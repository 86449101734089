import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import authService from '../services/AuthService';

// domains of environments (hardcoded - react doesn't allow changes on runtime)
const testApi = 'sharp-test.modelical.com';
const stagingApi = 'sharp-staging.modelical.com';
const productionApi = 'sharp.modelical.com';

let baseURL = process.env.REACT_APP_API_URL;
let campusURL = process.env.REACT_APP_CAMPUS_URL;
if (window.location.hostname.endsWith(testApi)) {
    baseURL = baseURL || `https://api.${testApi}`;
    campusURL = campusURL || 'https://modelicalsharptest.talentlms.com';
} else if (!baseURL && window.location.hostname.endsWith(stagingApi)) {
    baseURL = baseURL || `https://api.${stagingApi}`;
    campusURL = campusURL || 'https://modelicalsharpstaging.talentlms.com/';
} else if (!baseURL && window.location.hostname.endsWith(productionApi)) {
    baseURL = baseURL || `https://api.${productionApi}`;
    campusURL = campusURL || 'https://campus.modelical.com/';
}
export const Campus = {
    url: campusURL,
};

// api configuration
const apiAxiosConfig: AxiosRequestConfig = {
    baseURL,
    withCredentials: true,
};

// api response rejected interceptor
const axiosResponseRejectedInterceptor = (error: any) => {
    if (error && error.response && error.response.status === 401 && !!authService.auth) {
        window.location.href = `/login`;
    } else {
        return Promise.reject(error);
    }
};

// api
const apiAxios: AxiosInstance = axios.create(apiAxiosConfig);
apiAxios.interceptors.response.use(undefined, axiosResponseRejectedInterceptor);
export default apiAxios;
