import { ProfileSkill } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';

class ProfileSkillApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        profileId: number,
        searchText?: string,
    ): Promise<Page<ProfileSkill>> => {
        const response = await apiAxios.get<Page<ProfileSkill>>('/profile-skills', {
            params: { page, size, sortField, sortOrder, profileId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<ProfileSkill> => {
        const response = await apiAxios.get<ProfileSkill>(`/profile-skills/${id}`);
        return response.data;
    };

    create = async (profileSkill: ProfileSkill): Promise<ProfileSkill> => {
        const response = await apiAxios.post<ProfileSkill>('/profile-skills', profileSkill);
        return response.data;
    };

    update = async (profileSkill: ProfileSkill): Promise<ProfileSkill> => {
        const response = await apiAxios.put<ProfileSkill>(`/profile-skills/${profileSkill.id}`, profileSkill);
        return response.data;
    };

    delete = async (profileSkill: ProfileSkill): Promise<void> => {
        await apiAxios.delete(`/profile-skills/${profileSkill.id}`);
    };
}

const profileSkillApi: ProfileSkillApi = new ProfileSkillApi();
export default profileSkillApi;
