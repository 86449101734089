import React, { Component } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../context/CustomContext';
import { Page } from '../../../../../model/elements';
import { AssignmentGroupWithStatus, Group } from '../../../../../model/entities';
import tableService from '../../../../../services/TableService';
import notificationService from '../../../../../services/NotificationService';
import assignmentGroupApi from '../../../../../api/AssignmentGroupApi';
import { Link } from 'react-router-dom';

class GroupAssignmentsList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.group.id && this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.group.id !== prevProps.group.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        const { group } = this.props;
        try {
            this.setState({ loading: true });
            const assignmentGroupsPage = await assignmentGroupApi.list(
                0,
                tableService.pageSize,
                'assignment.published',
                false,
                undefined,
                group.id,
            );
            this.setState({ assignmentGroupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { group } = this.props;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const assignmentGroupsPage = await assignmentGroupApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                undefined,
                group.id,
            );
            this.setState({ assignmentGroupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { group } = this.props;
        const { assignmentGroupsPage, loading } = this.state;
        const items = assignmentGroupsPage ? assignmentGroupsPage.content : [];
        const columns: ColumnsType<AssignmentGroupWithStatus> = [
            {
                title: <FormattedMessage id="groupAssignment.assignment.name" />,
                dataIndex: 'assignment.name',
                key: 'assignment.name',
                sorter: !!group.id,
                render: (value: string, item: AssignmentGroupWithStatus) => (
                    <Link to={`/admin/assignments/${item.assignment.id}`}>{item.assignment.name}</Link>
                ),
            },

            {
                title: <FormattedMessage id="groupAssignment.assignment.published" />,
                dataIndex: 'assignment.published',
                key: 'assignment.published',
                sorter: !!group.id,
                defaultSortOrder: 'descend',
                align: 'center',
                width: '200px',
                render: (value: string, item: AssignmentGroupWithStatus) =>
                    item.assignment.published && (
                        <Link to={`/admin/assignments/${item.assignment.id}`}>
                            <FormattedDate
                                value={item.assignment.published.toISOString()}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="groupAssignment.deadline" />,
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: !!group.id,
                align: 'center',
                width: '200px',
                render: (value: string, item: AssignmentGroupWithStatus) =>
                    item.deadline && (
                        <Link to={`/admin/assignments/${item.assignment.id}`}>
                            <FormattedDate
                                value={item.deadline.toISOString()}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="groupAssignment.finished" />,
                dataIndex: 'finished',
                key: 'finished',
                width: '100px',
                align: 'right',
                render: (value: number, item: AssignmentGroupWithStatus) => (
                    <Link to={`/admin/assignments/${item.assignment.id}`}>{value}</Link>
                ),
            },
            {
                title: <FormattedMessage id="groupAssignment.passed" />,
                dataIndex: 'passed',
                key: 'passed',
                width: '100px',
                align: 'right',
                render: (value: number, item: AssignmentGroupWithStatus) => (
                    <Link to={`/admin/assignments/${item.assignment.id}`}>{value}</Link>
                ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(assignmentGroupsPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return this.renderTable();
    }
}
export default injectIntl(GroupAssignmentsList);

interface Props extends WrappedComponentProps {
    group: Group;
}

interface State {
    assignmentGroupsPage?: Page<AssignmentGroupWithStatus>;
    loading?: boolean;
}
