import { Assessment, AssessmentWithSkillStats, AssessmentWithUser, AssignmentQuestionFile } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';

class AssessmentApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<Page<Assessment>> => {
        const response = await apiAxios.get<Page<Assessment>>('/assessments', {
            params: { page, size, sortField, sortOrder, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.notified = d.notified && dayjs.utc(d.notified).local();
            d.started = d.started && dayjs.utc(d.started).local();
            d.finished = d.finished && dayjs.utc(d.finished).local();
            d.reminded = d.reminded && dayjs.utc(d.reminded).local();
        });

        return response.data;
    };

    listByAssignment = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        assignmentId: number,
        finished?: boolean,
        searchText?: string,
    ): Promise<Page<AssessmentWithUser>> => {
        const response = await apiAxios.get<Page<AssessmentWithUser>>('/assessments', {
            params: { page, size, sortField, sortOrder, assignmentId, finished, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.notified = d.notified && dayjs.utc(d.notified).local();
            d.started = d.started && dayjs.utc(d.started).local();
            d.finished = d.finished && dayjs.utc(d.finished).local();
            d.reminded = d.reminded && dayjs.utc(d.reminded).local();
        });

        return response.data;
    };

    get = async (id: number): Promise<Assessment> => {
        const response = await apiAxios.get<Assessment>(`/assessments/${id}`);
        response.data.notified = response.data.notified && dayjs.utc(response.data.notified).local();
        response.data.started = response.data.started && dayjs.utc(response.data.started).local();
        response.data.finished = response.data.finished && dayjs.utc(response.data.finished).local();
        response.data.reminded = response.data.reminded && dayjs.utc(response.data.reminded).local();

        return response.data;
    };

    getStats = async (id: number): Promise<AssessmentWithSkillStats> => {
        const response = await apiAxios.get<AssessmentWithSkillStats>(`/assessments/${id}/stats`);

        return response.data;
    };

    update = async (assessment: Assessment): Promise<Assessment> => {
        const assessmentForUpdate: Assessment = Object.assign({}, assessment, { assignment: undefined });
        const response = await apiAxios.put<Assessment>(`/assessments/${assessmentForUpdate.id}`, assessmentForUpdate);
        response.data.notified = response.data.notified && dayjs.utc(response.data.notified).local();
        response.data.started = response.data.started && dayjs.utc(response.data.started).local();
        response.data.finished = response.data.finished && dayjs.utc(response.data.finished).local();
        response.data.reminded = response.data.reminded && dayjs.utc(response.data.reminded).local();

        return response.data;
    };

    download = async (id: number, assignmentQuestionFile: AssignmentQuestionFile): Promise<void> => {
        const response = await apiAxios.get<Blob>(`/assessments/${id}/files/${assignmentQuestionFile.id}`, {
            responseType: 'blob',
        });
        FileSaver.saveAs(response.data, assignmentQuestionFile.file!.name);
    };

    sendReminder = async (assessment: Assessment): Promise<Assessment> => {
        const response = await apiAxios.post(`/assessments/${assessment.id}/reminder`);

        return response.data;
    };
}

const assessmentApi: AssessmentApi = new AssessmentApi();
export default assessmentApi;
