import Icon from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Space, Tooltip } from 'antd';
import { MenuProps } from 'rc-menu';
import { ItemType, MenuItemType } from 'rc-menu/lib/interface';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import { Campus } from '../../api/CustomAxios';
import CustomContext from '../../context/CustomContext';
import { PageType } from '../../model/elements';
import { Auth } from '../../model/entities';
import { LanguageType } from '../../model/types';
import { ReactComponent as ChevronDownSvg } from '../../resources/images/chevron--down.svg';
import { ReactComponent as CollaborateSvg } from '../../resources/images/collaborate.svg';
import { ReactComponent as EducationSvg } from '../../resources/images/education.svg';
import { ReactComponent as LogoutSvg } from '../../resources/images/logout.svg';
import { ReactComponent as ToolsSvg } from '../../resources/images/tools.svg';
import { ReactComponent as UserSvg } from '../../resources/images/user--avatar.svg';
import authService from '../../services/AuthService';
import stringService from '../../services/StringService';
import styles from './HeaderComponent.module.scss';

class HeaderComponent extends React.Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    logOut = async (): Promise<void> => {
        const { updateAuth } = this.context;
        const { intl } = this.props;

        try {
            await authService.logOut();
            updateAuth();
        } catch (error) {
            message.error(intl.formatMessage({ id: 'status.logoutError' }));
        }
    };

    /*** COMPONENTS ***/

    renderLogo = (): React.ReactElement | undefined => {
        return (
            <Link to={authService.getHomePath()}>
                <FormattedMessage id="header.logo" />
            </Link>
        );
    };

    renderMenu = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        const selectedKeys = this.props.pageId ? [this.props.pageId] : [];
        const tooltipAlign = { offset: [0, -31] };

        if (auth && authService.getCurrentApplication() === 'SUPER_ADMIN') {
            return (
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['skills']} selectedKeys={selectedKeys}>
                    <Menu.Item key="skills">
                        <Tooltip
                            title={<FormattedMessage id="header.superAdmin.skills.tooltip" />}
                            align={tooltipAlign}
                        >
                            <Link to="/super-admin/skills">
                                <FormattedMessage id="header.superAdmin.skills" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>

                    <Menu.Item key="profiles">
                        <Tooltip
                            title={<FormattedMessage id="header.superAdmin.profiles.tooltip" />}
                            align={tooltipAlign}
                        >
                            <Link to="/super-admin/profiles">
                                <FormattedMessage id="header.superAdmin.profiles" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                </Menu>
            );
        } else if (auth && authService.getCurrentApplication() === 'ADMIN') {
            return (
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['profiles']} selectedKeys={selectedKeys}>
                    <Menu.Item key="profiles">
                        <Tooltip title={<FormattedMessage id="header.admin.profiles.tooltip" />} align={tooltipAlign}>
                            <Link to="/admin/profiles">
                                <FormattedMessage id="header.admin.profiles" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    <Menu.Item key="assignments">
                        <Tooltip
                            title={<FormattedMessage id="header.admin.assignments.tooltip" />}
                            align={tooltipAlign}
                        >
                            <Link to="/admin/assignments">
                                <FormattedMessage id="header.admin.assignments" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    <Menu.Item key="groups">
                        <Tooltip title={<FormattedMessage id="header.admin.groups.tooltip" />} align={tooltipAlign}>
                            <Link to="/admin/groups">
                                <FormattedMessage id="header.admin.groups" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    <Menu.Item key="users">
                        <Tooltip title={<FormattedMessage id="header.admin.users.tooltip" />} align={tooltipAlign}>
                            <Link to="/admin/users">
                                <FormattedMessage id="header.admin.users" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    <Menu.Item key="reports" hidden={true}>
                        <Tooltip title={<FormattedMessage id="header.admin.reports.tooltip" />} align={tooltipAlign}>
                            <Link to="/admin/reports">
                                <FormattedMessage id="header.admin.reports" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                </Menu>
            );
        } else if (auth) {
            return (
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['assessments']} selectedKeys={selectedKeys}>
                    <Menu.Item key="assessments">
                        <Tooltip title={<FormattedMessage id="header.user.assessments.tooltip" />} align={tooltipAlign}>
                            <Link to="/assessments">
                                <FormattedMessage id="header.user.assessments" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    {!auth.external && (
                        <Menu.Item key="courses">
                            <Tooltip title={<FormattedMessage id="header.user.courses.tooltip" />} align={tooltipAlign}>
                                <Link to="/courses">
                                    <FormattedMessage id="header.user.courses" />
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                </Menu>
            );
        }
    };

    renderSettings = (): React.ReactElement | undefined => {
        const { auth, language } = this.context;

        if (auth) {
            const applicationItems = this.getApplicationMenuItems(auth);
            const languageApplication = this.getLanguageMenuItems(language);
            const branchItems = this.getBranchMenuItems(auth);
            const userProfileItems = this.getUserProfileMenuItems(auth);

            const items: ItemType[] = [
                ...applicationItems,
                ...languageApplication,
                ...branchItems,
                ...userProfileItems,
                {
                    key: 'logout',
                    label: (
                        <>
                            <Icon component={LogoutSvg} />
                            <FormattedMessage id="header.logout" />
                        </>
                    ),
                    onClick: this.logOut,
                },
            ];

            return (
                <Space direction="horizontal">
                    <a href={Campus.url} target="_blank" rel="noreferrer">
                        <Button type="default" ghost className={styles.campus}>
                            <FormattedMessage id="header.campus" />
                        </Button>
                    </a>
                    <Dropdown key="user" menu={{ items: items, className: styles.submenu }}>
                        <Button
                            type="text"
                            icon={<Icon component={UserSvg} className={styles.icon} />}
                            className={styles.user}
                        >
                            <span>
                                {auth?.firstName} {auth?.lastName} |{' '}
                                <span className={styles.type}>{this.renderApplication(auth)}</span>{' '}
                                <Icon component={ChevronDownSvg} className={styles.arrow} />
                            </span>
                        </Button>
                    </Dropdown>
                </Space>
            );
        } else {
            return (
                <a href={Campus.url} target="_blank" rel="noreferrer">
                    <Button type="default" ghost>
                        <FormattedMessage id="header.campus" />
                    </Button>
                </a>
            );
        }
    };

    getApplicationMenuItems = (auth: Auth): ItemType[] => {
        let items: MenuProps['items'] = [];
        if (auth.authorities.length > 1) {
            const applicationItems: MenuItemType[] = [
                {
                    key: 'application:superAdmin',
                    label: (
                        <a href="/super-admin">
                            <Icon component={ToolsSvg} />
                            <FormattedMessage id="header.application.superAdmin" />
                        </a>
                    ),
                    disabled: !auth.authorities.includes('ROLE_SUPER_ADMIN'),
                    className: authService.getCurrentApplication() === 'SUPER_ADMIN' ? styles.active : undefined,
                },
                {
                    key: 'application:admin',
                    label: (
                        <a href="/admin">
                            <Icon component={CollaborateSvg} />
                            <FormattedMessage id="header.application.admin" />
                        </a>
                    ),
                    disabled: !auth.authorities.includes('ROLE_ADMIN'),
                    className: authService.getCurrentApplication() === 'ADMIN' ? styles.active : undefined,
                },
                {
                    key: 'application:user',
                    label: (
                        <a href="/">
                            <Icon component={EducationSvg} />
                            <FormattedMessage id="header.application.user" />
                        </a>
                    ),
                    disabled: !auth.authorities.includes('ROLE_USER'),
                    className: authService.getCurrentApplication() === 'PORTAL' ? styles.active : undefined,
                },
            ];

            items = [
                {
                    type: 'group',
                    label: <FormattedMessage id="header.application" />,
                    children: applicationItems.filter((i) => !i.disabled),
                },
                {
                    type: 'divider',
                    className: styles.divider,
                },
            ];
        }

        return items;
    };

    getLanguageMenuItems = (language: LanguageType | undefined): ItemType[] => {
        let items: MenuProps['items'] = [];
        if (authService.getCurrentApplication() === 'SUPER_ADMIN') {
            items = [
                {
                    type: 'group',
                    label: <FormattedMessage id="header.language" />,
                    children: [
                        {
                            key: 'language:spanish',
                            label: (
                                <a href={`${authService.getHomePath()}?lang=es`}>
                                    <FormattedMessage id="header.language.es" />
                                </a>
                            ),
                            className: language === 'es' ? styles.active : undefined,
                        },
                        {
                            key: 'language:english',
                            label: (
                                <a href={`${authService.getHomePath()}?lang=en`}>
                                    <FormattedMessage id="header.language.en" />
                                </a>
                            ),
                            className: language === 'en' ? styles.active : undefined,
                        },
                    ],
                },
                {
                    type: 'divider',
                    className: styles.divider,
                },
            ];
        }

        return items;
    };

    getBranchMenuItems = (auth: Auth): ItemType[] => {
        let items: MenuProps['items'] = [];
        if (['ADMIN'].includes(authService.getCurrentApplication())) {
            let branches = auth.branches.sort((a, b) => stringService.sort(a.name, b.name));

            items = [
                {
                    type: 'group',
                    label: <FormattedMessage id="header.branch" />,
                    children: branches.map((branch) => {
                        const isCurrentBranch = branch.id === auth.branchId;
                        return {
                            key: `branch:${branch.id}`,
                            label: isCurrentBranch ? (
                                branch.name
                            ) : (
                                <a href={`${authService.getHomePath()}?branchId=${branch.id}`}>{branch.name}</a>
                            ),
                            className: branch.id === auth.branchId ? styles.active : undefined,
                        };
                    }),
                },
                {
                    type: 'divider',
                    className: styles.divider,
                },
            ];
        }

        return items;
    };

    getUserProfileMenuItems = (auth: Auth): ItemType[] => {
        let items: MenuProps['items'] = [];
        if (authService.getCurrentApplication() === 'PORTAL' && auth.external) {
            items = [
                {
                    key: 'userProfile',
                    label: (
                        <Link to="/user-profile">
                            <Icon component={UserSvg} className={styles.icon} />
                            <FormattedMessage id="header.userProfile" />
                        </Link>
                    ),
                },
                {
                    type: 'divider',
                    className: styles.divider,
                },
            ];
        }

        return items;
    };

    renderApplication = (auth: Auth): React.ReactElement | string | undefined => {
        const { pathname } = window.location;
        if (auth.authorities.length === 1) {
            return auth.userType;
        } else if (pathname.startsWith('/super-admin')) {
            return <FormattedMessage id="header.application.superAdmin" />;
        } else if (pathname.startsWith('/admin')) {
            return <FormattedMessage id="header.application.admin" />;
        } else {
            return <FormattedMessage id="header.application.user" />;
        }
    };

    render() {
        return (
            <div className={styles.header}>
                <div className={styles.logo}>{this.renderLogo()}</div>
                <div className={styles.menu}>{this.renderMenu()}</div>
                <div className={styles.settings}>{this.renderSettings()}</div>
            </div>
        );
    }
}
export default injectIntl(HeaderComponent);

interface Props extends WrappedComponentProps {
    pageId?: PageType;
}

interface State {}
