import { AssignmentGroup, AssignmentGroupWithStatus } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';
import dayjs from 'dayjs';

class AssignmentGroupApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        assignmentId?: number,
        groupId?: number,
        searchText?: string,
    ): Promise<Page<AssignmentGroupWithStatus>> => {
        const response = await apiAxios.get<Page<AssignmentGroupWithStatus>>('/assignment-groups', {
            params: { page, size, sortField, sortOrder, assignmentId, groupId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.deadline = d.deadline && dayjs.utc(d.deadline).local();
            d.assignment.published = d.assignment.published && dayjs.utc(d.assignment.published).local();
        });

        return response.data;
    };

    get = async (id: number): Promise<AssignmentGroup> => {
        const response = await apiAxios.get<AssignmentGroup>(`/assignment-groups/${id}`);
        response.data.deadline = response.data.deadline && dayjs.utc(response.data.deadline).local();

        return response.data;
    };

    create = async (assignmentGroup: AssignmentGroup): Promise<AssignmentGroup> => {
        const response = await apiAxios.post<AssignmentGroup>('/assignment-groups', assignmentGroup);
        response.data.deadline = response.data.deadline && dayjs.utc(response.data.deadline).local();

        return response.data;
    };

    update = async (assignmentGroup: AssignmentGroup): Promise<AssignmentGroup> => {
        const response = await apiAxios.put<AssignmentGroup>(
            `/assignment-groups/${assignmentGroup.id}`,
            assignmentGroup,
        );
        response.data.deadline = response.data.deadline && dayjs.utc(response.data.deadline).local();

        return response.data;
    };

    delete = async (assignmentGroup: AssignmentGroup): Promise<void> => {
        await apiAxios.delete(`/assignment-groups/${assignmentGroup.id}`);
    };
}

const assignmentGroupApi: AssignmentGroupApi = new AssignmentGroupApi();
export default assignmentGroupApi;
