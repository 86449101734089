import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as SearchSvg } from '../../../resources/images/search.svg';
import { Col, Row, Table, TablePaginationConfig, Tag, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import assessmentApi from '../../../api/AssessmentApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Assessment } from '../../../model/entities';
import tableService from '../../../services/TableService';
import notificationService from '../../../services/NotificationService';

class AssessmentsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const assessmentsPage = await assessmentApi.list(0, tableService.pageSize, 'notified', false);
            this.setState({ assessmentsPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const assessmentsPage = await assessmentApi.list(page - 1, pageSize, sortField, sortOrder, searchText);
            this.setState({ assessmentsPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    search = async (searchText: string) => {
        const { assessmentsPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = assessmentsPage!.size;
            const sortField = assessmentsPage!.sort.field!;
            const sortOrder = assessmentsPage!.sort.order!;
            const assessmentsPageNew = await assessmentApi.list(0, pageSize, sortField, sortOrder, searchText);
            this.setState({ assessmentsPage: assessmentsPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderTable()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const placeholder: string = intl.formatMessage({ id: 'assessments.search' });

        return (
            <Row className="toolbar">
                <Col span="24" lg={12}>
                    <Search
                        placeholder={placeholder}
                        onSearch={this.search}
                        size="large"
                        bordered={false}
                        addonBefore={<Icon component={SearchSvg} />}
                        className="search"
                    />
                </Col>
            </Row>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { assessmentsPage, loading } = this.state;
        const items = assessmentsPage ? assessmentsPage.content : [];
        const columns: ColumnsType<Assessment> = [
            {
                title: <FormattedMessage id="assessments.assignment.name" />,
                dataIndex: 'assignmentName',
                key: 'assignmentName',
                sorter: true,
                render: (value: string, item: Assessment) => (
                    <Link to={`/assessments/${item.id}`}>{item.assignment.name}</Link>
                ),
            },
            {
                title: <FormattedMessage id="assessment.notified" />,
                dataIndex: 'notified',
                key: 'notified',
                width: '200px',
                align: 'center',
                sorter: true,
                defaultSortOrder: 'descend',
                render: (value: string, item: Assessment) =>
                    value && (
                        <Link to={`/assessments/${item.id}`}>
                            <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assessment.started" />,
                dataIndex: 'started',
                key: 'started',
                width: '200px',
                align: 'center',
                sorter: true,
                render: (value: string, item: Assessment) =>
                    value && (
                        <Link to={`/assessments/${item.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assessment.finished" />,
                dataIndex: 'finished',
                key: 'finished',
                width: '200px',
                align: 'center',
                sorter: true,
                render: (value: string, item: Assessment) =>
                    value && (
                        <Link to={`/assessments/${item.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assessment.result.passed" />,
                dataIndex: 'resultPassed',
                key: 'resultPassed',
                width: '150px',
                align: 'center',
                render: (value: number, item: Assessment) =>
                    item.result && (
                        <Link to={`/assessments/${item.id}`}>
                            {item.result.passed ? (
                                <Tag color="green">
                                    <FormattedMessage id="assessment.result.passed.true" />
                                </Tag>
                            ) : (
                                <Tag color="red">
                                    <FormattedMessage id="assessment.result.passed.false" />
                                </Tag>
                            )}
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assessment.result.mark" />,
                dataIndex: 'resultMark',
                key: 'result.mark',
                width: '100px',
                align: 'right',
                render: (value: number, item: Assessment) =>
                    item.result && (
                        <Link to={`/assessments/${item.id}`}>
                            <FormattedNumber value={item.result!.mark!} maximumFractionDigits={1} />%
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assessment.result.courses" />,
                dataIndex: 'resultCourses',
                key: 'result.courses',
                width: '100px',
                align: 'right',
                render: (value: number, item: Assessment) =>
                    item.result &&
                    item.result.courses &&
                    item.result.courses.length > 0 && (
                        <Tooltip title={item.result.courses.map((c) => c.name).join(', ')} placement="left">
                            <Link to={`/assessments/${item.id}`}>{item.result.courses.length}</Link>
                        </Tooltip>
                    ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(assessmentsPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent pageId="assessments">{this.renderContent()}</LayoutComponent>;
    }
}
export default injectIntl(AssessmentsPage);

interface Props extends WrappedComponentProps {}

interface State {
    assessmentsPage?: Page<Assessment>;
    loading?: boolean;
    searchText?: string;
}
