import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../resources/images/add.svg';
import { ReactComponent as SearchSvg } from '../../../resources/images/search.svg';
import { Button, Modal, Table, TablePaginationConfig } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import userApi from '../../../api/UserApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { User } from '../../../model/entities';
import tableService from '../../../services/TableService';
import notificationService from '../../../services/NotificationService';
import { ReactComponent as CheckmarkSvg } from '../../../resources/images/checkmark.svg';
import { WithRouterProps, withRouter } from '../../../hooks/WithRouter';

class UsersPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const branchId = this.context.auth!.branchId!;
        try {
            this.setState({ loading: true });
            const usersPage = await userApi.list(0, tableService.pageSize, 'name', true, branchId);
            this.setState({ usersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const branchId = this.context.auth!.branchId!;
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const usersPage = await userApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                branchId,
                undefined,
                searchText,
            );
            this.setState({ usersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    search = async (searchText: string) => {
        const branchId = this.context.auth!.branchId!;
        const { usersPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = usersPage!.size;
            const sortField = usersPage!.sort.field!;
            const sortOrder = usersPage!.sort.order!;
            const usersPageNew = await userApi.list(0, pageSize, sortField, sortOrder, branchId, undefined, searchText);
            this.setState({ usersPage: usersPageNew, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    showModalNew = (modalNewVisible: boolean) => {
        this.setState({ modalNewVisible });
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderTable()}
                {this.renderModal()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const placeholder: string = intl.formatMessage({ id: 'users.search' });

        return (
            <div className="toolbar">
                <Search
                    placeholder={placeholder}
                    onSearch={this.search}
                    size="large"
                    bordered={false}
                    addonBefore={<Icon component={SearchSvg} />}
                    className="search"
                />
                <Button
                    type="primary"
                    size="large"
                    icon={<Icon component={AddSvg} />}
                    onClick={() => this.showModalNew(true)}
                >
                    <FormattedMessage id="users.new" tagName="span" />
                </Button>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { usersPage, loading } = this.state;
        const items = usersPage ? usersPage.content : [];
        const columns: ColumnsType<User> = [
            {
                title: <FormattedMessage id="user.name" />,
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, item: User) => (
                    <Link to={`/admin/users/${item.id}`}>
                        {item.lastName}, {item.firstName}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="user.email" />,
                dataIndex: 'email',
                key: 'email',
                sorter: true,
                render: (value: string, item: User) => <Link to={`/admin/users/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="user.external" />,
                dataIndex: 'external',
                key: 'external',
                sorter: true,
                align: 'center',
                width: '120px',
                render: (value: boolean, item: User) => (
                    <Link to={`/admin/users/${item.id}`} hidden={!value}>
                        <Icon component={CheckmarkSvg} />
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="user.assignments" />,
                dataIndex: 'assignmentsNumber',
                key: 'assignmentsNumber',
                align: 'right',
                width: '150px',
                render: (value: string, item: User) => <Link to={`/admin/users/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="user.courses" />,
                dataIndex: 'coursesNumber',
                key: 'coursesNumber',
                align: 'right',
                width: '150px',
                render: (value: string, item: User) => <Link to={`/admin/users/${item.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(usersPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    renderModal = (): React.ReactElement | undefined => {
        const { modalNewVisible } = this.state;

        return (
            <Modal
                title={<FormattedMessage id="users.new.modal.title" />}
                open={modalNewVisible}
                onCancel={() => this.showModalNew(false)}
                okText={<FormattedMessage id="users.new.modal.create" />}
                onOk={() => this.props.navigate('/admin/users/new')}
            >
                <p>
                    <FormattedMessage id="users.new.modal.description" />{' '}
                    <strong>
                        <FormattedMessage id="users.new.modal.create" />
                    </strong>
                    .
                </p>
            </Modal>
        );
    };

    render() {
        return <LayoutComponent pageId="users">{this.renderContent()}</LayoutComponent>;
    }
}
export default injectIntl(withRouter<Props>(UsersPage));

interface Props extends WrappedComponentProps {}

interface State {
    usersPage?: Page<User>;
    loading?: boolean;
    searchText?: string;
    modalNewVisible?: boolean;
}
