import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../resources/images/add.svg';
import { ReactComponent as SearchSvg } from '../../../resources/images/search.svg';
import { Button, Modal, Table, TablePaginationConfig } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import groupApi from '../../../api/GroupApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Group } from '../../../model/entities';
import tableService from '../../../services/TableService';
import notificationService from '../../../services/NotificationService';

class GroupsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const branchId = this.context.auth!.branchId!;
        try {
            this.setState({ loading: true });
            const groupsPage = await groupApi.list(0, tableService.pageSize, 'name', true, branchId);
            this.setState({ groupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const branchId = this.context.auth!.branchId!;
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const groupsPage = await groupApi.list(page - 1, pageSize, sortField, sortOrder, branchId, searchText);
            this.setState({ groupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    search = async (searchText: string) => {
        const branchId = this.context.auth!.branchId!;
        const { groupsPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = groupsPage!.size;
            const sortField = groupsPage!.sort.field!;
            const sortOrder = groupsPage!.sort.order!;
            const groupsPageNew = await groupApi.list(0, pageSize, sortField, sortOrder, branchId, searchText);
            this.setState({ groupsPage: groupsPageNew, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    new = () => {
        const { intl } = this.props;
        const title = intl.formatMessage({ id: 'groups.new.modal.title' });
        const content = intl.formatMessage({ id: 'groups.new.modal.description' });
        Modal.destroyAll();
        Modal.info({ title, content, maskClosable: true });
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderTable()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const placeholder: string = intl.formatMessage({ id: 'groups.search' });

        return (
            <div className="toolbar">
                <Search
                    placeholder={placeholder}
                    onSearch={this.search}
                    size="large"
                    bordered={false}
                    addonBefore={<Icon component={SearchSvg} />}
                    className="search"
                />
                <Button type="primary" size="large" icon={<Icon component={AddSvg} />} onClick={this.new}>
                    <FormattedMessage id="groups.new" tagName="span" />
                </Button>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { groupsPage, loading } = this.state;
        const items = groupsPage ? groupsPage.content : [];
        const columns: ColumnsType<Group> = [
            {
                title: <FormattedMessage id="groups.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, item: Group) => <Link to={`/admin/groups/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="group.assignments" />,
                dataIndex: 'assignmentsNumber',
                key: 'assignmentsNumber',
                align: 'right',
                width: '150px',
                render: (value: number, item: Group) => <Link to={`/admin/groups/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="group.users" />,
                dataIndex: 'usersNumber',
                key: 'usersNumber',
                align: 'right',
                width: '150px',
                render: (value: number, item: Group) => <Link to={`/admin/groups/${item.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(groupsPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent pageId="groups">{this.renderContent()}</LayoutComponent>;
    }
}
export default injectIntl(GroupsPage);

interface Props extends WrappedComponentProps {}

interface State {
    groupsPage?: Page<Group>;
    loading?: boolean;
    searchText?: string;
}
