import Layout, { Footer, Header } from 'antd/lib/layout/layout';
import styles from './LayoutComponent.module.scss';
import * as React from 'react';
import CustomContext from '../../context/CustomContext';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import { PageType } from '../../model/elements';

class LayoutComponent extends React.Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /*** COMPONENTS ***/

    render() {
        const { children, pageId } = this.props;

        return (
            <Layout className={styles.layout}>
                <Header>
                    <HeaderComponent pageId={pageId} />
                </Header>
                <div className={styles.content}>{children}</div>
                <Footer className={styles.footer}>
                    <a href="https://modelical.com">© Modelical</a>
                </Footer>
            </Layout>
        );
    }
}
export default LayoutComponent;

interface Props extends React.PropsWithChildren {
    pageId?: PageType;
}

interface State {}
