import * as React from 'react';
import { Route, Routes } from 'react-router';
import { default as AdminAssignmentPage } from '../../pages/Admin/AssignmentsPage/AssignmentPage/AssignmentPage';
import { default as AdminAssignmentsPage } from '../../pages/Admin/AssignmentsPage/AssignmentsPage';
import { default as AdminDashboardPage } from '../../pages/Admin/DashboardPage/DashboardPage';
import { default as AdminGroupPage } from '../../pages/Admin/GroupsPage/GroupPage/GroupPage';
import { default as AdminGroupsPage } from '../../pages/Admin/GroupsPage/GroupsPage';
import { default as AdminProfilePage } from '../../pages/Admin/ProfilesPage/ProfilePage/ProfilePage';
import { default as AdminProfilesPage } from '../../pages/Admin/ProfilesPage/ProfilesPage';
import { default as AdminReportsPage } from '../../pages/Admin/ReportsPage/ReportsPage';
import { default as AdminUserPage } from '../../pages/Admin/UsersPage/UserPage/UserPage';
import { default as AdminUsersPage } from '../../pages/Admin/UsersPage/UsersPage';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import { default as SuperAdminProfilePage } from '../../pages/SuperAdmin/ProfilesPage/ProfilePage/ProfilePage';
import { default as SuperAdminProfilesPage } from '../../pages/SuperAdmin/ProfilesPage/ProfilesPage';
import { default as SuperAdminSkillPage } from '../../pages/SuperAdmin/SkillsPage/SkillPage/SkillPage';
import { default as SuperAdminSkillsPage } from '../../pages/SuperAdmin/SkillsPage/SkillsPage';
import AssessmentPage from '../../pages/User/AssessmentsPage/AssessmentPage/AssessmentPage';
import AssessmentsPage from '../../pages/User/AssessmentsPage/AssessmentsPage';
import CoursesPage from '../../pages/User/CoursesPage/CoursesPage';
import DashboardPage from '../../pages/User/DashboardPage/DashboardPage';
import ProtectedAdminRoute from '../ProtectedRoute/ProtectedAdminRoute';
import ProtectedSuperAdminRoute from '../ProtectedRoute/ProtectedSuperAdminRoute';
import ProtectedUserRoute from '../ProtectedRoute/ProtectedUserRoute';
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage';
import UserProfilePage from '../../pages/UserProfilePage/UserProfilePage';

export const RoutesComponent: React.FC = () => {
    return (
        <Routes>
            <Route element={<ProtectedSuperAdminRoute />}>
                <Route path="/super-admin" element={<SuperAdminSkillsPage />} />
                <Route path="/super-admin/skills" element={<SuperAdminSkillsPage />} />
                <Route path="/super-admin/skills/:id" element={<SuperAdminSkillPage />} />
                <Route path="/super-admin/profiles" element={<SuperAdminProfilesPage />} />
                <Route path="/super-admin/profiles/:id" element={<SuperAdminProfilePage />} />
            </Route>

            <Route element={<ProtectedAdminRoute />}>
                <Route path="/admin" element={<AdminDashboardPage />} />
                <Route path="/admin/profiles" element={<AdminProfilesPage />} />
                <Route path="/admin/profiles/:id" element={<AdminProfilePage />} />
                <Route path="/admin/assignments" element={<AdminAssignmentsPage />} />
                <Route path="/admin/assignments/:id" element={<AdminAssignmentPage />} />
                <Route path="/admin/groups" element={<AdminGroupsPage />} />
                <Route path="/admin/groups/:id" element={<AdminGroupPage />} />
                <Route path="/admin/users" element={<AdminUsersPage />} />
                <Route path="/admin/users/:id" element={<AdminUserPage />} />
                <Route path="/admin/reports" element={<AdminReportsPage />} />
            </Route>

            <Route element={<ProtectedUserRoute />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/assessments" element={<AssessmentsPage />} />
                <Route path="/assessments/:id" element={<AssessmentPage />} />
                <Route path="/courses" element={<CoursesPage />} />
                <Route path="/user-profile" element={<UserProfilePage />} />
            </Route>

            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />

            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};
