import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../resources/images/add.svg';
import { ReactComponent as SearchSvg } from '../../../resources/images/search.svg';
import { Button, Table, TablePaginationConfig } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import assignmentApi from '../../../api/AssignmentApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Assignment } from '../../../model/entities';
import tableService from '../../../services/TableService';
import notificationService from '../../../services/NotificationService';

class AssignmentsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const branchId = this.context.auth!.branchId!;
        try {
            this.setState({ loading: true });
            const assignmentsPage = await assignmentApi.list(0, tableService.pageSize, 'published', false, branchId);
            this.setState({ assignmentsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const branchId = this.context.auth!.branchId!;
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const assignmentsPage = await assignmentApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                branchId,
                searchText,
            );
            this.setState({ assignmentsPage, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    search = async (searchText: string) => {
        const branchId = this.context.auth!.branchId!;
        const { assignmentsPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = assignmentsPage!.size;
            const sortField = assignmentsPage!.sort.field!;
            const sortOrder = assignmentsPage!.sort.order!;
            const assignmentsPageNew = await assignmentApi.list(
                0,
                pageSize,
                sortField,
                sortOrder,
                branchId,
                searchText,
            );
            this.setState({ assignmentsPage: assignmentsPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderTable()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const placeholder: string = intl.formatMessage({ id: 'assignments.search' });

        return (
            <div className="toolbar">
                <Search
                    placeholder={placeholder}
                    onSearch={this.search}
                    size="large"
                    bordered={false}
                    addonBefore={<Icon component={SearchSvg} />}
                    className="search"
                />
                <Link to="/admin/assignments/new">
                    <Button type="primary" size="large" icon={<Icon component={AddSvg} />}>
                        <FormattedMessage id="assignments.new" tagName="span" />
                    </Button>
                </Link>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { assignmentsPage, loading } = this.state;
        const items = assignmentsPage ? assignmentsPage.content : [];
        const columns: ColumnsType<Assignment> = [
            {
                title: <FormattedMessage id="assignments.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (value: string, item: Assignment) => <Link to={`/admin/assignments/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="assignment.published" />,
                dataIndex: 'published',
                key: 'published',
                width: '200px',
                align: 'center',
                sorter: true,
                defaultSortOrder: 'descend',
                render: (value: string, item: Assignment) =>
                    value && (
                        <Link to={`/admin/assignments/${item.id}`}>
                            <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assignment.period" />,
                dataIndex: 'period',
                key: 'period',
                width: '200px',
                align: 'center',
                sorter: true,
                render: (value: string, item: Assignment) =>
                    value && (
                        <Link to={`/admin/assignments/${item.id}`}>
                            {value} <FormattedMessage id="assignment.period.days" />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assignment.users" />,
                dataIndex: 'usersNumber',
                key: 'usersNumber',
                width: '100px',
                align: 'right',
                render: (value: string, item: Assignment) => <Link to={`/admin/assignments/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="assignment.groups" />,
                dataIndex: 'groupsNumber',
                key: 'groupsNumber',
                width: '100px',
                align: 'right',
                render: (value: string, item: Assignment) => <Link to={`/admin/assignments/${item.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(assignmentsPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent pageId="assignments">{this.renderContent()}</LayoutComponent>;
    }
}
export default injectIntl(AssignmentsPage);

interface Props extends WrappedComponentProps {}

interface State {
    assignmentsPage?: Page<Assignment>;
    loading?: boolean;
    searchText?: string;
}
