import { Skill } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';
import { LanguageType } from '../model/types';

class SkillApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        language?: LanguageType,
        searchText?: string,
    ): Promise<Page<Skill>> => {
        const response = await apiAxios.get<Page<Skill>>('/skills', {
            params: { page, size, sortField, sortOrder, language, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<Skill> => {
        const response = await apiAxios.get<Skill>(`/skills/${id}`);
        return response.data;
    };

    create = async (skill: Skill): Promise<Skill> => {
        const response = await apiAxios.post<Skill>('/skills', skill);
        return response.data;
    };

    update = async (skill: Skill): Promise<Skill> => {
        const response = await apiAxios.put<Skill>(`/skills/${skill.id}`, skill);
        return response.data;
    };

    delete = async (skill: Skill): Promise<void> => {
        await apiAxios.delete(`/skills/${skill.id}`);
    };
}

const skillApi: SkillApi = new SkillApi();
export default skillApi;
