import { Profile } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';

class ProfileApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<Page<Profile>> => {
        const response = await apiAxios.get<Page<Profile>>('/profiles', {
            params: { page, size, sortField, sortOrder, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<Profile> => {
        const response = await apiAxios.get<Profile>(`/profiles/${id}`);
        return response.data;
    };

    create = async (profile: Profile): Promise<Profile> => {
        const response = await apiAxios.post<Profile>('/profiles', profile);
        return response.data;
    };

    update = async (profile: Profile): Promise<Profile> => {
        const response = await apiAxios.put<Profile>(`/profiles/${profile.id}`, profile);
        return response.data;
    };

    delete = async (profile: Profile): Promise<void> => {
        await apiAxios.delete(`/profiles/${profile.id}`);
    };
}

const profileApi: ProfileApi = new ProfileApi();
export default profileApi;
