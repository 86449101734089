import Icon from '@ant-design/icons';
import { Form, Input, Modal, message } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import externalUserApi from '../../../api/ExternalUserApi';
import { UpdatePassword } from '../../../model/entities';
import { ReactComponent as SaveSvg } from '../../../resources/images/save.svg';
import notificationService from '../../../services/NotificationService';

/**
 * This component represents an assessment skill component.
 * @param props - the props
 */
export const ChangePasswordModal: React.FC<Props> = (props) => {
    const { onHide, onSave } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'saving'>();

    /*** METHODS ***/

    const changePassword = async (): Promise<void> => {
        try {
            setLoading('saving');
            const values = await form.validateFields();
            const updatePassword: UpdatePassword = Object.assign({}, values);
            await externalUserApi.updatePassword(updatePassword);

            onSave();
        } catch (error: any) {
            if (!error.errorFields) {
                displayError(error, intl);
            }
        } finally {
            setLoading(undefined);
        }
    };

    const displayError = (error: any, intl: IntlShape) => {
        try {
            if (error.response?.status === 400 && error.response.data[0].field === 'password') {
                message.error(intl.formatMessage({ id: 'changePassword.status.password.invalid' }));
            } else {
                notificationService.displayError(error, intl);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        }
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="changePassword.title" />}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            cancelText={<FormattedMessage id="button.cancel" />}
            onOk={changePassword}
            onCancel={onHide}
            open={true}
            maskClosable={false}
            className="modal"
            okButtonProps={{
                loading: loading === 'saving',
                icon: <Icon component={SaveSvg} />,
                size: 'large',
            }}
            cancelButtonProps={{
                type: 'text',
                size: 'large',
            }}
        >
            <Form form={form} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="changePassword.password" />}
                    name="password"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    required={false}
                >
                    <Input type="password" minLength={6} maxLength={20} size="large" />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="changePassword.newPassword" />}
                    name="newPassword"
                    rules={[
                        { required: true, message: <FormattedMessage id="status.mandatory" /> },
                        {
                            pattern: new RegExp('^(?=.*[0-9])(?=.*[^0-9]).{8,20}$'),
                            message: <FormattedMessage id="status.password.invalid" />,
                            validateTrigger: 'onSubmit',
                        },
                    ]}
                    required={false}
                >
                    <Input type="password" minLength={6} maxLength={20} size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ChangePasswordModal;

interface Props {
    onHide: () => void;
    onSave: () => void;
}
