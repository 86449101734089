import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../resources/images/add.svg';
import { ReactComponent as SearchSvg } from '../../../resources/images/search.svg';
import { Button, Table, TablePaginationConfig } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import profileApi from '../../../api/ProfileApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Profile } from '../../../model/entities';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';

class ProfilesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const profilesPage = await profileApi.list(0, tableService.pageSize, 'name', true);
            this.setState({ profilesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const profilesPage = await profileApi.list(page - 1, pageSize, sortField, sortOrder, searchText);
            this.setState({ profilesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    search = async (searchText: string) => {
        const { profilesPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = profilesPage!.size;
            const sortField = profilesPage!.sort.field!;
            const sortOrder = profilesPage!.sort.order!;
            const profilesPageNew = await profileApi.list(0, pageSize, sortField, sortOrder, searchText);
            this.setState({ profilesPage: profilesPageNew, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderTable()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const placeholder: string = intl.formatMessage({ id: 'profiles.search' });

        return (
            <div className="toolbar">
                <Search
                    placeholder={placeholder}
                    onSearch={this.search}
                    size="large"
                    bordered={false}
                    addonBefore={<Icon component={SearchSvg} />}
                    className="search"
                />
                <Link to="/super-admin/profiles/new">
                    <Button type="primary" size="large" icon={<Icon component={AddSvg} />}>
                        <FormattedMessage id="profiles.new" tagName="span" />
                    </Button>
                </Link>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { profilesPage, loading } = this.state;
        const items = profilesPage ? profilesPage.content : [];
        const columns: ColumnsType<Profile> = [
            {
                title: <FormattedMessage id="profiles.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, item: Profile) => <Link to={`/super-admin/profiles/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="profile.language" />,
                dataIndex: 'language',
                key: 'language',
                width: '110px',
                align: 'center',
                sorter: true,
                render: (value: string, item: Profile) => (
                    <Link to={`/super-admin/profiles/${item.id}`}>{value.toUpperCase()}</Link>
                ),
            },
            {
                title: <FormattedMessage id="profile.passGrade" />,
                dataIndex: 'passGrade',
                key: 'passGrade',
                width: '150px',
                align: 'right',
                render: (value: string, item: Profile) => <Link to={`/super-admin/profiles/${item.id}`}>{value}%</Link>,
            },
            {
                title: <FormattedMessage id="profile.skillsNumber" />,
                dataIndex: 'skillsNumber',
                key: 'skillsNumber',
                width: '150px',
                align: 'right',
                render: (value: string, item: Profile) => <Link to={`/super-admin/profiles/${item.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(profilesPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent pageId="profiles">{this.renderContent()}</LayoutComponent>;
    }
}
export default injectIntl(ProfilesPage);

interface Props extends WrappedComponentProps {}

interface State {
    profilesPage?: Page<Profile>;
    loading?: boolean;
    searchText?: string;
}
