import { Assignment, AssignmentWithSkillStats, AssignmentWithStats } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';
import dayjs from 'dayjs';

class AssignmentApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        branchId: number,
        searchText?: string,
    ): Promise<Page<Assignment>> => {
        const response = await apiAxios.get<Page<Assignment>>('/assignments', {
            params: { page, size, sortField, sortOrder, branchId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.published = d.published && dayjs.utc(d.published).local();
        });

        return response.data;
    };

    listWithStats = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        branchId: number,
        searchText?: string,
    ): Promise<Page<AssignmentWithStats>> => {
        const response = await apiAxios.get<Page<AssignmentWithStats>>('/assignments', {
            params: { page, size, sortField, sortOrder, branchId, searchText, stats: 'true' },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.published = d.published && dayjs.utc(d.published).local();
        });

        return response.data;
    };

    get = async (id: number): Promise<Assignment> => {
        const response = await apiAxios.get<Assignment>(`/assignments/${id}`);
        response.data.published = response.data.published && dayjs.utc(response.data.published).local();
        return response.data;
    };

    getStats = async (id: number): Promise<AssignmentWithSkillStats> => {
        const response = await apiAxios.get<AssignmentWithSkillStats>(`/assignments/${id}/stats`);

        return response.data;
    };

    create = async (assignment: Assignment): Promise<Assignment> => {
        const response = await apiAxios.post<Assignment>('/assignments', assignment);
        response.data.published = response.data.published && dayjs.utc(response.data.published).local();
        return response.data;
    };

    update = async (assignment: Assignment): Promise<Assignment> => {
        const response = await apiAxios.put<Assignment>(`/assignments/${assignment.id}`, assignment);
        response.data.published = response.data.published && dayjs.utc(response.data.published).local();
        return response.data;
    };

    delete = async (assignment: Assignment): Promise<void> => {
        await apiAxios.delete(`/assignments/${assignment.id}`);
    };
}

const assignmentApi: AssignmentApi = new AssignmentApi();
export default assignmentApi;
