import { Question } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';

class QuestionApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        skillId: number,
        searchText?: string,
    ): Promise<Page<Question>> => {
        const response = await apiAxios.get<Page<Question>>('/questions', {
            params: { page, size, sortField, sortOrder, skillId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<Question> => {
        const response = await apiAxios.get<Question>(`/questions/${id}`);
        return response.data;
    };

    create = async (question: Question): Promise<Question> => {
        const response = await apiAxios.post<Question>('/questions', question);
        return response.data;
    };

    update = async (question: Question): Promise<Question> => {
        const response = await apiAxios.put<Question>(`/questions/${question.id}`, question);
        return response.data;
    };

    delete = async (question: Question): Promise<void> => {
        await apiAxios.delete(`/questions/${question.id}`);
    };
}

const questionApi: QuestionApi = new QuestionApi();
export default questionApi;
