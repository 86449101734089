import React, { Component } from 'react';
import { Form, FormInstance, Input, InputNumber, message, Modal, Radio, RadioChangeEvent, Select } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as SaveSvg } from '../../../../../../resources/images/save.svg';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import questionApi from '../../../../../../api/QuestionApi';
import CustomContext from '../../../../../../context/CustomContext';
import { Question, QuestionOption, SkillFile } from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import skillFileApi from '../../../../../../api/SkillFileApi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import stringService from '../../../../../../services/StringService';

class QuestionModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { skillFiles: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { questionId } = this.props;

        try {
            if (questionId) {
                await this.get(questionId);
            } else {
                await this.new();
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { skillId } = this.props;
        const question: Question = { skillId, type: 'TEXT', files: [] };
        const skillFilePage = await skillFileApi.list(0, 500, 'file.name', true, skillId);
        const skillFiles = skillFilePage.content;

        this.setState({ question, skillFiles });
        this.formRef.current!.setFieldsValue(question);
    };

    get = async (id: number) => {
        const { skillId } = this.props;
        const responses = await Promise.all([
            questionApi.get(id),
            skillFileApi.list(0, 500, 'file.name', true, skillId),
        ]);
        const question = responses[0];
        const skillFiles = responses[1].content;
        let values = Object.assign({}, question, {
            files: question.files.map((file) => file.id!),
        });

        // sort question option answer result
        if (question.type === 'OPTION') {
            const questionOption = question as QuestionOption;
            values = Object.assign({}, values, {
                answers: questionOption.answers.sort((a, b) => a.answer!.localeCompare(b.answer!)),
            });
        }

        this.setState({ question, skillFiles });
        this.formRef.current!.setFieldsValue(values);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let question: Question = Object.assign({}, this.state.question, values, {
                files: values.files.map((id: number) => ({ id })),
            });
            question = question.id ? await questionApi.update(question) : await questionApi.create(question);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ question });
            this.props.onSave();
            this.props.onHide();
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'question.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    changeType = (e: RadioChangeEvent) => {
        const question: Question = Object.assign({}, this.state.question, {
            type: e.target.value,
        });
        this.setState({ question });
    };

    changeAnswerOption = (result: boolean, index: number) => {
        if (result) {
            const answers: any[] = this.formRef.current!.getFieldsValue().answers;
            answers.forEach((answer, i) => {
                if (i !== index) {
                    this.formRef.current!.setFieldValue(['answers', i, 'result'], false);
                }
            });
        }
    };

    validateRichTextEditor = (
        rule: RuleObject,
        value: StoreValue,
        callback: (error?: string) => void,
    ): Promise<void> | void => {
        if (value && value.length > 5 * 1024 * 1024) {
            callback(this.props.intl.formatMessage({ id: 'status.text.size' }));
        }
        callback();
    };

    /*** COMPONENTS ***/

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { question, skillFiles } = this.state;
        const skillFileOptions = skillFiles.map((skillFile) => (
            <Select.Option key={skillFile.id!} value={skillFile.id!}>
                {skillFile.file?.name}
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="question.question" />}
                    name="question"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Input
                        maxLength={250}
                        size="large"
                        placeholder={intl.formatMessage({ id: 'question.question.placeholder' })}
                    />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="question.description" />}
                    name="description"
                    initialValue=""
                    rules={[
                        {
                            validator: this.validateRichTextEditor,
                        },
                    ]}
                >
                    <ReactQuill
                        modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                                ['link', 'image'],
                                ['clean'],
                            ],
                        }}
                    />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="question.files" />} name="files">
                    <Select size="large" mode="multiple" showSearch filterOption={stringService.filterOptions}>
                        {skillFileOptions}
                    </Select>
                </Form.Item>
                <Form.Item label={<FormattedMessage id="question.type" />} name="type">
                    <Radio.Group onChange={this.changeType} disabled={question && !!question.id} size="large">
                        <Radio.Button value="TEXT">
                            <FormattedMessage id="question.type.TEXT" />
                        </Radio.Button>
                        <Radio.Button value="NUMERIC">
                            <FormattedMessage id="question.type.NUMERIC" />
                        </Radio.Button>
                        <Radio.Button value="OPTION">
                            <FormattedMessage id="question.type.OPTION" />
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {question && this.renderAnswer(question)}
            </Form>
        );
    };

    renderAnswer = (question: Question): React.ReactElement | undefined => {
        if (question.type === 'TEXT') {
            return this.renderAnswerText();
        } else if (question.type === 'NUMERIC') {
            return this.renderAnswerNumeric();
        } else if (question.type === 'OPTION') {
            return this.renderAnswerOptions(4);
        }
    };

    renderAnswerText = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        return (
            <Form.Item
                label={<FormattedMessage id="question.text.answer" />}
                name="answer"
                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
            >
                <Input
                    maxLength={250}
                    size="large"
                    placeholder={intl.formatMessage({ id: 'question.text.answer.placeholder' })}
                />
            </Form.Item>
        );
    };

    renderAnswerNumeric = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        return (
            <Form.Item
                label={<FormattedMessage id="question.numeric.answer" />}
                name="answer"
                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
            >
                <InputNumber
                    size="large"
                    placeholder={intl.formatMessage({ id: 'question.numeric.answer.placeholder' })}
                />
            </Form.Item>
        );
    };

    renderAnswerOptions = (size: number): React.ReactElement | undefined => {
        return <>{[...Array(size)].map((item, index) => this.renderAnswerOption(index))}</>;
    };

    renderAnswerOption = (index: number): React.ReactElement | undefined => {
        const { intl } = this.props;
        const label = index === 0 ? <FormattedMessage id="question.option.answer" /> : undefined;

        return (
            <Form.Item label={label} key={index}>
                <Input.Group compact>
                    <Form.Item
                        noStyle
                        name={['answers', index, 'answer']}
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input
                            maxLength={250}
                            size="large"
                            style={{ width: '80%' }}
                            placeholder={intl.formatMessage({
                                id: `question.option.answer.${index}.placeholder`,
                            })}
                        />
                    </Form.Item>
                    <Form.Item noStyle name={['answers', index, 'result']} shouldUpdate>
                        <Select
                            size="large"
                            style={{ width: '20%' }}
                            defaultValue={false}
                            onChange={(value) => this.changeAnswerOption(value, index)}
                        >
                            <Select.Option value={false}>
                                <FormattedMessage id="button.false" />
                            </Select.Option>
                            <Select.Option value={true}>
                                <FormattedMessage id="button.true" />
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Input.Group>
            </Form.Item>
        );
    };

    render() {
        const { saving } = this.state;
        const submit = this.formRef.current ? this.formRef.current.submit : undefined;

        return (
            <Modal
                title={<FormattedMessage id="question.title" />}
                okText={<FormattedMessage id="button.save" tagName="span" />}
                cancelText={<FormattedMessage id="button.cancel" />}
                onOk={submit}
                onCancel={this.props.onHide}
                open={true}
                maskClosable={false}
                width="1024px"
                className="modal"
                centered
                okButtonProps={{
                    loading: saving,
                    icon: <Icon component={SaveSvg} />,
                    size: 'large',
                }}
                cancelButtonProps={{
                    type: 'text',
                    size: 'large',
                }}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}
export default injectIntl(QuestionModal);

interface Props extends WrappedComponentProps {
    questionId?: number;
    skillId: number;
    onHide: () => void;
    onSave: () => void;
}

interface State {
    question?: Question;
    skillFiles: SkillFile[];
    saving?: boolean;
}
