import { SkillFile } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';
import FileSaver from 'file-saver';

class SkillFileApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        skillId: number,
        searchText?: string,
    ): Promise<Page<SkillFile>> => {
        const response = await apiAxios.get<Page<SkillFile>>('/skill-files', {
            params: { page, size, sortField, sortOrder, skillId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<SkillFile> => {
        const response = await apiAxios.get<SkillFile>(`/skill-files/${id}`);
        return response.data;
    };

    create = async (skillFile: SkillFile, file: File): Promise<SkillFile> => {
        const formData: FormData = new FormData();
        formData.append('skillId', skillFile.skillId!.toString());
        skillFile.file && skillFile.file.name && formData.append('file.name', skillFile.file.name);
        skillFile.description && formData.append('description', skillFile.description);
        formData.append('file.file', file);

        const response = await apiAxios.post<SkillFile>('/skill-files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    };

    update = async (skillFile: SkillFile, file?: File): Promise<SkillFile> => {
        const formData: FormData = new FormData();
        skillFile.id && formData.append('id', skillFile.id.toString());
        skillFile.skillId && formData.append('skillId', skillFile.skillId.toString());
        skillFile.file && skillFile.file.name && formData.append('file.name', skillFile.file.name);
        skillFile.description && formData.append('description', skillFile.description);
        file && formData.append('file.file', file);

        const response = await apiAxios.post<SkillFile>(`/skill-files/${skillFile.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    };

    delete = async (skillFile: SkillFile): Promise<void> => {
        await apiAxios.delete(`/skill-files/${skillFile.id}`);
    };

    download = async (skillFile: SkillFile): Promise<void> => {
        const response = await apiAxios.get<Blob>(`/skill-files/${skillFile.id}/file`, {
            responseType: 'blob',
        });
        FileSaver.saveAs(response.data, skillFile.file?.name);
    };
}

const skillFileApi: SkillFileApi = new SkillFileApi();
export default skillFileApi;
