import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../../../resources/images/add.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import { Button, Popconfirm, Table, TablePaginationConfig, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../context/CustomContext';
import { Page } from '../../../../../model/elements';
import { Assignment, AssignmentGroup, AssignmentGroupWithStatus } from '../../../../../model/entities';
import tableService from '../../../../../services/TableService';
import notificationService from '../../../../../services/NotificationService';
import assignmentGroupApi from '../../../../../api/AssignmentGroupApi';
import AssignmentGroupModal from './AssignmentGroupModal/AssignmentGroupModal';
import { Link } from 'react-router-dom';

class AssignmentGroupsList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.assignment.id && this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.assignment.id !== prevProps.assignment.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        const { assignment } = this.props;
        try {
            this.setState({ loading: true });
            const assignmentGroupsPage = await assignmentGroupApi.list(
                0,
                tableService.pageSize,
                'group.name',
                true,
                assignment.id,
            );
            this.setState({ assignmentGroupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { assignment } = this.props;
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const assignmentGroupsPage = await assignmentGroupApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                assignment.id,
                undefined,
                searchText,
            );
            this.setState({ assignmentGroupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    refresh = async () => {
        const { assignment } = this.props;
        const { assignmentGroupsPage, searchText } = this.state;
        try {
            this.setState({ loading: true });
            const sortField = assignmentGroupsPage!.sort.field!;
            const sortOrder = assignmentGroupsPage!.sort.order!;
            const assignmentGroupsPageNew = await assignmentGroupApi.list(
                0,
                tableService.pageSize,
                sortField,
                sortOrder,
                assignment.id,
                undefined,
                searchText,
            );
            this.setState({ assignmentGroupsPage: assignmentGroupsPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    showModal = (assignmentGroup?: AssignmentGroupWithStatus) => {
        const modalVisible = true;
        this.setState({ modalVisible, assignmentGroup });
    };

    hideModal = async () => {
        const modalVisible = false;
        const assignmentGroup = undefined;
        this.setState({ modalVisible, assignmentGroup });
    };

    delete = async (assignmentGroup: AssignmentGroup) => {
        try {
            this.setState({ deleting: assignmentGroup.id });
            await assignmentGroupApi.delete(assignmentGroup);
            this.refresh();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ deleting: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { assignment } = this.props;
        const { assignmentGroupsPage, loading, deleting } = this.state;
        const items = assignmentGroupsPage ? assignmentGroupsPage.content : [];
        const columns: ColumnsType<AssignmentGroupWithStatus> = [
            {
                title: <FormattedMessage id="assignmentGroup.group.name" />,
                dataIndex: 'group.name',
                key: 'group.name',
                sorter: !!assignment.id,
                defaultSortOrder: 'ascend',
                render: (value: string, item: AssignmentGroupWithStatus) => (
                    <Link to={`/admin/groups/${item.group.id}`}>{item.group.name}</Link>
                ),
            },
            {
                title: <FormattedMessage id="assignmentGroup.deadline" />,
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: !!assignment.id,
                width: '160px',
                align: 'center',
                render: (value: string, item: AssignmentGroupWithStatus) =>
                    value && (
                        <Link to={`/admin/groups/${item.group.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assignmentGroup.group.users" />,
                dataIndex: 'group.usersNumber',
                key: 'group.usersNumber',
                sorter: !!assignment.id,
                width: '150px',
                align: 'right',
                render: (value: string, item: AssignmentGroupWithStatus) => (
                    <Link to={`/admin/groups/${item.group.id}`}>{item.group.usersNumber}</Link>
                ),
            },
            {
                title: <FormattedMessage id="assignmentGroup.finished" />,
                dataIndex: 'finished',
                key: 'finished',
                width: '150px',
                align: 'right',
                render: (value: number, item: AssignmentGroupWithStatus) => (
                    <Link to={`/admin/groups/${item.group.id}`}>{value}</Link>
                ),
            },
            {
                title: <FormattedMessage id="assignmentGroup.passed" />,
                dataIndex: 'passed',
                key: 'passed',
                width: '150px',
                align: 'right',
                render: (value: number, item: AssignmentGroupWithStatus) => (
                    <Link to={`/admin/groups/${item.group.id}`}>{value}</Link>
                ),
            },
            {
                title: (
                    <Tooltip title={<FormattedMessage id="assignmentGroups.new" tagName="span" />}>
                        <Button
                            type="primary"
                            ghost={!assignment.id}
                            icon={<Icon component={AddSvg} />}
                            onClick={(e) => this.showModal()}
                            disabled={!assignment.id}
                        ></Button>
                    </Tooltip>
                ),
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: '100px',
                render: (value: string, item: AssignmentGroupWithStatus) => (
                    <Popconfirm
                        title={<FormattedMessage id="assignmentGroups.delete" />}
                        onConfirm={() => this.delete(item)}
                        okText={<FormattedMessage id="button.yes" />}
                        cancelText={<FormattedMessage id="button.no" />}
                        placement="left"
                    >
                        <Button type="ghost" icon={<Icon component={TrashCanSvg} />} loading={deleting === item.id} />
                    </Popconfirm>
                ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(assignmentGroupsPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    renderModal = (): React.ReactElement | undefined => {
        const { assignment } = this.props;
        const { assignmentGroup, modalVisible } = this.state;

        if (modalVisible && assignment.id) {
            return (
                <AssignmentGroupModal
                    assignment={assignment}
                    assignmentGroupId={assignmentGroup?.id}
                    onHide={this.hideModal}
                    onSave={this.refresh}
                />
            );
        }
    };

    render() {
        return (
            <>
                {this.renderTable()}
                {this.renderModal()}
            </>
        );
    }
}
export default injectIntl(AssignmentGroupsList);

interface Props extends WrappedComponentProps {
    assignment: Assignment;
}

interface State {
    assignmentGroupsPage?: Page<AssignmentGroupWithStatus>;
    loading?: boolean;
    deleting?: number;
    searchText?: string;
    assignmentGroup?: AssignmentGroupWithStatus;
    modalVisible?: boolean;
}
