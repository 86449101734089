import Icon from '@ant-design/icons';
import { Button, Carousel, Col, Form, Input, Layout, message, Result, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Header } from 'antd/lib/layout/layout';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import { Link, Navigate } from 'react-router-dom';
import externalUserApi from '../../api/ExternalUserApi';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import CustomContext from '../../context/CustomContext';
import { withRouter, WithRouterProps } from '../../hooks/WithRouter';
import { ResetPassword, ResetPasswordEmail } from '../../model/entities';
import { ReactComponent as Login1 } from '../../resources/images/login-1.svg';
import { ReactComponent as Login2 } from '../../resources/images/login-2.svg';
import { ReactComponent as Login3 } from '../../resources/images/login-3.svg';
import { ReactComponent as Login4 } from '../../resources/images/login-4.svg';
import styles from './ResetPasswordPage.module.scss';
import notificationService from '../../services/NotificationService';
import authService from '../../services/AuthService';

class ResetPasswordPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    codeFormRef = React.createRef<FormInstance>();
    resetPasswordFormRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    sendResetPasswordEmail = async (values: any): Promise<void> => {
        try {
            this.setState({ loading: true });
            const resetPasswordEmail: ResetPasswordEmail = Object.assign({}, values);
            await externalUserApi.sendResetPasswordEmail(resetPasswordEmail);
            this.resetPasswordFormRef.current?.setFieldValue('email', values.email);

            this.setState({ isCodeSent: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 404, message: 'resetPassword.status.notFound' },
            ]);
        } finally {
            this.setState({ loading: false });
        }
    };

    resetPassword = async (values: any): Promise<void> => {
        const { intl } = this.props;
        const { updateAuth } = this.context;

        try {
            this.setState({ loading: true });
            const resetPassword: ResetPassword = Object.assign({}, values);
            await externalUserApi.resetPassword(resetPassword);

            // login
            const auth = await authService.logIn(resetPassword.email!, resetPassword.password!);
            updateAuth(auth);
        } catch (error: any) {
            this.displayError(error, intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    displayError = (error: any, intl: IntlShape) => {
        try {
            if (error.response?.status === 400 && error.response.data[0].field === 'email') {
                message.error(intl.formatMessage({ id: 'resetPassword.status.email.invalid' }));
            } else if (error.response?.status === 400 && error.response.data[0].field === 'resetPasswordCode') {
                message.error(intl.formatMessage({ id: 'resetPassword.status.resetPasswordCode.invalid' }));
            } else if (error.response?.status === 400 && error.response.data[0].field === 'reseted') {
                message.error(intl.formatMessage({ id: 'resetPassword.status.reseted.invalid' }));
            } else {
                notificationService.displayError(error, this.props.intl);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <Layout className={styles.layout}>
                <Header>
                    <HeaderComponent />
                </Header>
                <Row className={styles.content} justify="center">
                    <Col span={12} className={styles.info}>
                        {this.renderInfo()}
                    </Col>
                    <Col span={12} className={styles.resetPassword}>
                        {this.renderCodeForm()}
                        {this.renderResetPasswordForm()}
                    </Col>
                </Row>
            </Layout>
        );
    };

    renderInfo = (): React.ReactElement | undefined => {
        return (
            <Carousel autoplay speed={4000}>
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.1" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.1.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login1} className={styles.image} />}
                />
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.2" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.2.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login2} className={styles.image} />}
                />
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.3" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.3.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login3} className={styles.image} />}
                />
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.4" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.4.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login4} className={styles.image} />}
                />
            </Carousel>
        );
    };

    renderCodeForm = (): React.ReactElement | undefined => {
        const { loading, isCodeSent } = this.state;

        return (
            <div hidden={isCodeSent}>
                <Row justify="center">
                    <Col span="16" className={styles.title}>
                        <FormattedMessage id="resetPassword.resetPassword" />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span="16" className={styles.description}>
                        <FormattedMessage id="resetPassword.description" />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span="16">
                        <Form
                            ref={this.codeFormRef}
                            onFinish={this.sendResetPasswordEmail}
                            colon={false}
                            layout="vertical"
                        >
                            <Form.Item
                                label={<FormattedMessage id="resetPassword.email" />}
                                name="email"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    { type: 'email', message: <FormattedMessage id="status.email.invalid" /> },
                                ]}
                                required={false}
                            >
                                <Input maxLength={200} size="large" />
                            </Form.Item>
                            <Form.Item className={styles.buttons} wrapperCol={{ span: 14 }}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                                    <FormattedMessage id="resetPassword.sendCode" tagName="span" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={16}>
                        <Link to="/login">
                            <Button type="link" className={styles.login}>
                                <FormattedMessage id="resetPassword.login" />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    };

    renderResetPasswordForm = (): React.ReactElement | undefined => {
        const { loading, isCodeSent } = this.state;

        return (
            <div hidden={!isCodeSent}>
                <Row justify="center">
                    <Col span="16" className={styles.title}>
                        <FormattedMessage id="resetPassword.resetPassword" />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span="16" className={styles.description}>
                        <FormattedMessage id="resetPassword.description" />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span="16">
                        <Form
                            ref={this.resetPasswordFormRef}
                            onFinish={this.resetPassword}
                            colon={false}
                            layout="vertical"
                        >
                            <Form.Item
                                label={<FormattedMessage id="resetPassword.email" />}
                                name="email"
                                required={false}
                            >
                                <Input maxLength={200} size="large" disabled />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="resetPassword.resetPasswordCode" />}
                                name="resetPasswordCode"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                            >
                                <Input maxLength={6} size="large" />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="resetPassword.password" />}
                                name="password"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        pattern: new RegExp('^(?=.*[0-9])(?=.*[^0-9]).{8,20}$'),
                                        message: <FormattedMessage id="status.password.invalid" />,
                                        validateTrigger: 'onSubmit',
                                    },
                                ]}
                                required={false}
                            >
                                <Input type="password" minLength={6} maxLength={20} size="large" />
                            </Form.Item>
                            <Form.Item className={styles.buttons} wrapperCol={{ span: 14 }}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                                    <FormattedMessage id="resetPassword.resetPassword" tagName="span" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={16}>
                        <Link to="/login">
                            <Button type="link" className={styles.login}>
                                <FormattedMessage id="resetPassword.login" />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    };

    render() {
        const { auth } = this.context;

        if (!auth) {
            return <>{this.renderContent()}</>;
        } else {
            return <Navigate to="/" />;
        }
    }
}
export default injectIntl(withRouter<Props>(ResetPasswordPage));

interface Props extends WrappedComponentProps {}

interface State {
    isCodeSent?: boolean;
    loading?: boolean;
}
