import React, { Component } from 'react';
import styles from './SkillFilesList.module.scss';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../../../resources/images/add.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import { ReactComponent as DownloadSvg } from '../../../../../resources/images/download.svg';
import { ReactComponent as AttachmentSvg } from '../../../../../resources/images/attachment.svg';
import { Avatar, Button, List, Popconfirm, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import SkillFileModal from './SkillFileModal/SkillFileModal';
import CustomContext from '../../../../../context/CustomContext';
import notificationService from '../../../../../services/NotificationService';
import skillFileApi from '../../../../../api/SkillFileApi';
import { SkillFile } from '../../../../../model/entities';
import { Page } from '../../../../../model/elements';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import SubheaderComponent from '../../../../../components/SubheaderComponent/SubheaderComponent';

class SkillFilesList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.skillId !== prevProps.skillId) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        await this.list();
    };

    list = async () => {
        const { skillId } = this.props;
        try {
            if (skillId) {
                this.setState({ loading: true });
                const skillFilePage = await skillFileApi.list(0, 500, 'file.name', true, skillId);
                this.setState({ skillFilesPage: skillFilePage, loading: false });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    showModal = (skillFile?: SkillFile) => {
        const modalVisible = true;
        this.setState({ modalVisible, skillFile });
    };

    hideModal = async () => {
        const modalVisible = false;
        const skillFile = undefined;
        this.setState({ modalVisible, skillFile });
    };

    download = async (skillFile: SkillFile) => {
        try {
            this.setState({ downloading: skillFile.id });
            await skillFileApi.download(skillFile);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ downloading: undefined });
        }
    };

    delete = async (skillFile: SkillFile) => {
        try {
            this.setState({ deleting: skillFile.id });
            await skillFileApi.delete(skillFile);
            this.list();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ deleting: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderList()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { skillId } = this.props;

        return (
            <SubheaderComponent
                title={<FormattedMessage id="skillFiles.title" />}
                button={
                    <Tooltip title={<FormattedMessage id="skillFiles.new" />}>
                        <Button
                            type="primary"
                            icon={<Icon component={AddSvg} />}
                            disabled={!skillId}
                            onClick={() => this.showModal()}
                        />
                    </Tooltip>
                }
            />
        );
    };

    renderList = (): React.ReactElement | undefined => {
        const { skillFilesPage, loading, downloading, deleting } = this.state;
        const skillFiles = skillFilesPage ? skillFilesPage.content : [];

        return (
            <List
                className={styles.files}
                itemLayout="horizontal"
                dataSource={skillFiles}
                locale={{
                    emptyText: <FormattedMessage id="skillFiles.empty" />,
                }}
                loading={loading}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Button
                                type="ghost"
                                icon={<Icon component={DownloadSvg} />}
                                loading={downloading === item.id}
                                onClick={() => this.download(item)}
                            />,
                            <Popconfirm
                                title={<FormattedMessage id="skillFiles.delete" />}
                                onConfirm={() => this.delete(item)}
                                okText={<FormattedMessage id="button.yes" />}
                                cancelText={<FormattedMessage id="button.no" />}
                                placement="left"
                            >
                                <Button
                                    type="ghost"
                                    icon={<Icon component={TrashCanSvg} />}
                                    loading={deleting === item.id}
                                />
                            </Popconfirm>,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    shape="square"
                                    size="large"
                                    icon={<Icon component={AttachmentSvg} onClick={() => this.showModal(item)} />}
                                />
                            }
                            title={
                                <span className="link" onClick={() => this.showModal(item)}>
                                    {item.file!.name}
                                </span>
                            }
                            description={
                                <span className="link" onClick={() => this.showModal(item)}>
                                    <FileSizeComponent value={item.file!.size} />
                                </span>
                            }
                        />
                    </List.Item>
                )}
            />
        );
    };

    renderModal = (): React.ReactElement | undefined => {
        const { skillId } = this.props;
        const { skillFile, modalVisible } = this.state;

        if (modalVisible && skillId) {
            return (
                <SkillFileModal
                    skillId={skillId}
                    skillFileId={skillFile && skillFile.id}
                    onHide={this.hideModal}
                    onSave={this.list}
                />
            );
        }
    };

    render() {
        return (
            <>
                {this.renderContent()}
                {this.renderModal()}
            </>
        );
    }
}
export default injectIntl(SkillFilesList);

interface Props extends WrappedComponentProps {
    skillId?: number;
}

interface State {
    skillFilesPage?: Page<SkillFile>;
    loading?: boolean;
    downloading?: number;
    deleting?: number;
    searchText?: string;
    skillFile?: SkillFile;
    modalVisible?: boolean;
}
