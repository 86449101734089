import { Button, Form, FormInstance, Input, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import groupApi from '../../../../api/GroupApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../../../context/CustomContext';
import { WithRouterProps, withRouter } from '../../../../hooks/WithRouter';
import { Group } from '../../../../model/entities';
import notificationService from '../../../../services/NotificationService';
import GroupAssignmentsList from './GroupAssignmentsList/GroupAssignmentsList';
import GroupUsersList from './GroupUsersList/GroupUsersList';

class GroupPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            await this.get(+this.props.params.id!);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    get = async (id: number) => {
        const group = await groupApi.get(id);

        this.setState({ group });
        this.formRef.current!.setFieldsValue(group);
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderForm()}
                {this.renderTabs()}
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        return (
            <Form ref={this.formRef} colon={false} layout="vertical">
                <Form.Item label={<FormattedMessage id="group.name" />} name="name">
                    <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="group.description" />} name="description">
                    <TextArea rows={3} size="large" disabled={true} showCount />
                </Form.Item>
                <Form.Item className="buttons">
                    <Link to="/admin/groups">
                        <Button type="text" size="large">
                            <FormattedMessage id="button.back" tagName="span" />
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        );
    };

    renderTabs = (): React.ReactElement | undefined => {
        const { group } = this.state;

        return (
            <Tabs defaultActiveKey="users" size="large">
                <Tabs.TabPane key="users" tab={<FormattedMessage id="group.users" />}>
                    {group && group.id && <GroupUsersList groupId={group.id} />}
                </Tabs.TabPane>
                <Tabs.TabPane key="assignments" tab={<FormattedMessage id="group.assignments" />}>
                    {group && <GroupAssignmentsList group={group} />}
                </Tabs.TabPane>
            </Tabs>
        );
    };

    render() {
        return (
            <LayoutComponent pageId="groups">
                <WrapperComponent title={<FormattedMessage id="groups.name" />}>
                    {this.renderContent()}
                </WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<Props>(GroupPage));

interface Props extends WrappedComponentProps {}

interface State {
    group?: Group;
}
