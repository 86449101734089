import { Button, Col, Divider, Progress, Row, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import assessmentApi from '../../../../api/AssessmentApi';
import { AssessmentSkillStats, AssessmentWithSkillStats } from '../../../../model/entities';
import styles from './AssessmentSkillsComponent.module.scss';

/**
 * This component represents an assessment skill component.
 * @param props - the props
 */
export const AssessmentSkillsComponent: React.FC<Props> = (props) => {
    const { assessmentId } = props;

    /*** HOOKS ***/

    const [assessmentWithSkillStats, setAssessmentWithSkillStats] = useState<AssessmentWithSkillStats>();
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const init = async () => {
            try {
                const assessmentWithSkillStats = await assessmentApi.getStats(assessmentId);
                setAssessmentWithSkillStats(assessmentWithSkillStats);
            } catch (error) {
                // no error message
            }
        };
        init();
    }, [assessmentId]);

    /*** METHODS ***/

    const togglePanel = (event: any) => {
        event.preventDefault();
        setVisible(!visible);
    };

    /*** VISUAL ***/

    return (
        <>
            <Row gutter={[16, 16]} className={styles.buttons} hidden={assessmentWithSkillStats?.skills.length === 0}>
                <Col span={24}>
                    <Button type="link" className={styles.link} onClick={togglePanel}>
                        <FormattedMessage id="button.seeAll" />
                    </Button>
                </Col>
            </Row>

            <Row gutter={[16, 16]} hidden={!visible} className={styles.container}>
                <Divider />

                {assessmentWithSkillStats?.skills.map((skill) => (
                    <Col span={24}>
                        <Statistic
                            title={
                                <>
                                    <h4>
                                        <FormattedMessage id="dashboard.assessments.skills.skill" />
                                    </h4>
                                    <p>{skill.skillName}</p>
                                </>
                            }
                            valueRender={() => (
                                <Row gutter={28}>
                                    <Col span={12}>
                                        <Progress
                                            strokeLinecap="square"
                                            type="circle"
                                            percent={getAssessmentPassed(skill)}
                                            format={() => (
                                                <>
                                                    <FormattedNumber
                                                        value={getAssessmentPassed(skill)}
                                                        maximumFractionDigits={1}
                                                    />
                                                    %
                                                </>
                                            )}
                                            className={styles.results}
                                        />
                                    </Col>
                                    <Col span={12} className={styles.legend}>
                                        <p className={styles.passed}>
                                            <span />
                                            <FormattedMessage id="dashboard.assessments.marks.passed" />
                                        </p>
                                        <p className={styles.failed}>
                                            <span />
                                            <FormattedMessage id="dashboard.assessments.marks.failed" />
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        ></Statistic>
                    </Col>
                ))}
            </Row>
        </>
    );
};
export default AssessmentSkillsComponent;

interface Props {
    assessmentId: number;
}

const getAssessmentPassed = (assessment: AssessmentSkillStats): number => {
    return assessment.assessmentsNumber === 0 ? 0 : (assessment.passedNumber / assessment.assessmentsNumber) * 100;
};
