import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
    token: {
        colorPrimary: '#0555e6',
        colorLink: '#000',
        colorLinkActive: '#000',
        colorLinkHover: '#000',
        fontFamily: 'IBMPlexSans',
        colorBgLayout: '#eef0f2',
        borderRadius: 0,
        colorText: '#00154b',
        colorTextDisabled: 'rgba(0,0,0,0.5)',
        colorBgContainerDisabled: '#fff',
    },
    components: {
        Layout: {
            colorBgHeader: '#00154b',
            colorBgBody: '#eef0f2',
        },
        Input: {
            colorBorder: 'transparent',
            fontFamily: 'IBMPlexSans',
            borderRadius: 0,
        },
        Select: {
            colorBorder: 'transparent',
        },
        Divider: {
            colorBgContainer: '#fafafa',
            colorBgLayout: '#fafafa',
            colorBorder: '#fafafa',
            colorFill: '#fafafa',
        },
        Tooltip: {
            colorBgDefault: '#00154b',
        },
        Menu: {
            colorItemBg: '#00154b',
        },
        Dropdown: {
            colorBgElevated: '#00154b',
        },
        Table: {},
        Progress: {
            colorText: '#000',
            colorSuccess: '#000',
        },
    },
};

export default theme;
