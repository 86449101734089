import { Avatar, Button, Card, Col, Divider, Empty, List, Progress, Row, Space, Spin, Statistic } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import assignmentApi from '../../../api/AssignmentApi';
import profileBranchApi from '../../../api/ProfileBranchApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { AssignmentWithStats, Profile } from '../../../model/entities';
import notificationService from '../../../services/NotificationService';
import AssignmentSkillsComponent from './AssignmentSkillsComponent/AssignmentSkillsComponent';
import styles from './DashboardPage.module.scss';

class DashboardPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { assignments: [], profiles: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const branchId = this.context.auth!.branchId!;
        try {
            this.setState({ loading: true });
            const responses = await Promise.all([
                assignmentApi.listWithStats(0, 4, 'published', false, branchId),
                profileBranchApi.list(0, 5, 'name', true, branchId, true),
            ]);
            const assignments = responses[0].content;
            const profiles = responses[1].content;
            this.setState({ assignments, profiles });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    getAssignmentPassed = (assignment: AssignmentWithStats): number => {
        const total = assignment.passedNumber + assignment.failedNumber;
        return total === 0 ? 0 : (assignment.passedNumber / total) * 100;
    };

    getAssignmentFailed = (assignment: AssignmentWithStats): number => {
        const total = assignment.passedNumber + assignment.failedNumber;
        return total === 0 ? 0 : (assignment.failedNumber / total) * 100;
    };

    getAssignmentFinished = (assignment: AssignmentWithStats): number => {
        return this.getAssignmentPassed(assignment) + this.getAssignmentFailed(assignment);
    };

    /*** COMPONENTS ***/

    renderLoader = (): React.ReactElement | undefined => {
        return (
            <div className={styles.loader}>
                <Spin /> <FormattedMessage id="status.loading" tagName="h3" />
            </div>
        );
    };

    renderAssignments = (): React.ReactElement | undefined => {
        const { assignments, loading } = this.state;

        return (
            <>
                <h1 className={styles.title}>
                    <FormattedMessage id="admin.dashboard.assignments" />
                </h1>
                <Row gutter={16}>
                    {assignments.map((assignment) => (
                        <Col span={6} key={assignment.id}>
                            {this.renderAssignment(assignment)}
                        </Col>
                    ))}
                </Row>

                {loading && this.renderLoader()}
                {!loading && assignments.length === 0 && (
                    <div>
                        <Empty
                            image={false}
                            description={<FormattedMessage id="admin.dashboard.assignments.empty" />}
                            className={styles.empty}
                        >
                            <Link to="/admin/assignments/new">
                                <Button type="primary" size="large">
                                    <FormattedMessage id="admin.dashboard.assignments.new" />
                                </Button>
                            </Link>
                        </Empty>
                    </div>
                )}
                {!loading && assignments.length > 0 && (
                    <Link to="/admin/assignments">
                        <Button type="link" className={styles.link}>
                            <FormattedMessage id="button.seeAll" />
                        </Button>
                    </Link>
                )}
            </>
        );
    };

    renderAssignment = (assignment: AssignmentWithStats): React.ReactElement | undefined => {
        const { intl } = this.props;

        return (
            <Link to={`/admin/assignments/${assignment.id}`} key={assignment.id}>
                <Card title={assignment.name} bordered={false}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Statistic
                                title={<FormattedMessage id="admin.dashboard.assignments.published" />}
                                value={
                                    assignment.published
                                        ? intl.formatDate(assignment.published.toISOString(), {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                          })
                                        : ' '
                                }
                            />
                        </Col>
                        <Col span={12} className={styles.right}>
                            <Statistic
                                title={<FormattedMessage id="admin.dashboard.assignments.groups" />}
                                value={assignment.groupsNumber}
                            />
                        </Col>
                        <Col span={12}>
                            <Statistic
                                title={<FormattedMessage id="admin.dashboard.assignments.users" />}
                                value={assignment.usersNumber}
                            />
                        </Col>
                        <Col span={12} className={styles.right}>
                            <Statistic
                                title={<FormattedMessage id="admin.dashboard.assignments.finished" />}
                                valueRender={() => (
                                    <>
                                        {assignment.passedNumber + assignment.failedNumber}/
                                        {assignment.assessmentsNumber}
                                    </>
                                )}
                            />
                        </Col>
                        <Col span={24}>
                            <Statistic
                                title={<FormattedMessage id="admin.dashboard.assignments.results" />}
                                valueRender={() => (
                                    <Row gutter={28}>
                                        <Col span={12}>
                                            <Progress
                                                strokeLinecap="square"
                                                type="circle"
                                                percent={this.getAssignmentFinished(assignment)}
                                                success={{
                                                    percent: this.getAssignmentPassed(assignment),
                                                    strokeColor: '#00154b',
                                                }}
                                                format={() => (
                                                    <>
                                                        <FormattedNumber
                                                            value={this.getAssignmentPassed(assignment)}
                                                            maximumFractionDigits={1}
                                                        />
                                                        %
                                                    </>
                                                )}
                                                className={styles.results}
                                            />
                                        </Col>
                                        <Col span={12} className={styles.legend}>
                                            <p className={styles.passed}>
                                                <span />
                                                <FormattedMessage id="admin.dashboard.assignments.results.passed" />
                                            </p>
                                            <p className={styles.failed}>
                                                <span />
                                                <FormattedMessage id="admin.dashboard.assignments.results.failed" />
                                            </p>
                                        </Col>
                                    </Row>
                                )}
                            ></Statistic>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            {assignment.id && <AssignmentSkillsComponent assignmentId={assignment.id} />}
                        </Col>
                    </Row>
                </Card>
            </Link>
        );
    };

    renderProfiles = (): React.ReactElement | undefined => {
        const { profiles, loading } = this.state;

        return (
            <>
                <h1 className={styles.title}>
                    <FormattedMessage id="admin.dashboard.profiles" />
                </h1>
                {profiles.length > 0 && (
                    <List
                        className={styles.files}
                        itemLayout="horizontal"
                        dataSource={profiles}
                        locale={{
                            emptyText: <></>,
                        }}
                        renderItem={(profile) => (
                            <Link to={`/admin/profiles/${profile.id}`} key={profile.id}>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar shape="square" size="large">
                                                {profile.name!.charAt(0)}
                                            </Avatar>
                                        }
                                        title={<span>{profile.name}</span>}
                                        description={
                                            <>
                                                <Space split={<Divider type="vertical" />}>
                                                    <span>
                                                        <FormattedMessage id="admin.dashboard.profile.skillsNumber" />:{' '}
                                                        {profile.skillsNumber}
                                                    </span>
                                                    <span>
                                                        <FormattedMessage id="admin.dashboard.profile.passGrade" />:{' '}
                                                        {profile.passGrade}%
                                                    </span>
                                                </Space>
                                                <p className={styles.description}>{profile.description}</p>
                                            </>
                                        }
                                    />
                                </List.Item>
                            </Link>
                        )}
                    />
                )}

                {loading && this.renderLoader()}
                {!loading && profiles.length === 0 && (
                    <Empty
                        image={false}
                        description={<FormattedMessage id="admin.dashboard.profiles.empty" />}
                        className={styles.empty}
                    >
                        <Link to="/admin/profiles/new">
                            <Button type="primary" size="large">
                                <FormattedMessage id="admin.dashboard.profiles.new" />
                            </Button>{' '}
                        </Link>
                    </Empty>
                )}
                {!loading && profiles.length > 0 && (
                    <Link to="/admin/profiles">
                        <Button type="link" className={styles.link}>
                            <FormattedMessage id="button.seeAll" />
                        </Button>
                    </Link>
                )}
            </>
        );
    };

    render() {
        return (
            <LayoutComponent>
                {this.renderAssignments()}
                {this.renderProfiles()}
            </LayoutComponent>
        );
    }
}
export default injectIntl(DashboardPage);

interface Props extends WrappedComponentProps {}

interface State {
    assignments: AssignmentWithStats[];
    profiles: Profile[];
    loading?: boolean;
}
