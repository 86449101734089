import React, { Component } from 'react';
import { Col, DatePicker, Form, FormInstance, InputNumber, message, Modal, Row, Select } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as SaveSvg } from '../../../../../../resources/images/save.svg';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../../context/CustomContext';
import { Assignment, AssignmentGroup, Group } from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import groupApi from '../../../../../../api/GroupApi';
import assignmentGroupApi from '../../../../../../api/AssignmentGroupApi';
import dayjs from 'dayjs';

class AssignmentGroupModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { groups: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { assignmentGroupId } = this.props;

        try {
            if (assignmentGroupId) {
                await this.get(assignmentGroupId);
            } else {
                await this.new();
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { assignment } = this.props;
        const assignmentGroup: AssignmentGroup = {
            assignmentId: assignment.id,
            deadline: assignment.period
                ? dayjs()
                      .add(assignment.period, 'days')
                      .set('hour', 18)
                      .set('minute', 0)
                      .set('second', 0)
                      .set('millisecond', 0)
                : undefined,
        };
        const groupsPage = await groupApi.list(0, 10, 'lastName', true, assignment.branchId!);
        const groups = groupsPage.content;

        this.setState({ assignmentGroup, groups });
        this.formRef.current!.setFieldsValue(assignmentGroup);
        this.formRef.current!.setFieldValue('assignment.period', assignment.period);
    };

    get = async (id: number) => {
        const { assignment } = this.props;
        const responses = await Promise.all([
            assignmentGroupApi.get(id),
            groupApi.list(0, 10, 'lastName', true, assignment.branchId!),
        ]);
        const assignmentGroup = responses[0];
        const groups = responses[1].content;

        this.setState({ assignmentGroup, groups });
        this.formRef.current!.setFieldsValue(assignmentGroup);
        this.formRef.current!.setFieldValue('assignment.period', assignment.period);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let assignmentGroup: AssignmentGroup = Object.assign({}, this.state.assignmentGroup, values);

            assignmentGroup = assignmentGroup.id
                ? await assignmentGroupApi.update(assignmentGroup)
                : await assignmentGroupApi.create(assignmentGroup);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ assignmentGroup });
            this.props.onSave();
            this.props.onHide();
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'assignmentGroup.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    searchGroups = async (searchText: string) => {
        const { assignment } = this.props;
        try {
            const groupsPage = await groupApi.list(0, 10, 'lastName', true, assignment.branchId!, searchText);
            const groups = groupsPage.content;

            this.setState({ groups });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { groups } = this.state;
        const groupOptions = groups.map((group) => (
            <Select.Option key={group.id!} value={group.id!}>
                {group.name}
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={<FormattedMessage id="assignmentGroup.group" />}
                            name="groupId"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                placeholder={intl.formatMessage({ id: 'assignmentGroup.group.placeholder' })}
                                showSearch
                                filterOption={false}
                                onSearch={this.searchGroups}
                            >
                                {groupOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="assignmentGroup.assignment.period" />}
                            name="assignment.period"
                            wrapperCol={{ span: 12 }}
                        >
                            <InputNumber
                                min={0}
                                max={1000}
                                step={1}
                                size="large"
                                maxLength={3}
                                addonAfter={<FormattedMessage id="assignmentGroup.assignment.period.days" />}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="assignmentGroup.deadline" />} name="deadline">
                            <DatePicker size="large" format="DD/MM/YYYY HH:mm" showTime={{ format: 'HH:mm' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        const { saving } = this.state;
        const submit = this.formRef.current ? this.formRef.current.submit : undefined;

        return (
            <Modal
                title={<FormattedMessage id="assignmentGroup.title" />}
                okText={<FormattedMessage id="button.save" tagName="span" />}
                cancelText={<FormattedMessage id="button.cancel" />}
                onOk={submit}
                onCancel={this.props.onHide}
                open={true}
                maskClosable={false}
                width="760px"
                className="modal"
                okButtonProps={{
                    loading: saving,
                    icon: <Icon component={SaveSvg} />,
                    size: 'large',
                }}
                cancelButtonProps={{
                    type: 'text',
                    size: 'large',
                }}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}
export default injectIntl(AssignmentGroupModal);

interface Props extends WrappedComponentProps {
    assignmentGroupId?: number;
    assignment: Assignment;
    onHide: () => void;
    onSave: () => void;
}

interface State {
    assignmentGroup?: AssignmentGroup;
    groups: Group[];
    saving?: boolean;
}
