import React, { Component } from 'react';
import styles from './DashboardPage.module.scss';
import { Avatar, Button, Card, Col, Divider, Empty, List, Progress, Row, Space, Spin, Statistic, Tag } from 'antd';
import { Link } from 'react-router-dom';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Assessment, CourseUser } from '../../../model/entities';
import assessmentApi from '../../../api/AssessmentApi';
import notificationService from '../../../services/NotificationService';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import courseUserApi from '../../../api/CourseUserApi';
import AssessmentSkillsComponent from './AssessmentSkillsComponent/AssessmentSkillsComponent';

class DashboardPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { assessments: [], courses: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const responses = await Promise.all([
                assessmentApi.list(0, 4, 'notified', false),
                courseUserApi.list(0, 5, 'name', true),
            ]);
            const assessments = responses[0].content;
            const courses = responses[1].content;
            this.setState({ assessments, courses });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderLoader = (): React.ReactElement | undefined => {
        return (
            <div className={styles.loader}>
                <Spin /> <FormattedMessage id="status.loading" tagName="h3" />
            </div>
        );
    };

    renderAssessments = (): React.ReactElement | undefined => {
        const { assessments, loading } = this.state;

        return (
            <>
                <h1 className={styles.title}>
                    <FormattedMessage id="dashboard.assessments" />
                </h1>
                <Row gutter={[16, 16]}>
                    {assessments.map((assessment) => (
                        <Col span={6} key={assessment.id}>
                            {this.renderAssessment(assessment)}
                        </Col>
                    ))}
                </Row>

                {loading && this.renderLoader()}
                {!loading && assessments.length === 0 && (
                    <div>
                        <Empty
                            image={false}
                            description={<FormattedMessage id="dashboard.assessments.empty" />}
                            className={styles.empty}
                        />
                    </div>
                )}
                {!loading && assessments.length > 0 && (
                    <Link to="/assessments">
                        <Button type="link" className={styles.link}>
                            <FormattedMessage id="button.seeAll" />
                        </Button>
                    </Link>
                )}
            </>
        );
    };

    renderAssessment = (assessment: Assessment): React.ReactElement | undefined => {
        const { intl } = this.props;

        return (
            <Link to={`/assessments/${assessment.id}`} key={assessment.id}>
                <Card title={assessment.assignment.name} bordered={false} className={styles.card}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Statistic
                                title={<FormattedMessage id="dashboard.assessments.notified" />}
                                valueRender={() =>
                                    assessment.notified &&
                                    intl.formatDate(assessment.notified.toISOString(), {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })
                                }
                            />
                        </Col>
                        <Col span={12} className={styles.right}>
                            <Statistic
                                title={<FormattedMessage id="dashboard.assessments.started" />}
                                valueRender={() =>
                                    assessment.started &&
                                    intl.formatDate(assessment.started.toISOString(), {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Statistic
                                title={<FormattedMessage id="dashboard.assessments.finished" />}
                                valueRender={() =>
                                    assessment.finished &&
                                    intl.formatDate(assessment.finished.toISOString(), {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })
                                }
                            />
                        </Col>

                        <Col span={12} className={styles.right} hidden={!assessment.assignment.publicResult}>
                            <Statistic
                                title={<FormattedMessage id="dashboard.assessments.result" />}
                                valueRender={() => {
                                    if (assessment.result && assessment.result.passed) {
                                        return (
                                            <Tag color="green" className={styles.tag}>
                                                <FormattedMessage id="assessment.result.passed.true" />
                                            </Tag>
                                        );
                                    } else if (assessment.result && !assessment.result.passed) {
                                        return (
                                            <Tag color="red" className={styles.tag}>
                                                <FormattedMessage id="assessment.result.passed.false" />
                                            </Tag>
                                        );
                                    }
                                }}
                            />
                        </Col>
                        <Col span={24} hidden={!assessment.assignment.publicResult || !assessment.result}>
                            <Statistic
                                title={<FormattedMessage id="dashboard.assessments.marks" />}
                                valueRender={() => (
                                    <Row gutter={28}>
                                        <Col span={12}>
                                            <Progress
                                                strokeLinecap="square"
                                                type="circle"
                                                percent={assessment.result?.mark!}
                                                format={() =>
                                                    assessment.assignment.publicResult &&
                                                    assessment.result && (
                                                        <>
                                                            <FormattedNumber
                                                                value={assessment.result!.mark!}
                                                                maximumFractionDigits={1}
                                                            />
                                                            %
                                                        </>
                                                    )
                                                }
                                                className={styles.results}
                                            />
                                        </Col>
                                        <Col span={12} className={styles.legend}>
                                            <p className={styles.passed}>
                                                <span />
                                                <FormattedMessage id="dashboard.assessments.marks.passed" />
                                            </p>
                                            <p className={styles.failed}>
                                                <span />
                                                <FormattedMessage id="dashboard.assessments.marks.failed" />
                                            </p>
                                        </Col>
                                    </Row>
                                )}
                            ></Statistic>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            {assessment.id && <AssessmentSkillsComponent assessmentId={assessment.id} />}
                        </Col>
                    </Row>
                </Card>
            </Link>
        );
    };

    renderCourses = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { courses, loading } = this.state;

        if (auth && !auth.external) {
            return (
                <>
                    <h1 className={styles.title}>
                        <FormattedMessage id="dashboard.courses" />
                    </h1>
                    {courses.length > 0 && (
                        <List
                            className={styles.files}
                            itemLayout="horizontal"
                            dataSource={courses}
                            locale={{
                                emptyText: <></>,
                            }}
                            renderItem={(course) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar shape="square" size="large">
                                                {course.name!.charAt(0)}
                                            </Avatar>
                                        }
                                        title={<span>{course.name}</span>}
                                        description={
                                            <Space split={<Divider type="vertical" />}>
                                                <span>
                                                    <FormattedMessage id="dashboard.courses.enrolledOn" />:{' '}
                                                    {course.enrolledOn && (
                                                        <FormattedDate
                                                            value={course.enrolledOn.toISOString()}
                                                            day="2-digit"
                                                            month="2-digit"
                                                            year="numeric"
                                                            hour="2-digit"
                                                            minute="2-digit"
                                                            hour12={false}
                                                        />
                                                    )}
                                                </span>
                                                <span>
                                                    <FormattedMessage id="dashboard.courses.completedOn" />:{' '}
                                                    {course.completedOn && (
                                                        <FormattedDate
                                                            value={course.completedOn.toISOString()}
                                                            day="2-digit"
                                                            month="2-digit"
                                                            year="numeric"
                                                            hour="2-digit"
                                                            minute="2-digit"
                                                            hour12={false}
                                                        />
                                                    )}
                                                </span>
                                                <span>
                                                    <FormattedMessage id="dashboard.courses.completionPercentage" />:{' '}
                                                    <FormattedNumber
                                                        value={course.completionPercentage || 0}
                                                        maximumFractionDigits={1}
                                                    />
                                                    %
                                                </span>
                                            </Space>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    )}

                    {loading && this.renderLoader()}
                    {!loading && courses.length === 0 && (
                        <Empty
                            image={false}
                            description={<FormattedMessage id="dashboard.courses.empty" />}
                            className={styles.empty}
                        />
                    )}
                    {!loading && courses.length > 0 && (
                        <Link to="/courses">
                            <Button type="link" className={styles.link}>
                                <FormattedMessage id="button.seeAll" />
                            </Button>
                        </Link>
                    )}
                </>
            );
        }
    };

    render() {
        return (
            <LayoutComponent>
                {this.renderAssessments()}
                {this.renderCourses()}
            </LayoutComponent>
        );
    }
}
export default injectIntl(DashboardPage);

interface Props extends WrappedComponentProps {}

interface State {
    assessments: Assessment[];
    courses: CourseUser[];
    loading?: boolean;
}
