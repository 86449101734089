import Icon from '@ant-design/icons';
import { Collapse, Divider, List, Modal, Space, Tag } from 'antd';
import _, { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import assessmentAnswerApi from '../../../../../../api/AssessmentAnswerApi';
import CustomContext from '../../../../../../context/CustomContext';
import {
    Assessment,
    AssessmentAnswer,
    AssessmentAnswerNumeric,
    AssessmentAnswerOption,
    AssessmentAnswerText,
    AssignmentQuestionOptionAnswer,
} from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import { ReactComponent as ChevronDownSvg } from '../../../../../../resources/images/chevron--down.svg';
import styles from './AssessmentModal.module.scss';
import './AssessmentModal.css';
import stringService from '../../../../../../services/StringService';

class AssessmentModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { assessmentAnswersBySkill: {} };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { assessment } = this.props;
        try {
            this.setState({ loading: true });
            await this.get(assessment);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    get = async (assessment: Assessment) => {
        const assessmentAnswers = await assessmentAnswerApi.list(assessment.id!);
        const assessmentAnswersBySkill: Dictionary<AssessmentAnswer[]> = _.groupBy(
            assessmentAnswers,
            (a) => a.question.skillId,
        );

        this.setState({ assessmentAnswersBySkill });
    };

    /*** COMPONENTS ***/

    renderAnswers = (): React.ReactElement | undefined => {
        const { assessmentAnswersBySkill, loading } = this.state;

        return (
            <Collapse ghost size="small">
                {Object.entries(assessmentAnswersBySkill).map(([skillId, assessmentAnswers]) => {
                    const skillName = assessmentAnswers[0].question.skillName;
                    const passedCount = assessmentAnswers.filter((a) => a.passed).length;
                    const count = assessmentAnswers.length;
                    const answers = assessmentAnswers.sort((a, b) =>
                        stringService.sort(a.question.question, b.question.question),
                    );

                    return (
                        <Collapse.Panel
                            header={
                                <div className={styles.skillHeader}>
                                    <p>
                                        {skillName}
                                        <Icon component={ChevronDownSvg} className="arrow" />
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            id="assessmentAnswer.passed"
                                            values={{ passedCount, count }}
                                        />
                                    </p>
                                </div>
                            }
                            key={skillId}
                            showArrow={false}
                            className={styles.skill}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={answers}
                                locale={{
                                    emptyText: <></>,
                                }}
                                renderItem={(assessmentAnswer) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={assessmentAnswer.question.question}
                                            description={
                                                <>
                                                    <p>{this.renderAnswer(assessmentAnswer)}</p>
                                                    <p>{this.renderCorrectAnswer(assessmentAnswer)}</p>
                                                </>
                                            }
                                            className={styles.question}
                                        />
                                        <div>{this.renderAnswerPassed(assessmentAnswer)}</div>
                                    </List.Item>
                                )}
                                loading={loading}
                            />
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        );
    };

    renderAnswer = (answer: AssessmentAnswer): React.ReactElement | undefined => {
        if (answer.type === 'TEXT') {
            const answerText = answer as AssessmentAnswerText;
            return <span className={styles.answer}>{answerText.answer}</span>;
        } else if (answer.type === 'NUMERIC') {
            const answerNumeric = answer as AssessmentAnswerNumeric;
            return <span className={styles.answer}>{answerNumeric.answer}</span>;
        } else if (answer.type === 'OPTION') {
            const answerOption = answer as AssessmentAnswerOption;
            return (
                <span className={styles.answer}>
                    <Space split={<Divider type="vertical" />}>
                        {answerOption.answers
                            .filter((a) => a.result)
                            .map((a) => answerOption.question.answers.find((qa) => qa.uuid === a.uuid)?.answer)
                            .join(', ')}
                    </Space>
                </span>
            );
        }
    };

    renderAnswerPassed = (answer: AssessmentAnswer): React.ReactElement | undefined => {
        if (answer.passed) {
            return (
                <Tag color="green">
                    <FormattedMessage id="assessmentAnswer.passed.true" />
                </Tag>
            );
        } else {
            return (
                <Tag color="red">
                    <FormattedMessage id="assessmentAnswer.passed.false" />
                </Tag>
            );
        }
    };

    renderCorrectAnswer = (answer: AssessmentAnswer): React.ReactElement | undefined => {
        if (!answer.passed) {
            if (answer.type === 'TEXT') {
                const answerText = answer.correctAnswer as string;
                return (
                    <span className={styles.correctAnswer}>
                        <span>
                            <FormattedMessage id="assessmentAnswer.correctAnswer" />
                        </span>{' '}
                        {answerText}
                    </span>
                );
            } else if (answer.type === 'NUMERIC') {
                const answerNumeric = answer.correctAnswer as number;
                return (
                    <span className={styles.correctAnswer}>
                        <span>
                            <FormattedMessage id="assessmentAnswer.correctAnswer" />
                        </span>{' '}
                        {answerNumeric}
                    </span>
                );
            } else if (answer.type === 'OPTION') {
                const answerOptions = answer.correctAnswer as AssignmentQuestionOptionAnswer[];
                return (
                    <span className={styles.correctAnswer}>
                        <span>
                            <FormattedMessage id="assessmentAnswer.correctAnswer" />
                        </span>{' '}
                        {answerOptions
                            .map((a) => a.answer)
                            .sort(stringService.sort)
                            .join(', ')}
                    </span>
                );
            }
        }
    };

    render() {
        return (
            <Modal
                title={<FormattedMessage id="assignmentAssessment.modal.title" />}
                okText={<FormattedMessage id="button.ok" />}
                onOk={this.props.onHide}
                onCancel={this.props.onHide}
                open={true}
                maskClosable={false}
                width="900px"
                className="modal"
                okButtonProps={{
                    size: 'large',
                }}
                cancelButtonProps={{
                    hidden: true,
                }}
            >
                {this.renderAnswers()}
            </Modal>
        );
    }
}
export default injectIntl(AssessmentModal);

interface Props extends WrappedComponentProps {
    assessment: Assessment;
    onHide: () => void;
}

interface State {
    assessmentAnswersBySkill: Dictionary<AssessmentAnswer[]>;
    loading?: boolean;
}
