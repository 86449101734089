import React, { Component } from 'react';
import styles from './ProfileSkillsList.module.scss';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../../../resources/images/add.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import { Avatar, Button, Divider, Empty, List, Popconfirm, Space, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../context/CustomContext';
import notificationService from '../../../../../services/NotificationService';
import profileBranchSkillApi from '../../../../../api/ProfileBranchSkillApi';
import { Page } from '../../../../../model/elements';
import { Profile, ProfileSkill } from '../../../../../model/entities';
import ProfileSkillModal from './ProfileSkillModal/ProfileSkillModal';
import SubheaderComponent from '../../../../../components/SubheaderComponent/SubheaderComponent';

class ProfileSkillsList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.profile.id !== prevProps.profile.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        await this.list();
    };

    list = async () => {
        const { profile } = this.props;
        try {
            if (profile.id) {
                this.setState({ loading: true });
                const profileSkillsPage = await profileBranchSkillApi.list(0, 500, 'skill.name', true, profile.id);
                this.setState({ profileSkillsPage });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    showModal = (profileSkill?: ProfileSkill) => {
        const modalVisible = true;
        this.setState({ modalVisible, profileSkill });
    };

    hideModal = async () => {
        const modalVisible = false;
        const profileSkill = undefined;
        this.setState({ modalVisible, profileSkill });
    };

    delete = async (profileSkill: ProfileSkill) => {
        try {
            this.setState({ deleting: profileSkill.id });
            await profileBranchSkillApi.delete(profileSkill);
            this.list();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ deleting: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderList()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { profile } = this.props;
        const disabled = !profile.branchId;

        return (
            <SubheaderComponent
                title={<FormattedMessage id="profileSkills.title" />}
                button={
                    <Tooltip title={<FormattedMessage id="profileSkills.new" />}>
                        <Button
                            type="primary"
                            ghost={!profile.id}
                            icon={<Icon component={AddSvg} />}
                            disabled={!profile.id}
                            onClick={() => this.showModal()}
                            hidden={disabled}
                        ></Button>
                    </Tooltip>
                }
            />
        );
    };

    renderList = (): React.ReactElement | undefined => {
        const { profile } = this.props;
        const { profileSkillsPage, loading, deleting } = this.state;
        const disabled = !profile.branchId;
        const profileSkills = profileSkillsPage ? profileSkillsPage.content : [];

        return (
            <List
                className={styles.files}
                itemLayout="horizontal"
                dataSource={profileSkills}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<FormattedMessage id="profileSkills.empty" />}
                        />
                    ),
                }}
                loading={loading}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Popconfirm
                                title={<FormattedMessage id="profileSkills.delete" />}
                                onConfirm={() => this.delete(item)}
                                okText={<FormattedMessage id="button.yes" />}
                                cancelText={<FormattedMessage id="button.no" />}
                                placement="left"
                            >
                                <Button
                                    type="ghost"
                                    icon={<Icon component={TrashCanSvg} />}
                                    loading={deleting === item.id}
                                    hidden={disabled}
                                />
                            </Popconfirm>,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar shape="square" size="large">
                                    {item.skill.name!.charAt(0)}
                                </Avatar>
                            }
                            title={
                                <span className="link" onClick={() => this.showModal(item)}>
                                    {item.skill.name}
                                </span>
                            }
                            description={
                                <span className="link" onClick={() => this.showModal(item)}>
                                    <Space split={<Divider type="vertical" />}>
                                        <span>
                                            <FormattedMessage id="profileSkill.questions" />: {item.questions}
                                        </span>
                                        <span>
                                            <FormattedMessage id="profileSkill.grades" />: {item.passGrade}% -{' '}
                                            {item.distinctionGrade}%
                                        </span>
                                        <span>
                                            <FormattedMessage id="profileSkill.courses" />:{' '}
                                            {item.courses.map((c) => c.name).join(', ')}
                                        </span>
                                    </Space>
                                </span>
                            }
                        />
                    </List.Item>
                )}
            />
        );
    };

    renderModal = (): React.ReactElement | undefined => {
        const { profile } = this.props;
        const { profileSkill, modalVisible } = this.state;

        if (modalVisible && profile.id) {
            return (
                <ProfileSkillModal
                    profile={profile}
                    profileSkillId={profileSkill && profileSkill.id}
                    onHide={this.hideModal}
                    onSave={this.list}
                />
            );
        }
    };

    render() {
        return (
            <>
                {this.renderContent()}
                {this.renderModal()}
            </>
        );
    }
}
export default injectIntl(ProfileSkillsList);

interface Props extends WrappedComponentProps {
    profile: Profile;
}

interface State {
    profileSkillsPage?: Page<ProfileSkill>;
    loading?: boolean;
    deleting?: number;
    searchText?: string;
    profileSkill?: ProfileSkill;
    modalVisible?: boolean;
}
