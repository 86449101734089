import { Button, Col, Form, FormInstance, List, message, Modal, Row, Upload } from 'antd';
import styles from './SkillFileModal.module.scss';
import Icon from '@ant-design/icons';
import { ReactComponent as SaveSvg } from '../../../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../../resources/images/trash-can.svg';
import { ReactComponent as AttachmentSvg } from '../../../../../../resources/images/attachment.svg';
import { ReactComponent as CloudUploadSvg } from '../../../../../../resources/images/cloud--upload.svg';
import TextArea from 'antd/lib/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import skillFileApi from '../../../../../../api/SkillFileApi';
import CustomContext from '../../../../../../context/CustomContext';
import { SkillFile } from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import FileSizeComponent from '../../../../../../components/FileSizeComponent/FileSizeComponent';

class SkillFileModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly maxFileSize = 100 * 1024 * 1024;

    constructor(props: Props) {
        super(props);
        this.state = { files: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { skillFileId } = this.props;

        try {
            if (skillFileId) {
                await this.get(skillFileId);
            } else {
                await this.new();
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { skillId } = this.props;
        const skillFile: SkillFile = { skillId, file: {} };

        this.setState({ skillFile });
        this.formRef.current!.setFieldsValue(skillFile);
    };

    get = async (id: number) => {
        const skillFile = await skillFileApi.get(id);
        const files: any[] = [
            {
                name: skillFile.file!.name,
            },
        ];

        this.setState({ skillFile, files });
        this.formRef.current!.setFieldsValue(skillFile);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            const file: any = this.state.files[0] instanceof File ? this.state.files[0] : undefined;
            let skillFile: SkillFile = Object.assign({}, this.state.skillFile, values);
            if (file && skillFile.file) {
                skillFile.file.name = file.name;
            }
            skillFile = skillFile.id
                ? await skillFileApi.update(skillFile, file)
                : await skillFileApi.create(skillFile, file);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ skillFile });
            this.props.onSave();
            this.props.onHide();
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'skillFile.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!this.isFileSizeValid(file)) {
            this.setState({ files });
        } else {
            files.push(file);
            this.setState({ files });
        }

        return false;
    };

    isFileSizeValid = (file: UploadFile) => file.size! <= this.maxFileSize;

    validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && !value.file && !value.name) {
            callback(this.props.intl.formatMessage({ id: 'status.mandatory' }));
        }
        if (value && value.file && !this.isFileSizeValid(value.file)) {
            callback(this.props.intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    removeFile = () => {
        const files: UploadFile[] = [];
        this.formRef.current!.setFieldsValue({
            file: files,
        });
        this.setState({ files });
    };

    /*** COMPONENTS ***/

    renderForm = (): React.ReactElement | undefined => {
        const { files } = this.state;

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Row>
                    <Col span={10}>
                        <Form.Item
                            name="file"
                            valuePropName="files"
                            rules={[
                                {
                                    validator: this.validateFile,
                                },
                            ]}
                            extra={
                                <>
                                    <FormattedMessage id="file.size" /> <FileSizeComponent value={this.maxFileSize} />
                                </>
                            }
                        >
                            <Upload.Dragger beforeUpload={this.uploadFile} fileList={files} showUploadList={false}>
                                <Icon component={CloudUploadSvg} /> <FormattedMessage id="skillFile.file.upload" />
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={14}>{this.renderFiles()}</Col>
                </Row>
                <Form.Item label={<FormattedMessage id="skillFile.description" />} name="description">
                    <TextArea maxLength={1000} rows={2} size="large" showCount />
                </Form.Item>
            </Form>
        );
    };

    renderFiles = (): React.ReactElement | undefined => {
        const { files, skillFile } = this.state;

        return (
            <List
                className={styles.files}
                itemLayout="horizontal"
                dataSource={files}
                locale={{ emptyText: <></> }}
                renderItem={(file) => (
                    <List.Item
                        actions={[
                            <Button
                                icon={<Icon component={TrashCanSvg} />}
                                danger
                                size="small"
                                onClick={this.removeFile}
                            />,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Icon component={AttachmentSvg} />}
                            title={file.name}
                            description={<FileSizeComponent value={file.size || skillFile!.file!.size} />}
                        />
                    </List.Item>
                )}
            />
        );
    };

    render() {
        const { saving } = this.state;
        const submit = this.formRef.current ? this.formRef.current.submit : undefined;

        return (
            <Modal
                title={<FormattedMessage id="skillFile.title" />}
                okText={<FormattedMessage id="button.save" tagName="span" />}
                cancelText={<FormattedMessage id="button.cancel" />}
                onOk={submit}
                onCancel={this.props.onHide}
                open={true}
                maskClosable={false}
                width="760px"
                className="modal"
                okButtonProps={{
                    loading: saving,
                    icon: <Icon component={SaveSvg} />,
                    size: 'large',
                }}
                cancelButtonProps={{
                    type: 'text',
                    size: 'large',
                }}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}
export default injectIntl(SkillFileModal);

interface Props extends WrappedComponentProps {
    skillFileId?: number;
    skillId: number;
    onHide: () => void;
    onSave: () => void;
}

interface State {
    skillFile?: SkillFile;
    files: UploadFile[];
    saving?: boolean;
}
