import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../../../resources/images/add.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import { Button, Popconfirm, Table, TablePaginationConfig, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../context/CustomContext';
import { Page } from '../../../../../model/elements';
import { Assignment, AssignmentUser, AssignmentUserWithAssessment, Course } from '../../../../../model/entities';
import tableService from '../../../../../services/TableService';
import notificationService from '../../../../../services/NotificationService';
import assignmentUserApi from '../../../../../api/AssignmentUserApi';
import AssignmentUserModal from './AssignmentUserModal/AssignmentUserModal';
import { Link } from 'react-router-dom';

class AssignmentUsersList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.assignment.id && this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.assignment.id !== prevProps.assignment.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        const { assignment } = this.props;
        try {
            this.setState({ loading: true });
            const assignmentUsersPage = await assignmentUserApi.list(
                0,
                tableService.pageSize,
                'user.lastName',
                true,
                assignment.id,
            );
            this.setState({ assignmentUsersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { assignment } = this.props;
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const assignmentUsersPage = await assignmentUserApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                assignment.id,
                undefined,
                searchText,
            );
            this.setState({ assignmentUsersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    refresh = async () => {
        const { assignment } = this.props;
        const { assignmentUsersPage, searchText } = this.state;
        try {
            this.setState({ loading: true });
            const sortField = assignmentUsersPage!.sort.field!;
            const sortOrder = assignmentUsersPage!.sort.order!;
            const assignmentUsersPageNew = await assignmentUserApi.list(
                0,
                tableService.pageSize,
                sortField,
                sortOrder,
                assignment.id,
                undefined,
                searchText,
            );
            this.setState({ assignmentUsersPage: assignmentUsersPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    showModal = (assignmentUser?: AssignmentUserWithAssessment) => {
        const modalVisible = true;
        this.setState({ modalVisible, assignmentUser });
    };

    hideModal = async () => {
        const modalVisible = false;
        const assignmentUser = undefined;
        this.setState({ modalVisible, assignmentUser });
    };

    delete = async (assignmentUser: AssignmentUser) => {
        try {
            this.setState({ deleting: assignmentUser.id });
            await assignmentUserApi.delete(assignmentUser);
            this.refresh();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ deleting: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { assignment } = this.props;
        const { assignmentUsersPage, loading, deleting } = this.state;
        const items = assignmentUsersPage ? assignmentUsersPage.content : [];
        const columns: ColumnsType<AssignmentUserWithAssessment> = [
            {
                title: <FormattedMessage id="assignmentUser.user.fullName" />,
                dataIndex: 'user.lastName',
                key: 'user.lastName',
                sorter: !!assignment.id,
                defaultSortOrder: 'ascend',
                render: (value: string, item: AssignmentUserWithAssessment) => (
                    <Link to={`/admin/users/${item.user.id}`}>
                        {item.user.lastName}, {item.user.firstName}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="assignmentUser.assessment.started" />,
                dataIndex: ['assessment', 'started'],
                key: 'started',
                width: '170px',
                align: 'center',
                render: (value: string, item: AssignmentUserWithAssessment) =>
                    value && (
                        <Link to={`/admin/users/${item.user.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assignmentUser.assessment.finished" />,
                dataIndex: ['assessment', 'finished'],
                key: 'finished',
                width: '170px',
                align: 'center',
                render: (value: string, item: AssignmentUserWithAssessment) =>
                    value && (
                        <Link to={`/admin/users/${item.user.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assignmentUser.deadline" />,
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: !!assignment.id,
                width: '170px',
                align: 'center',
                render: (value: string, item: AssignmentUserWithAssessment) =>
                    value && (
                        <Link to={`/admin/users/${item.user.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assignmentUser.assessment.result.passed" />,
                dataIndex: ['assessment', 'result', 'passed'],
                key: 'resultPassed',
                width: '120px',
                align: 'center',
                render: (value: boolean, item: AssignmentUserWithAssessment) => {
                    if (value === true) {
                        return (
                            <Link to={`/admin/users/${item.user.id}`}>
                                <Tag color="green">
                                    <FormattedMessage id="assessment.result.passed.true" />
                                </Tag>
                            </Link>
                        );
                    } else if (value === false) {
                        return (
                            <Link to={`/admin/users/${item.user.id}`}>
                                <Tag color="red">
                                    <FormattedMessage id="assessment.result.passed.false" />
                                </Tag>
                            </Link>
                        );
                    }
                },
            },
            {
                title: <FormattedMessage id="assignmentUser.assessment.result.mark" />,
                dataIndex: ['assessment', 'result', 'mark'],
                key: 'result.mark',
                width: '100px',
                align: 'right',
                render: (value: number, item: AssignmentUserWithAssessment) =>
                    value && (
                        <Link to={`/admin/users/${item.user.id}`}>
                            <FormattedNumber value={value} maximumFractionDigits={1} />%
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="assignmentUser.assessment.result.courses" />,
                dataIndex: ['assessment', 'result', 'courses'],
                key: 'result.courses',
                width: '80px',
                align: 'right',
                render: (value: Course[], item: AssignmentUserWithAssessment) =>
                    value &&
                    value.length > 0 && (
                        <Tooltip title={value.map((c) => c.name).join(', ')} placement="left">
                            <Link to={`/admin/users/${item.user.id}`}>{value.length}</Link>
                        </Tooltip>
                    ),
            },
            {
                title: (
                    <Tooltip title={<FormattedMessage id="assignmentUsers.new" tagName="span" />}>
                        <Button
                            type="primary"
                            ghost={!assignment.id}
                            icon={<Icon component={AddSvg} />}
                            onClick={(e) => this.showModal()}
                            disabled={!assignment.id}
                        ></Button>
                    </Tooltip>
                ),
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: '100px',
                render: (value: string, item: AssignmentUserWithAssessment) => (
                    <Popconfirm
                        title={<FormattedMessage id="assignmentUsers.delete" />}
                        onConfirm={() => this.delete(item)}
                        okText={<FormattedMessage id="button.yes" />}
                        cancelText={<FormattedMessage id="button.no" />}
                        placement="left"
                    >
                        <Button type="ghost" icon={<Icon component={TrashCanSvg} />} loading={deleting === item.id} />
                    </Popconfirm>
                ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(assignmentUsersPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    renderModal = (): React.ReactElement | undefined => {
        const { assignment } = this.props;
        const { assignmentUser, modalVisible } = this.state;

        if (modalVisible && assignment.id) {
            return (
                <AssignmentUserModal
                    assignment={assignment}
                    assignmentUserId={assignmentUser?.id}
                    onHide={this.hideModal}
                    onSave={this.refresh}
                />
            );
        }
    };

    render() {
        return (
            <>
                {this.renderTable()}
                {this.renderModal()}
            </>
        );
    }
}
export default injectIntl(AssignmentUsersList);

interface Props extends WrappedComponentProps {
    assignment: Assignment;
}

interface State {
    assignmentUsersPage?: Page<AssignmentUserWithAssessment>;
    loading?: boolean;
    deleting?: number;
    searchText?: string;
    assignmentUser?: AssignmentUserWithAssessment;
    modalVisible?: boolean;
}
