import React, { Component } from 'react';
import { Table, TablePaginationConfig, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../context/CustomContext';
import { Page } from '../../../../../model/elements';
import { AssignmentUserWithAssessment, Course, User } from '../../../../../model/entities';
import tableService from '../../../../../services/TableService';
import notificationService from '../../../../../services/NotificationService';
import assignmentUserApi from '../../../../../api/AssignmentUserApi';
import { Link } from 'react-router-dom';

class UserAssignmentsList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.user.id && this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.user.id !== prevProps.user.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        const { user } = this.props;
        try {
            this.setState({ loading: true });
            const assignmentUsersPage = await assignmentUserApi.list(
                0,
                tableService.pageSize,
                'assignment.published',
                false,
                undefined,
                user.id,
            );
            this.setState({ assignmentUsersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { user } = this.props;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const assignmentUsersPage = await assignmentUserApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                undefined,
                user.id,
            );
            this.setState({ assignmentUsersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { user } = this.props;
        const { assignmentUsersPage, loading } = this.state;
        const items = assignmentUsersPage ? assignmentUsersPage.content : [];
        const columns: ColumnsType<AssignmentUserWithAssessment> = [
            {
                title: <FormattedMessage id="userAssignment.assignment.name" />,
                dataIndex: 'assignment.name',
                key: 'assignment.name',
                sorter: !!user.id,
                render: (value: string, item: AssignmentUserWithAssessment) => (
                    <Link to={`/admin/assignments/${item.assignment.id}`}>{item.assignment.name}</Link>
                ),
            },
            {
                title: <FormattedMessage id="userAssignment.assignment.published" />,
                dataIndex: 'assignment.published',
                key: 'assignment.published',
                sorter: !!user.id,
                defaultSortOrder: 'descend',
                align: 'center',
                width: '130px',
                render: (value: string, item: AssignmentUserWithAssessment) =>
                    item.assignment.published && (
                        <Link to={`/admin/assignments/${item.assignment.id}`}>
                            <FormattedDate
                                value={item.assignment.published?.toISOString()}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="userAssignment.deadline" />,
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: !!user.id,
                align: 'center',
                width: '130px',
                render: (value: string, item: AssignmentUserWithAssessment) =>
                    item.deadline && (
                        <Link to={`/admin/assignments/${item.assignment.id}`}>
                            <FormattedDate
                                value={item.deadline?.toISOString()}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="userAssignment.assessment.started" />,
                dataIndex: ['assessment', 'started'],
                key: 'started',
                width: '170px',
                align: 'center',
                render: (value: string, item: AssignmentUserWithAssessment) =>
                    value && (
                        <Link to={`/admin/assignments/${item.assignment.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="userAssignment.assessment.finished" />,
                dataIndex: ['assessment', 'finished'],
                key: 'finished',
                width: '170px',
                align: 'center',
                render: (value: string, item: AssignmentUserWithAssessment) =>
                    value && (
                        <Link to={`/admin/assignments/${item.assignment.id}`}>
                            <FormattedDate
                                value={value}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="userAssignment.assessment.result.passed" />,
                dataIndex: ['assessment', 'result', 'passed'],
                key: 'resultPassed',
                width: '50px',
                align: 'center',
                render: (value: boolean, item: AssignmentUserWithAssessment) => {
                    if (value === true) {
                        return (
                            <Link to={`/admin/assignments/${item.assignment.id}`}>
                                <Tag color="green">
                                    <FormattedMessage id="assessment.result.passed.true" />
                                </Tag>
                            </Link>
                        );
                    } else if (value === false) {
                        return (
                            <Link to={`/admin/assignments/${item.assignment.id}`}>
                                <Tag color="red">
                                    <FormattedMessage id="assessment.result.passed.false" />
                                </Tag>
                            </Link>
                        );
                    }
                },
            },
            {
                title: <FormattedMessage id="userAssignment.assessment.result.mark" />,
                dataIndex: ['assessment', 'result', 'mark'],
                key: 'result.mark',
                width: '40px',
                align: 'right',
                render: (value: number, item: AssignmentUserWithAssessment) =>
                    value && (
                        <Link to={`/admin/assignments/${item.assignment.id}`}>
                            <FormattedNumber value={value} maximumFractionDigits={1} />%
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="userAssignment.assessment.result.courses" />,
                dataIndex: ['assessment', 'result', 'courses'],
                key: 'result.courses',
                width: '40px',
                align: 'right',
                render: (value: Course[], item: AssignmentUserWithAssessment) =>
                    value &&
                    value.length > 0 && (
                        <Tooltip title={value.map((c) => c.name).join(', ')} placement="left">
                            <Link to={`/admin/assignments/${item.assignment.id}`}>{value.length}</Link>
                        </Tooltip>
                    ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(assignmentUsersPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return this.renderTable();
    }
}
export default injectIntl(UserAssignmentsList);

interface Props extends WrappedComponentProps {
    user: User;
}

interface State {
    assignmentUsersPage?: Page<AssignmentUserWithAssessment>;
    loading?: boolean;
}
