import React, { Component } from 'react';
import styles from './ProfilesPage.module.scss';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../resources/images/add.svg';
import { ReactComponent as SearchSvg } from '../../../resources/images/search.svg';
import ModelicalLogo from '../../../resources/images/modelical-logo.png';
import { Button, Col, Input, Row, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import profileBranchApi from '../../../api/ProfileBranchApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Profile } from '../../../model/entities';
import tableService from '../../../services/TableService';
import notificationService from '../../../services/NotificationService';

class ProfilesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            includeNonBranchProfiles: true,
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const branchId = this.context.auth!.branchId!;
        const { includeNonBranchProfiles } = this.state;
        try {
            this.setState({ loading: true });
            const profilesPage = await profileBranchApi.list(
                0,
                tableService.pageSize,
                'name',
                true,
                branchId,
                includeNonBranchProfiles,
            );
            this.setState({ profilesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const branchId = this.context.auth!.branchId!;
        const { includeNonBranchProfiles, searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const profilesPage = await profileBranchApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                branchId,
                includeNonBranchProfiles,
                searchText,
            );
            this.setState({ profilesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    search = async (searchText: string) => {
        const branchId = this.context.auth!.branchId!;
        const { profilesPage, includeNonBranchProfiles } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = profilesPage!.size;
            const sortField = profilesPage!.sort.field!;
            const sortOrder = profilesPage!.sort.order!;
            const profilesPageNew = await profileBranchApi.list(
                0,
                pageSize,
                sortField,
                sortOrder,
                branchId,
                includeNonBranchProfiles,
                searchText,
            );
            this.setState({ profilesPage: profilesPageNew, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    filter = async (includeNonBranchProfiles: boolean) => {
        const branchId = this.context.auth!.branchId!;
        const { profilesPage, searchText } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = profilesPage!.size;
            const sortField = profilesPage!.sort.field!;
            const sortOrder = profilesPage!.sort.order!;
            const profilesPageNew = await profileBranchApi.list(
                0,
                pageSize,
                sortField,
                sortOrder,
                branchId,
                includeNonBranchProfiles,
                searchText,
            );
            this.setState({ profilesPage: profilesPageNew, includeNonBranchProfiles });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderTable()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const placeholder: string = intl.formatMessage({ id: 'profiles.search' });

        return (
            <div className="toolbar">
                <Input.Group size="large">
                    <Row gutter={16}>
                        <Col span={20}>
                            <Search
                                placeholder={placeholder}
                                onSearch={this.search}
                                size="large"
                                bordered={false}
                                addonBefore={<Icon component={SearchSvg} />}
                                className="search"
                            />
                        </Col>
                        <Col span={4} className={styles.templates}>
                            <Switch
                                onChange={this.filter}
                                defaultChecked={true}
                                checkedChildren={<FormattedMessage id="profiles.search.templates" />}
                                unCheckedChildren={<FormattedMessage id="profiles.search.templates" />}
                            />
                        </Col>
                    </Row>
                </Input.Group>
                <Link to="/admin/profiles/new">
                    <Button type="primary" size="large" icon={<Icon component={AddSvg} />}>
                        <FormattedMessage id="profiles.new" tagName="span" />
                    </Button>
                </Link>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { profilesPage, loading } = this.state;
        const items = profilesPage ? profilesPage.content : [];
        const columns: ColumnsType<Profile> = [
            {
                title: <FormattedMessage id="profiles.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, item: Profile) => <Link to={`/admin/profiles/${item.id}`}>{value}</Link>,
            },

            {
                title: <FormattedMessage id="profile.passGrade" />,
                dataIndex: 'passGrade',
                key: 'passGrade',
                width: '150px',
                align: 'right',
                render: (value: string, item: Profile) => <Link to={`/admin/profiles/${item.id}`}>{value}%</Link>,
            },
            {
                title: <FormattedMessage id="profile.skillsNumber" />,
                dataIndex: 'skillsNumber',
                key: 'skillsNumber',
                width: '150px',
                align: 'right',
                render: (value: string, item: Profile) => <Link to={`/admin/profiles/${item.id}`}>{value}</Link>,
            },
            {
                dataIndex: 'branchId',
                key: 'branchId',
                width: '50px',
                align: 'right',
                render: (value: number, item: Profile) =>
                    !value && (
                        <Tooltip title={<FormattedMessage id="profile.branchId.global" />} placement="left">
                            <Link to={`/admin/profiles/${item.id}`}>
                                <img src={ModelicalLogo} alt="Modelical" className={styles.logo} />
                            </Link>
                        </Tooltip>
                    ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(profilesPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent pageId="profiles">{this.renderContent()}</LayoutComponent>;
    }
}
export default injectIntl(ProfilesPage);

interface Props extends WrappedComponentProps {}

interface State {
    profilesPage?: Page<Profile>;
    loading?: boolean;
    includeNonBranchProfiles: boolean;
    searchText?: string;
}
