import { Auth } from '../model/entities';
import { LanguageType } from '../model/types';
import authService from './AuthService';

class LanguageService {
    readonly key = 'lang';
    readonly languages: LanguageType[] = ['es', 'en'];

    get = (auth: Auth | undefined): LanguageType => {
        let language: LanguageType | undefined;
        if (auth && authService.getCurrentApplication() === 'SUPER_ADMIN') {
            var params = new URLSearchParams(window.location.search);
            var paramValue = params.get(this.key);
            const storedLanguage = localStorage.getItem(this.key);

            if (paramValue && this.languages.includes(paramValue as LanguageType)) {
                language = paramValue as LanguageType;
                localStorage.setItem(this.key, language);
            } else if (storedLanguage && this.languages.includes(storedLanguage as LanguageType)) {
                language = storedLanguage as LanguageType;
            }
        } else if (auth && auth.branchId) {
            language = auth.branches.filter((b) => b.id === auth.branchId)[0].language as LanguageType;
        }

        // if language is not retrieved, set default language
        if (!language && navigator.language) {
            language = navigator.language && navigator.language.startsWith('en') ? 'en' : 'es';
        }
        if (!language) {
            language = 'es';
        }

        return language;
    };
}

const languageService: LanguageService = new LanguageService();
export default languageService;
