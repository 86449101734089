import React, { Component } from 'react';
import { Col, DatePicker, Form, FormInstance, InputNumber, message, Modal, Row, Select } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as SaveSvg } from '../../../../../../resources/images/save.svg';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../../context/CustomContext';
import { Assignment, AssignmentUser, User } from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import userApi from '../../../../../../api/UserApi';
import assignmentUserApi from '../../../../../../api/AssignmentUserApi';
import dayjs from 'dayjs';

class AssignmentUserModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { users: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { assignmentUserId } = this.props;

        try {
            if (assignmentUserId) {
                await this.get(assignmentUserId);
            } else {
                await this.new();
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { assignment } = this.props;
        const assignmentUser: AssignmentUser = {
            assignmentId: assignment.id,
            deadline: assignment.period
                ? dayjs()
                      .add(assignment.period, 'days')
                      .set('hour', 18)
                      .set('minute', 0)
                      .set('second', 0)
                      .set('millisecond', 0)
                : undefined,
        };
        const usersPage = await userApi.list(0, 10, 'lastName', true, assignment.branchId!);
        const users = usersPage.content;

        this.setState({ assignmentUser, users });
        this.formRef.current!.setFieldsValue(assignmentUser);
        this.formRef.current!.setFieldValue('assignment.period', assignment.period);
    };

    get = async (id: number) => {
        const { assignment } = this.props;
        const responses = await Promise.all([
            assignmentUserApi.get(id),
            userApi.list(0, 10, 'lastName', true, assignment.branchId!),
        ]);
        const assignmentUser = responses[0];
        const users = responses[1].content;

        this.setState({ assignmentUser, users });
        this.formRef.current!.setFieldsValue(assignmentUser);
        this.formRef.current!.setFieldValue('assignment.period', assignment.period);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let assignmentUser: AssignmentUser = Object.assign({}, this.state.assignmentUser, values);

            assignmentUser = assignmentUser.id
                ? await assignmentUserApi.update(assignmentUser)
                : await assignmentUserApi.create(assignmentUser);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ assignmentUser });
            this.props.onSave();
            this.props.onHide();
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'assignmentUser.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    searchUsers = async (searchText: string) => {
        const { assignment } = this.props;
        try {
            const usersPage = await userApi.list(0, 10, 'lastName', true, assignment.branchId!, undefined, searchText);
            const users = usersPage.content;

            this.setState({ users });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { users } = this.state;
        const userOptions = users.map((user) => (
            <Select.Option key={user.id!} value={user.id!}>
                {user.lastName}, {user.firstName} - {user.email}
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={<FormattedMessage id="assignmentUser.user" />}
                            name="userId"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                placeholder={intl.formatMessage({ id: 'assignmentUser.user.placeholder' })}
                                showSearch
                                filterOption={false}
                                onSearch={this.searchUsers}
                            >
                                {userOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="assignmentUser.assignment.period" />}
                            name="assignment.period"
                            wrapperCol={{ span: 12 }}
                        >
                            <InputNumber
                                min={0}
                                max={1000}
                                step={1}
                                size="large"
                                maxLength={3}
                                addonAfter={<FormattedMessage id="assignmentUser.assignment.period.days" />}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="assignmentUser.deadline" />} name="deadline">
                            <DatePicker size="large" format="DD/MM/YYYY HH:mm" showTime={{ format: 'HH:mm' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        const { saving } = this.state;
        const submit = this.formRef.current ? this.formRef.current.submit : undefined;

        return (
            <Modal
                title={<FormattedMessage id="assignmentUser.title" />}
                okText={<FormattedMessage id="button.save" tagName="span" />}
                cancelText={<FormattedMessage id="button.cancel" />}
                onOk={submit}
                onCancel={this.props.onHide}
                open={true}
                maskClosable={false}
                width="760px"
                className="modal"
                okButtonProps={{
                    loading: saving,
                    icon: <Icon component={SaveSvg} />,
                    size: 'large',
                }}
                cancelButtonProps={{
                    type: 'text',
                    size: 'large',
                }}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}
export default injectIntl(AssignmentUserModal);

interface Props extends WrappedComponentProps {
    assignmentUserId?: number;
    assignment: Assignment;
    onHide: () => void;
    onSave: () => void;
}

interface State {
    assignmentUser?: AssignmentUser;
    users: User[];
    saving?: boolean;
}
