import { Profile } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';

class ProfileBranchApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        branchId: number,
        includeNonBranchProfiles: boolean,
        searchText?: string,
    ): Promise<Page<Profile>> => {
        const response = await apiAxios.get<Page<Profile>>('/profiles/branches', {
            params: { page, size, sortField, sortOrder, branchId, includeNonBranchProfiles, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<Profile> => {
        const response = await apiAxios.get<Profile>(`/profiles/branches/${id}`);
        return response.data;
    };

    create = async (profile: Profile): Promise<Profile> => {
        const response = await apiAxios.post<Profile>('/profiles/branches', profile);
        return response.data;
    };

    update = async (profile: Profile): Promise<Profile> => {
        const response = await apiAxios.put<Profile>(`/profiles/branches/${profile.id}`, profile);
        return response.data;
    };

    delete = async (profile: Profile): Promise<void> => {
        await apiAxios.delete(`/profiles/branches/${profile.id}`);
    };
}

const profileBranchApi: ProfileBranchApi = new ProfileBranchApi();
export default profileBranchApi;
