import React, { Component } from 'react';
import styles from './LoginPage.module.scss';
import Icon from '@ant-design/icons';
import { ReactComponent as Login1 } from '../../resources/images/login-1.svg';
import { ReactComponent as Login2 } from '../../resources/images/login-2.svg';
import { ReactComponent as Login3 } from '../../resources/images/login-3.svg';
import { ReactComponent as Login4 } from '../../resources/images/login-4.svg';
import { Button, Carousel, Col, Form, Input, Layout, message, Result, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../context/CustomContext';
import authService from '../../services/AuthService';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import { Header } from 'antd/lib/layout/layout';
import { withRouter, WithRouterProps } from '../../hooks/WithRouter';
import { Link, Navigate } from 'react-router-dom';

class LoginPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    logIn = async (values: any): Promise<void> => {
        const { updateAuth } = this.context;
        const { intl } = this.props;

        try {
            this.setState({ loading: true });
            const auth = await authService.logIn(values.username, values.password);
            updateAuth(auth);
        } catch (error) {
            message.error(intl.formatMessage({ id: 'status.loginError' }));
        } finally {
            this.setState({ loading: false });
        }
    };

    fail = () => {
        const { intl } = this.props;
        message.error(intl.formatMessage({ id: 'status.loginError' }));
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <Layout className={styles.layout}>
                <Header>
                    <HeaderComponent />
                </Header>
                <Row className={styles.content} justify="center">
                    <Col span={12} className={styles.info}>
                        {this.renderInfo()}
                    </Col>
                    <Col span={12} className={styles.login}>
                        {this.renderForm()}
                    </Col>
                </Row>
            </Layout>
        );
    };

    renderInfo = (): React.ReactElement | undefined => {
        return (
            <Carousel autoplay speed={4000}>
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.1" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.1.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login1} className={styles.image} />}
                />
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.2" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.2.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login2} className={styles.image} />}
                />
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.3" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.3.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login3} className={styles.image} />}
                />
                <Result
                    title={
                        <h3>
                            <FormattedMessage id="login.info.4" />
                        </h3>
                    }
                    subTitle={
                        <h4>
                            <FormattedMessage id="login.info.4.desc" />
                        </h4>
                    }
                    icon={<Icon component={Login4} className={styles.image} />}
                />
            </Carousel>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <>
                <Row justify="center">
                    <Col span="16" className={styles.title}>
                        <FormattedMessage id="login.login" />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span="16">
                        <Form
                            ref={this.formRef}
                            onFinish={this.logIn}
                            onFinishFailed={this.fail}
                            colon={false}
                            layout="vertical"
                        >
                            <Form.Item
                                label={<FormattedMessage id="login.username" />}
                                name="username"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="login.password" />}
                                name="password"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                            >
                                <Input type="password" maxLength={25} size="large" />
                            </Form.Item>
                            <Form.Item className={styles.buttons} wrapperCol={{ span: 14 }}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                                    <FormattedMessage id="login.logIn" tagName="span" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={16}>
                        <Link to="/reset-password">
                            <Button type="link" className={styles.resetPassword}>
                                <FormattedMessage id="login.resetPassword" />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        const { auth } = this.context;

        if (!auth) {
            return <>{this.renderContent()}</>;
        } else {
            return <Navigate to="/" />;
        }
    }
}
export default injectIntl(withRouter<Props>(LoginPage));

interface Props extends WrappedComponentProps {}

interface State {
    loading?: boolean;
}
