import React from 'react';
import styles from './SubheaderComponent.module.scss';

const SubheaderComponent: React.FC<Props> = (props: Props) => {
    const { title, button } = props;
    return (
        <div className={styles.subheader}>
            <h1>{title}</h1>
            {button}
        </div>
    );
};
export default SubheaderComponent;

interface Props {
    title: React.ReactNode | string;
    button: React.ReactNode;
}
