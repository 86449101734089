import Icon from '@ant-design/icons';
import { Button, Divider, Form, FormInstance, Input, message, Popconfirm, Radio, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import profileApi from '../../../../api/ProfileApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../../../context/CustomContext';
import { withRouter, WithRouterProps } from '../../../../hooks/WithRouter';
import { Profile } from '../../../../model/entities';
import { languageTypes } from '../../../../model/types';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../resources/images/trash-can.svg';
import notificationService from '../../../../services/NotificationService';
import ProfileSkillsList from './ProfileSkillsList/ProfileSkillsList';

class ProfilePage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            if (this.props.params.id === 'new') {
                await this.new();
            } else {
                await this.get(+this.props.params.id!);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { language } = this.context;

        const profile: Profile = {
            passGrade: 50,
            language,
        };

        this.setState({ profile });
        this.formRef.current!.setFieldsValue(profile);
    };

    get = async (id: number) => {
        const profile = await profileApi.get(id);

        this.setState({ profile });
        this.formRef.current!.setFieldsValue(profile);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let profile: Profile = Object.assign({}, this.state.profile, values);
            profile = profile.id ? await profileApi.update(profile) : await profileApi.create(profile);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ profile });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'profile.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    delete = async () => {
        try {
            this.setState({ deleting: true });
            const profile = Object.assign({}, this.state.profile);
            await profileApi.delete(profile);

            this.props.navigate(`/super-admin/profiles`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ deleting: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { profile } = this.state;

        return (
            <>
                {this.renderForm()}
                <Divider />
                {profile && <ProfileSkillsList profile={profile} />}
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { profile, saving, deleting } = this.state;

        const languageOptions = languageTypes.map((l) => (
            <Radio key={l} value={l}>
                <FormattedMessage id={`language.${l}`} />
            </Radio>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="profile.name" />}
                    name="name"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Input
                        maxLength={100}
                        size="large"
                        placeholder={intl.formatMessage({ id: 'profile.name.placeholder' })}
                    />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="profile.description" />} name="description">
                    <TextArea maxLength={500} rows={3} size="large" showCount />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="profile.language" />}
                    name="language"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Radio.Group size="large" disabled={profile && !!profile.id}>
                        {languageOptions}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="profile.passGrade" />}
                    name="passGrade"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    wrapperCol={{ span: 3 }}
                >
                    <Input type="number" min={0} max={100} step={1} size="large" addonAfter="%" />
                </Form.Item>
                <Form.Item className="buttons">
                    <Space>
                        <Button
                            type="primary"
                            ghost={!!profile?.id}
                            htmlType="submit"
                            size="large"
                            icon={<Icon component={SaveSvg} />}
                            loading={saving}
                        >
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>

                        <Popconfirm
                            title={<FormattedMessage id="profile.delete" />}
                            onConfirm={this.delete}
                            okText={<FormattedMessage id="button.yes" />}
                            cancelText={<FormattedMessage id="button.no" />}
                        >
                            <Button
                                type="ghost"
                                size="large"
                                hidden={!profile || !profile.id}
                                icon={<Icon component={TrashCanSvg} />}
                                loading={deleting}
                            >
                                <FormattedMessage id="button.delete" tagName="span" />
                            </Button>
                        </Popconfirm>
                    </Space>
                    <Link to="/super-admin/profiles">
                        <Button type="text" size="large">
                            <FormattedMessage id="button.back" tagName="span" />
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        );
    };

    render() {
        return (
            <LayoutComponent pageId="profiles">
                <WrapperComponent title={<FormattedMessage id="profiles.name" />}>
                    {this.renderContent()}
                </WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<Props>(ProfilePage));

interface Props extends WrappedComponentProps {}

interface State {
    profile?: Profile;
    saving?: boolean;
    deleting?: boolean;
}
