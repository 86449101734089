import Icon from '@ant-design/icons';
import { Button, Checkbox, Form, FormInstance, Input, InputNumber, Modal, Space } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import assessmentAnswerApi from '../../../../../../api/AssessmentAnswerApi';
import PageHeaderComponent from '../../../../../../components/PageHeaderComponent/PageHeaderComponent';
import CustomContext from '../../../../../../context/CustomContext';
import {
    AssessmentAnswer,
    AssessmentAnswerOption,
    AssessmentAnswerOptionAnswer,
} from '../../../../../../model/entities';
import { ReactComponent as ArrowLeftSvg } from '../../../../../../resources/images/arrow--left.svg';
import { ReactComponent as ArrowRightSvg } from '../../../../../../resources/images/arrow--right.svg';
import { ReactComponent as CheckmarkSvg } from '../../../../../../resources/images/checkmark.svg';
import { ReactComponent as WarningSquare } from '../../../../../../resources/images/warning-square.svg';
import notificationService from '../../../../../../services/NotificationService';
import styles from './AssessmentAnswerComponent.module.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

class AssessmentAnswerComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.page !== prevProps.page) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            const { assessmentAnswer } = this.props;
            this.formRef.current!.setFieldsValue(assessmentAnswer);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    save = async (values: any) => {
        let saved: boolean = false;
        try {
            this.setState({ saving: true });
            let assessmentAnswer: AssessmentAnswer = Object.assign({}, this.props.assessmentAnswer, values);
            await assessmentAnswerApi.update(assessmentAnswer);
            saved = true;
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ saving: false });
        }

        return saved;
    };

    changePage = async (page: number) => {
        if (this.formRef.current) {
            const saved = await this.save(this.formRef.current.getFieldsValue());
            saved && this.props.onPageChange(page);
        }
    };

    submit = async () => {
        try {
            if (this.formRef.current) {
                this.setState({ submitting: true });
                const saved = await this.save(this.formRef.current.getFieldsValue());
                saved && (await this.props.onSubmit());
            }
        } finally {
            this.setState({ submitting: false });
        }
    };

    showSubmitModal = () => {
        const { intl } = this.props;

        Modal.confirm({
            title: intl.formatMessage({ id: 'assessment.steps.inProgress.submit.title' }),
            icon: <Icon component={WarningSquare} />,
            content: intl.formatMessage({ id: 'assessment.steps.inProgress.submit.description' }),
            okText: intl.formatMessage({ id: 'button.yes' }),
            cancelText: intl.formatMessage({ id: 'button.no' }),
            okButtonProps: {
                icon: <Icon component={CheckmarkSvg} />,
            },
            onOk: async () => await this.submit(),
        });
    };

    changeAnswerOption = (event: CheckboxChangeEvent, index: number) => {
        console.log(event.target.checked);
        if (event.target.checked) {
            const answers: any[] = this.formRef.current!.getFieldsValue().answers;
            answers.forEach((answer, i) => {
                if (i !== index) {
                    this.formRef.current!.setFieldValue(['answers', i, 'result'], false);
                }
            });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return <>{this.renderQuestion()}</>;
    };

    renderQuestion = (): React.ReactElement | undefined => {
        const { assessmentAnswer } = this.props;

        return (
            <>
                <PageHeaderComponent title={assessmentAnswer.question.question} className={styles.question} />
                <ReactQuill value={assessmentAnswer.question.description} readOnly={true} theme={'bubble'} />
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { assessmentAnswer } = this.props;

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                {this.renderAnswer(assessmentAnswer)}
            </Form>
        );
    };

    renderAnswer = (assessmentAnswer: AssessmentAnswer): React.ReactElement | undefined => {
        if (assessmentAnswer.type === 'TEXT') {
            return this.renderAnswerText();
        } else if (assessmentAnswer.type === 'NUMERIC') {
            return this.renderAnswerNumeric();
        } else if (assessmentAnswer.type === 'OPTION') {
            return this.renderAnswerOptions(assessmentAnswer as AssessmentAnswerOption);
        }
    };

    renderAnswerText = (): React.ReactElement | undefined => {
        return (
            <Form.Item name="answer" wrapperCol={{ span: 12 }}>
                <Input maxLength={250} size="large" />
            </Form.Item>
        );
    };

    renderAnswerNumeric = (): React.ReactElement | undefined => {
        return (
            <Form.Item name="answer" wrapperCol={{ span: 12 }}>
                <InputNumber size="large" />
            </Form.Item>
        );
    };

    renderAnswerOptions = (assessmentAnswer: AssessmentAnswerOption): React.ReactElement | undefined => {
        return (
            <>
                {assessmentAnswer.answers
                    .sort((a, b) => a.uuid!.localeCompare(b.uuid!))
                    .map((answerOption, index) => this.renderAnswerOption(assessmentAnswer, answerOption, index))}
            </>
        );
    };

    renderAnswerOption = (
        assessmentAnswer: AssessmentAnswerOption,
        answerOption: AssessmentAnswerOptionAnswer,
        index: number,
    ): React.ReactElement | undefined => {
        const statement = assessmentAnswer.question.answers.filter((a) => a.uuid === answerOption.uuid)[0].answer;

        return (
            <Form.Item key={index}>
                <Form.Item name={['answers', index, 'result']} valuePropName="checked" noStyle>
                    <Checkbox onChange={(e) => this.changeAnswerOption(e, index)}>{statement}</Checkbox>
                </Form.Item>
                <Form.Item name={['answers', index, 'uuid']} valuePropName="checked" noStyle hidden={true}>
                    <Input />
                </Form.Item>
            </Form.Item>
        );
    };

    renderNavigation = (): React.ReactElement | undefined => {
        const { page, count, includeFiles } = this.props;
        const first = page === 0 && !includeFiles;
        const last = page === count - 1;

        return (
            <>
                <Space className={styles.buttons}>
                    <span className={styles.page}>
                        {page + 1} / {count}
                    </span>
                    <Button
                        key="previous"
                        size="large"
                        type="ghost"
                        disabled={first}
                        onClick={() => this.changePage(page - 1)}
                    >
                        <Icon component={ArrowLeftSvg} /> <FormattedMessage id="button.previous" tagName="span" />
                    </Button>
                    <Button
                        key="next"
                        type="primary"
                        ghost
                        size="large"
                        hidden={last}
                        onClick={() => this.changePage(page + 1)}
                    >
                        <FormattedMessage id="button.next" tagName="span" /> <Icon component={ArrowRightSvg} />
                    </Button>
                    <Button
                        key="finish"
                        type="primary"
                        size="large"
                        hidden={!last}
                        onClick={this.showSubmitModal}
                        icon={<Icon component={CheckmarkSvg} />}
                    >
                        <FormattedMessage id="assessment.button.finish" tagName="span" />
                    </Button>
                </Space>
            </>
        );
    };

    render() {
        return (
            <>
                {this.renderQuestion()}
                {this.renderForm()}
                {this.renderNavigation()}
            </>
        );
    }
}
export default injectIntl(AssessmentAnswerComponent);

interface Props extends WrappedComponentProps {
    assessmentAnswer: AssessmentAnswer;
    page: number;
    count: number;
    includeFiles: boolean;
    onPageChange: (page: number) => Promise<void>;
    onSubmit: () => Promise<void>;
}

interface State {
    saving?: boolean;
    submitting?: boolean;
}
