import React, { Component } from 'react';
import { Form, FormInstance, InputNumber, message, Modal, Select, Slider } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as SaveSvg } from '../../../../../../resources/images/save.svg';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import profileSkillApi from '../../../../../../api/ProfileSkillApi';
import CustomContext from '../../../../../../context/CustomContext';
import { Course, Profile, ProfileSkill, Skill } from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import courseApi from '../../../../../../api/CourseApi';
import skillApi from '../../../../../../api/SkillApi';
import { LanguageType } from '../../../../../../model/types';
import stringService from '../../../../../../services/StringService';

class ProfileSkillModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { skills: [], courses: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { profileSkillId } = this.props;

        try {
            if (profileSkillId) {
                await this.get(profileSkillId);
            } else {
                await this.new();
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { profile } = this.props;

        const profileSkill: ProfileSkill = {
            profileId: profile.id,
            skill: { branches: [] },
            courses: [],
            passGrade: 0,
            distinctionGrade: 70,
        };
        const responses = await Promise.all([
            skillApi.list(0, 500, 'name', true),
            courseApi.list(0, 500, 'name', true),
        ]);
        const skills = responses[0].content.filter((s) => s.language === profile.language);
        const courses = responses[1].content.filter((c) => this.isCourseAllowed(c, profile.language));
        let values = Object.assign({}, profileSkill, {
            grades: [profileSkill.passGrade, profileSkill.distinctionGrade],
        });

        this.setState({ profileSkill, skills, courses });
        this.formRef.current!.setFieldsValue(values);
    };

    get = async (id: number) => {
        const { profile } = this.props;

        const responses = await Promise.all([
            profileSkillApi.get(id),
            skillApi.list(0, 500, 'name', true),
            courseApi.list(0, 500, 'name', true),
        ]);
        const profileSkill = responses[0];
        const skills = responses[1].content.filter((s) => s.language === profile.language || s.id === id);
        const courses = responses[2].content.filter(
            (c) => this.isCourseAllowed(c, profile.language) || profileSkill.courses.some((psc) => psc.id === c.id),
        );
        let values = Object.assign({}, profileSkill, {
            courses: profileSkill.courses.map((course) => course.id!),
            grades: [profileSkill.passGrade, profileSkill.distinctionGrade],
        });

        this.setState({ profileSkill, skills, courses });
        this.formRef.current!.setFieldsValue(values);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let profileSkill: ProfileSkill = Object.assign({}, this.state.profileSkill, values, {
                courses: values.courses.map((id: number) => ({ id })),
                passGrade: Math.min(...values.grades),
                distinctionGrade: Math.max(...values.grades),
            });

            profileSkill = profileSkill.id
                ? await profileSkillApi.update(profileSkill)
                : await profileSkillApi.create(profileSkill);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ profileSkill });
            this.props.onSave();
            this.props.onHide();
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'profileSkill.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    gradesFormatter = (value?: number): string => {
        return `${value}%`;
    };

    isCourseAllowed = (course: Course, language: LanguageType | undefined): boolean => {
        let isCourseAllowed: boolean = false;
        if (language === 'en' && course.code?.startsWith('ENG')) {
            isCourseAllowed = true;
        } else if (language === 'es' && course.code?.startsWith('ESP')) {
            isCourseAllowed = true;
        }

        return isCourseAllowed;
    };

    /*** COMPONENTS ***/

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { skills, courses } = this.state;
        const skillOptions = skills.map((skill) => (
            <Select.Option key={skill.id!} value={skill.id!}>
                {skill.name}
            </Select.Option>
        ));
        const courseOptions = courses.map((course) => (
            <Select.Option key={course.id!} value={course.id!}>
                {course.name}
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="profileSkill.skill" />}
                    name={['skill', 'id']}
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Select
                        size="large"
                        placeholder={intl.formatMessage({ id: 'profileSkill.skill.placeholder' })}
                        showSearch
                        filterOption={stringService.filterOptions}
                    >
                        {skillOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="profileSkill.questions" />}
                    name="questions"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <InputNumber
                        size="large"
                        min={0}
                        step={1}
                        placeholder={intl.formatMessage({ id: 'profileSkill.questions.placeholder' })}
                    />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="profileSkill.grades" />}
                    name="grades"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    tooltip={{ title: <FormattedMessage id="profileSKill.grades.tooltip" />, placement: 'topLeft' }}
                >
                    <Slider
                        range={{ draggableTrack: true }}
                        tooltipVisible={true}
                        tipFormatter={this.gradesFormatter}
                        marks={{
                            0: <FormattedMessage id="profileSkill.grades.0" />,
                            100: <FormattedMessage id="profileSkill.grades.100" />,
                        }}
                        step={5}
                    />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="profileSkill.courses" />} name="courses">
                    <Select
                        size="large"
                        mode="multiple"
                        placeholder={intl.formatMessage({ id: 'profileSkill.courses.placeholder' })}
                        showSearch
                        filterOption={stringService.filterOptions}
                    >
                        {courseOptions}
                    </Select>
                </Form.Item>
            </Form>

            // passGrade?: number;
            // distinctionGrade?: number;
        );
    };

    render() {
        const { saving } = this.state;
        const submit = this.formRef.current ? this.formRef.current.submit : undefined;

        return (
            <Modal
                title={<FormattedMessage id="profileSkill.title" />}
                okText={<FormattedMessage id="button.save" tagName="span" />}
                cancelText={<FormattedMessage id="button.cancel" />}
                onOk={submit}
                onCancel={this.props.onHide}
                open={true}
                maskClosable={false}
                width="760px"
                className="modal"
                okButtonProps={{
                    loading: saving,
                    icon: <Icon component={SaveSvg} />,
                    size: 'large',
                }}
                cancelButtonProps={{
                    type: 'text',
                    size: 'large',
                }}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}
export default injectIntl(ProfileSkillModal);

interface Props extends WrappedComponentProps {
    profileSkillId?: number;
    profile: Profile;
    onHide: () => void;
    onSave: () => void;
}

interface State {
    profileSkill?: ProfileSkill;
    skills: Skill[];
    courses: Course[];
    saving?: boolean;
}
