import { Button, Result } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as ArrowLeftSvg } from '../../resources/images/arrow--left.svg';
import { ReactComponent as Error404Svg } from '../../resources/images/404.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './ErrorPage.module.scss';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';

const ErrorPage: React.FC = () => {
    return (
        <LayoutComponent>
            <Result
                icon={<Icon component={Error404Svg} className={styles.image} />}
                title={<FormattedMessage id="errors.404.title" tagName="h3" />}
                subTitle={<FormattedMessage id="errors.404.description" tagName="h4" />}
                extra={
                    <Link to="/assessments">
                        <Button type="primary" size="large" icon={<Icon component={ArrowLeftSvg} />}>
                            <FormattedMessage id="errors.404.back" tagName="span" />
                        </Button>
                    </Link>
                }
                className={styles.result}
            />
        </LayoutComponent>
    );
};
export default ErrorPage;
