import { AxiosRequestConfig } from 'axios';
import { Auth } from '../model/entities';
import apiAxios from './CustomAxios';

class AuthApi {
    get = async (): Promise<Auth> => {
        const response = await apiAxios.get<Auth>('/auth/current');
        return response.data;
    };

    logIn = async (username: string, password: string) => {
        const body = new URLSearchParams();
        body.set('username', username);
        body.set('password', password);
        const config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        };

        await apiAxios.post('/login', body, config);
    };

    logOut = async () => {
        await apiAxios.get('/logout');
    };
}

const authApi: AuthApi = new AuthApi();
export default authApi;
