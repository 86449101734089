import { AssessmentAnswer } from '../model/entities';
import apiAxios from './CustomAxios';

class AssessmentAnswerApi {
    list = async (assessmentId: number): Promise<AssessmentAnswer[]> => {
        const response = await apiAxios.get<AssessmentAnswer[]>('/assessment-answers', {
            params: { assessmentId },
        });

        return response.data;
    };

    update = async (assessmentAnswer: AssessmentAnswer): Promise<AssessmentAnswer> => {
        const assessmentAnswerForUpdate: AssessmentAnswer = Object.assign({}, assessmentAnswer, {
            question: undefined,
        });
        const response = await apiAxios.put<AssessmentAnswer>(
            `/assessment-answers/${assessmentAnswerForUpdate.id}`,
            assessmentAnswerForUpdate,
        );

        return response.data;
    };
}

const assessmentAnswerApi: AssessmentAnswerApi = new AssessmentAnswerApi();
export default assessmentAnswerApi;
