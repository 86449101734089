import { Auth } from '../model/entities';
import stringService from './StringService';

class BranchService {
    readonly key = 'branchId';

    get = (auth: Auth): number | undefined => {
        var params = new URLSearchParams(window.location.search);
        var paramValue = params.get(this.key);
        const storedBranchId = localStorage.getItem(this.key);

        let branchId: number | undefined;
        if (paramValue && auth.branches.map((b) => b.id).includes(+paramValue)) {
            branchId = +paramValue;
            localStorage.setItem(this.key, branchId.toString());
        } else if (storedBranchId && auth.branches.map((b) => b.id).includes(+storedBranchId)) {
            branchId = +storedBranchId;
        } else if (auth.branches.length > 0) {
            branchId = auth.branches.sort((a, b) => stringService.sort(a.name, b.name))[0].id;
        }

        return branchId;
    };
}

const branchService: BranchService = new BranchService();
export default branchService;
