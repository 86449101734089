import React, { Component } from 'react';
import styles from './AssessmentPage.module.scss';
import { Button, Divider, Result, Space, Spin, Steps, Tag } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as PowerSvg } from '../../../../resources/images/power.svg';
import { ReactComponent as ArrowLeftSvg } from '../../../../resources/images/arrow--left.svg';
import { ReactComponent as OfficeStartSvg } from '../../../../resources/images/office-start.svg';
import { ReactComponent as OfficeFinishSvg } from '../../../../resources/images/office-finish.svg';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import assessmentApi from '../../../../api/AssessmentApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Assessment, AssessmentAnswer } from '../../../../model/entities';
import notificationService from '../../../../services/NotificationService';
import dayjs from 'dayjs';
import assessmentAnswerApi from '../../../../api/AssessmentAnswerApi';
import AssessmentAnswersComponent from './AssessmentAnswersComponent/AssessmentAnswersComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import { WithRouterProps, withRouter } from '../../../../hooks/WithRouter';

class AssessmentPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = { assessmentAnswers: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            await this.get(+this.props.params.id!);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    get = async (id: number) => {
        const assessment = await assessmentApi.get(id);
        let assessmentAnswers: AssessmentAnswer[] = [];
        if (assessment.started) {
            assessmentAnswers = await assessmentAnswerApi.list(assessment.id!);
        }

        this.setState({ assessment, assessmentAnswers });
    };

    start = async () => {
        try {
            this.setState({ saving: true });
            let assessment: Assessment = Object.assign({}, this.state.assessment, {
                started: dayjs(),
            });
            assessment = await assessmentApi.update(assessment);
            const assessmentAnswers = await assessmentAnswerApi.list(assessment.id!);

            this.setState({ assessment, assessmentAnswers });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ saving: false });
        }
    };

    submit = async () => {
        try {
            this.setState({ saving: true });
            let assessment: Assessment = Object.assign({}, this.state.assessment, {
                finished: dayjs(),
            });
            assessment = await assessmentApi.update(assessment);

            this.setState({ assessment });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ saving: false });
        }
    };

    refreshAssessmentAnswers = async () => {
        const { assessment } = this.state;
        if (assessment) {
            const assessmentAnswers = await assessmentAnswerApi.list(assessment.id!);
            this.setState({ assessmentAnswers });
        }
    };

    getStep = (assessment: Assessment): number => {
        let step: number = 0;
        if (assessment.started && !assessment.finished) {
            step = 1;
        } else if (assessment.started && assessment.finished) {
            step = 2;
        }
        return step;
    };

    getPercent = (assessment: Assessment): number | undefined => {
        let percent: number | undefined;
        if (assessment.started && !assessment.finished) {
            percent = 50;
        }
        return percent;
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { assessment, assessmentAnswers } = this.state;

        if (assessment) {
            return (
                <>
                    <div className={styles.steps}>{this.renderToolbar(assessment, assessmentAnswers)}</div>
                    {this.renderStep(assessment, assessmentAnswers)}
                </>
            );
        } else {
            return <Spin size="large" />;
        }
    };

    renderToolbar = (assessment: Assessment, assessmentAnswers: AssessmentAnswer[]): React.ReactElement | undefined => {
        const { intl } = this.props;
        const step = this.getStep(assessment);
        const percent = this.getPercent(assessment);

        return (
            <Steps current={step} percent={percent} type="navigation">
                <Steps.Step
                    icon={<></>}
                    title={<FormattedMessage id="assessment.steps.start" />}
                    description={
                        assessment.started ? (
                            <FormattedDate
                                value={assessment.started.toISOString()}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        ) : (
                            <span className={styles.empty}>...</span>
                        )
                    }
                />
                <Steps.Step
                    icon={<></>}
                    title={<FormattedMessage id="assessment.steps.inProgress" />}
                    description={
                        assessmentAnswers.length > 0 &&
                        `${assessmentAnswers.length} ${intl.formatMessage({
                            id: 'assessment.steps.inProgress.questions',
                        })}`
                    }
                />
                <Steps.Step
                    icon={<></>}
                    title={<FormattedMessage id="assessment.steps.finished" />}
                    description={
                        assessment.finished && (
                            <FormattedDate
                                value={assessment.finished.toISOString()}
                                day="2-digit"
                                month="2-digit"
                                year="numeric"
                                hour="2-digit"
                                minute="2-digit"
                                hour12={false}
                            />
                        )
                    }
                />
            </Steps>
        );
    };

    renderStep = (assessment: Assessment, assessmentAnswers: AssessmentAnswer[]): React.ReactElement | undefined => {
        if (!assessment.started) {
            return this.renderStart(assessment);
        } else if (!assessment.finished) {
            return this.renderAnswers(assessment, assessmentAnswers);
        } else {
            return this.renderFinished(assessment);
        }
    };

    renderStart = (assessment: Assessment): React.ReactElement | undefined => {
        const { saving } = this.state;

        return (
            <Result
                title={<h3>{assessment.assignment.name}</h3>}
                subTitle={<h4>{assessment.assignment.description}</h4>}
                icon={<Icon component={OfficeStartSvg} className={styles.image} />}
                extra={[
                    <Button
                        type="primary"
                        size="large"
                        icon={<Icon component={PowerSvg} />}
                        loading={saving}
                        onClick={this.start}
                        key="start"
                    >
                        <FormattedMessage id="assessment.button.start" tagName="span" />
                    </Button>,
                    <Link to="/assessments" key="back">
                        <Button type="text" size="large" disabled={saving}>
                            <FormattedMessage id="button.back" />
                        </Button>
                    </Link>,
                ]}
                className={styles.result}
            />
        );
    };

    renderAnswers = (assessment: Assessment, assessmentAnswers: AssessmentAnswer[]): React.ReactElement | undefined => {
        return (
            <AssessmentAnswersComponent
                assessment={assessment}
                assessmentAnswers={assessmentAnswers}
                onAssessmentAnswerSave={this.refreshAssessmentAnswers}
                onSubmit={this.submit}
            />
        );
    };

    renderFinished = (assessment: Assessment): React.ReactElement | undefined => {
        if (assessment.assignment.publicResult) {
            return this.renderFinishedPublicResult(assessment);
        } else {
            return this.renderFinishedPrivateResult();
        }
    };

    renderFinishedPrivateResult = (): React.ReactElement | undefined => {
        return (
            <Result
                icon={<Icon component={OfficeFinishSvg} className={styles.image} />}
                title={<FormattedMessage id="assessment.steps.finished.title" tagName="h3" />}
                subTitle={<FormattedMessage id="assessment.steps.finished.description" tagName="h4" />}
                extra={
                    <Link to="/assessments">
                        <Button type="primary" size="large" icon={<Icon component={ArrowLeftSvg} />}>
                            <FormattedMessage id="assessment.steps.finished.back" tagName="span" />
                        </Button>
                    </Link>
                }
                className={styles.result}
            />
        );
    };

    renderFinishedPublicResult = (assessment: Assessment): React.ReactElement | undefined => {
        return (
            <Result
                icon={<Icon component={OfficeFinishSvg} className={styles.image} />}
                title={<FormattedMessage id="assessment.steps.finished.title" tagName="h3" />}
                subTitle={
                    <h4>
                        <Space split={<Divider type="vertical" />}>
                            <span>
                                <FormattedMessage id="assessment.result.passed" />:{' '}
                                {assessment.result!.passed ? (
                                    <Tag color="green">
                                        <FormattedMessage id="assessment.result.passed.true" />
                                    </Tag>
                                ) : (
                                    <Tag color="red">
                                        <FormattedMessage id="assessment.result.passed.false" />
                                    </Tag>
                                )}
                            </span>
                            <span>
                                <FormattedMessage id="assessment.result.mark" />:{' '}
                                <FormattedNumber value={assessment.result!.mark!} maximumFractionDigits={1} />%
                            </span>
                            {assessment.result!.courses.length > 0 && (
                                <span>
                                    <FormattedMessage id="assessment.result.courses" />:{' '}
                                    {assessment.result?.courses.map((c) => c.name).join(', ')}
                                </span>
                            )}
                        </Space>
                    </h4>
                }
                extra={
                    <Link to="/assessments">
                        <Button type="primary" size="large" icon={<Icon component={ArrowLeftSvg} />}>
                            <FormattedMessage id="assessment.steps.finished.back" tagName="span" />
                        </Button>
                    </Link>
                }
                className={styles.result}
            />
        );
    };

    render() {
        return (
            <LayoutComponent pageId="assessments">
                <WrapperComponent>{this.renderContent()}</WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<WithRouterProps<Props>>(AssessmentPage));

interface Props extends WrappedComponentProps {}

interface State {
    assessment?: Assessment;
    assessmentAnswers: AssessmentAnswer[];
    saving?: boolean;
}
