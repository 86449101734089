import { Course } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';

class CourseApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<Page<Course>> => {
        const response = await apiAxios.get<Page<Course>>('/courses', {
            params: { page, size, sortField, sortOrder, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    listByBranch = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        branchId: number,
        searchText?: string,
    ): Promise<Page<Course>> => {
        const response = await apiAxios.get<Page<Course>>('/courses', {
            params: { page, size, sortField, sortOrder, branchId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<Course> => {
        const response = await apiAxios.get<Course>(`/courses/${id}`);
        return response.data;
    };
}

const courseApi: CourseApi = new CourseApi();
export default courseApi;
