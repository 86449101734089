import { Page } from '../model/elements';
import { Branch } from '../model/entities';
import apiAxios from './CustomAxios';

class BranchApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<Page<Branch>> => {
        const response = await apiAxios.get<Page<Branch>>('/branches', {
            params: { page, size, sortField, sortOrder, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };
}

const branchApi: BranchApi = new BranchApi();
export default branchApi;
