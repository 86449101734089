import Icon from '@ant-design/icons';
import { Button, Divider, Form, FormInstance, Input, message, Popconfirm, Radio, Select, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import branchApi from '../../../../api/BranchApi';
import skillApi from '../../../../api/SkillApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../../../context/CustomContext';
import { withRouter, WithRouterProps } from '../../../../hooks/WithRouter';
import { Branch, Skill } from '../../../../model/entities';
import { languageTypes } from '../../../../model/types';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../resources/images/trash-can.svg';
import notificationService from '../../../../services/NotificationService';
import QuestionsList from './QuestionsList/QuestionsList';
import SkillFilesList from './SkillFilesList/SkillFilesList';
import stringService from '../../../../services/StringService';

class SkillPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = { branches: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            if (this.props.params.id === 'new') {
                await this.new();
            } else {
                await this.get(+this.props.params.id!);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { language } = this.context;

        const skill: Skill = { language, branches: [] };
        const branchesPage = await branchApi.list(0, 500, 'name', true);
        const branches = branchesPage.content;

        this.setState({ skill, branches });
        this.formRef.current!.setFieldsValue(skill);
    };

    get = async (id: number) => {
        const responses = await Promise.all([await skillApi.get(id), branchApi.list(0, 500, 'name', true)]);
        const skill = responses[0];
        const branches = responses[1].content;

        let values = Object.assign({}, skill, {
            branches: skill.branches.map((branch) => branch.id!),
        });

        this.setState({ skill, branches });
        this.formRef.current!.setFieldsValue(values);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let skill: Skill = Object.assign({}, this.state.skill, values, {
                branches: values.branches.map((id: number) => ({ id })),
            });
            skill = skill.id ? await skillApi.update(skill) : await skillApi.create(skill);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ skill });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'skill.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    delete = async () => {
        try {
            this.setState({ deleting: true });
            const skill = Object.assign({}, this.state.skill);
            await skillApi.delete(skill);

            this.props.navigate(`/super-admin/skills`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ deleting: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { skill } = this.state;

        return (
            <>
                {this.renderForm()}
                <Divider />
                <SkillFilesList skillId={skill?.id} />
                <Divider />
                <QuestionsList skillId={skill?.id} />
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { skill, branches, saving, deleting } = this.state;

        const languageOptions = languageTypes.map((l) => (
            <Radio key={l} value={l}>
                <FormattedMessage id={`language.${l}`} />
            </Radio>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="skill.name" />}
                    name="name"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Input
                        maxLength={100}
                        size="large"
                        placeholder={intl.formatMessage({ id: 'skill.name.placeholder' })}
                    />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="skill.description" />} name="description">
                    <TextArea maxLength={500} rows={3} size="large" showCount />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="skill.language" />}
                    name="language"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Radio.Group
                        size="large"
                        disabled={skill && !!skill.id}
                        onChange={() => this.formRef.current?.resetFields(['branches'])}
                    >
                        {languageOptions}
                    </Radio.Group>
                </Form.Item>
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.language !== curValues.language}>
                    {() => {
                        const language = this.formRef.current?.getFieldValue('language');
                        const branchOptions = branches
                            .filter((b) => b.language === language || skill?.branches.some((sb) => sb.id === b.id))
                            .map((branch) => (
                                <Select.Option key={branch.id!} value={branch.id!}>
                                    {branch.name}
                                </Select.Option>
                            ));

                        return (
                            <Form.Item label={<FormattedMessage id="skill.branches" />} name="branches">
                                <Select
                                    size="large"
                                    mode="multiple"
                                    placeholder={intl.formatMessage({ id: 'skill.branches.placeholder' })}
                                    showSearch
                                    filterOption={stringService.filterOptions}
                                >
                                    {branchOptions}
                                </Select>
                            </Form.Item>
                        );
                    }}
                </Form.Item>
                <Form.Item className="buttons">
                    <Space>
                        <Button
                            type="primary"
                            ghost={!!skill?.id}
                            htmlType="submit"
                            size="large"
                            icon={<Icon component={SaveSvg} />}
                            loading={saving}
                        >
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>

                        <Popconfirm
                            title={<FormattedMessage id="skill.delete" />}
                            onConfirm={this.delete}
                            okText={<FormattedMessage id="button.yes" />}
                            cancelText={<FormattedMessage id="button.no" />}
                        >
                            <Button
                                size="large"
                                hidden={!skill || !skill.id}
                                icon={<Icon component={TrashCanSvg} />}
                                loading={deleting}
                                type="ghost"
                            >
                                <FormattedMessage id="button.delete" tagName="span" />
                            </Button>
                        </Popconfirm>
                        <Link to="/super-admin/skills">
                            <Button type="text" size="large">
                                <FormattedMessage id="button.back" tagName="span" />
                            </Button>
                        </Link>
                    </Space>
                </Form.Item>
            </Form>
        );
    };

    render() {
        return (
            <LayoutComponent pageId="skills">
                <WrapperComponent title={<FormattedMessage id="skills.name" />}>
                    {this.renderContent()}
                </WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<Props>(SkillPage));

interface Props extends WrappedComponentProps {}

interface State {
    skill?: Skill;
    branches: Branch[];
    saving?: boolean;
    deleting?: boolean;
}
