import { User } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';

class UserApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        branchId: number,
        groupId?: number,
        searchText?: string,
    ): Promise<Page<User>> => {
        const response = await apiAxios.get<Page<User>>('/users', {
            params: { page, size, sortField, sortOrder, branchId, groupId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: string): Promise<User> => {
        const response = await apiAxios.get<User>(`/users/${id}`);
        return response.data;
    };
}

const userApi: UserApi = new UserApi();
export default userApi;
