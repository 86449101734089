import React, { Component } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../../../context/CustomContext';
import { Page } from '../../../../../model/elements';
import { Course, User } from '../../../../../model/entities';
import tableService from '../../../../../services/TableService';
import notificationService from '../../../../../services/NotificationService';
import courseUserApi from '../../../../../api/CourseUserApi';

class UserCoursesList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.user.id && this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.user.id !== prevProps.user.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        const { user } = this.props;
        try {
            this.setState({ loading: true });
            const courseUsersPage = await courseUserApi.list(0, tableService.pageSize, 'name', true, user.id!);
            this.setState({ courseUsersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { user } = this.props;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const courseUsersPage = await courseUserApi.list(page - 1, pageSize, sortField, sortOrder, user.id!);
            this.setState({ courseUsersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { courseUsersPage, loading } = this.state;
        const items = courseUsersPage ? courseUsersPage.content : [];
        const columns: ColumnsType<Course> = [
            {
                title: <FormattedMessage id="course.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                defaultSortOrder: 'ascend',
            },
            {
                title: <FormattedMessage id="course.enrolledOn" />,
                dataIndex: 'enrolledOn',
                key: 'enrolledOn',
                width: '200px',
                align: 'center',
                sorter: true,
                render: (value: string) =>
                    value && (
                        <FormattedDate
                            value={value}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                            hour12={false}
                        />
                    ),
            },
            {
                title: <FormattedMessage id="course.completedOn" />,
                dataIndex: 'completedOn',
                key: 'completedOn',
                width: '200px',
                align: 'center',
                sorter: true,
                render: (value: string) =>
                    value && (
                        <FormattedDate
                            value={value}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                            hour12={false}
                        />
                    ),
            },
            {
                title: <FormattedMessage id="course.completionPercentage" />,
                dataIndex: 'completionPercentage',
                key: 'completionPercentage',
                width: '100px',
                align: 'right',
                sorter: true,
                render: (value: number) => (
                    <>
                        <FormattedNumber value={value || 0} maximumFractionDigits={1} />%
                    </>
                ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(courseUsersPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return this.renderTable();
    }
}
export default injectIntl(UserCoursesList);

interface Props extends WrappedComponentProps {
    user: User;
}

interface State {
    courseUsersPage?: Page<Course>;
    loading?: boolean;
}
