import React, { Component } from 'react';
import styles from './QuestionsList.module.scss';
import Icon from '@ant-design/icons';
import { ReactComponent as AddSvg } from '../../../../../resources/images/add.svg';
import { ReactComponent as SearchSvg } from '../../../../../resources/images/search.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import { Avatar, Button, Divider, Empty, List, Popconfirm, Space, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import QuestionModal from './QuestionModal/QuestionModal';
import CustomContext from '../../../../../context/CustomContext';
import notificationService from '../../../../../services/NotificationService';
import questionApi from '../../../../../api/QuestionApi';
import { Page } from '../../../../../model/elements';
import { Question, QuestionNumeric, QuestionOption, QuestionText } from '../../../../../model/entities';
import Search from 'antd/lib/input/Search';
import SubheaderComponent from '../../../../../components/SubheaderComponent/SubheaderComponent';

class QuestionsList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.skillId !== prevProps.skillId) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        await this.list();
    };

    list = async () => {
        const { skillId } = this.props;
        const { searchText } = this.state;

        try {
            if (skillId) {
                this.setState({ loading: true });
                const questionsPage = await questionApi.list(0, 500, 'question', true, skillId, searchText);
                this.setState({ questionsPage, loading: false });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    search = async (searchText: string) => {
        const { skillId } = this.props;

        try {
            if (skillId) {
                this.setState({ loading: true });
                const questionsPageNew = await questionApi.list(0, 500, 'question', true, skillId, searchText);
                this.setState({ questionsPage: questionsPageNew, searchText, loading: false });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    toggleSearch = () => {
        const { searchVisible } = this.state;
        this.setState({ searchVisible: !searchVisible });
    };

    showModal = (question?: Question) => {
        const modalVisible = true;
        this.setState({ modalVisible, question });
    };

    hideModal = async () => {
        const modalVisible = false;
        const question = undefined;
        this.setState({ modalVisible, question });
    };

    delete = async (question: Question) => {
        try {
            this.setState({ deleting: question.id });
            await questionApi.delete(question);
            this.list();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ deleting: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderList()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl, skillId } = this.props;
        const { searchVisible } = this.state;
        const placeholder = intl.formatMessage({ id: 'questions.search' });

        return (
            <SubheaderComponent
                title={
                    <Space size="large">
                        <FormattedMessage id="questions.title" />
                        {!searchVisible && (
                            <Button
                                icon={<Icon component={SearchSvg} />}
                                type="text"
                                onClick={this.toggleSearch}
                                disabled={!skillId}
                            />
                        )}
                        {searchVisible && (
                            <Search
                                onSearch={this.search}
                                placeholder={placeholder}
                                className={`search ${styles.search}`}
                                bordered={false}
                                addonBefore={<Icon component={SearchSvg} />}
                            />
                        )}
                    </Space>
                }
                button={
                    <Tooltip title={<FormattedMessage id="questions.new" />}>
                        <Button
                            type="primary"
                            ghost={!skillId}
                            icon={<Icon component={AddSvg} />}
                            disabled={!skillId}
                            onClick={() => this.showModal()}
                        />
                    </Tooltip>
                }
            />
        );
    };

    renderList = (): React.ReactElement | undefined => {
        const { questionsPage, loading, deleting } = this.state;
        const questions = questionsPage ? questionsPage.content : [];

        return (
            <List
                className={styles.files}
                itemLayout="horizontal"
                dataSource={questions}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<FormattedMessage id="questions.empty" />}
                        />
                    ),
                }}
                loading={loading}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Popconfirm
                                title={<FormattedMessage id="questions.delete" />}
                                onConfirm={() => this.delete(item)}
                                okText={<FormattedMessage id="button.yes" />}
                                cancelText={<FormattedMessage id="button.no" />}
                                placement="left"
                            >
                                <Button
                                    type="ghost"
                                    icon={<Icon component={TrashCanSvg} />}
                                    loading={deleting === item.id}
                                />
                            </Popconfirm>,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar shape="square" size="large">
                                    {item.type?.charAt(0)}
                                </Avatar>
                            }
                            title={
                                <span className="link" onClick={() => this.showModal(item)}>
                                    {item.question}
                                </span>
                            }
                            description={
                                <span className="link" onClick={() => this.showModal(item)}>
                                    {this.renderAnswer(item)}
                                </span>
                            }
                        />
                    </List.Item>
                )}
            />
        );
    };

    renderAnswer = (question: Question): React.ReactElement | undefined => {
        if (question.type === 'TEXT') {
            const questionText = question as QuestionText;
            return (
                <>
                    <FormattedMessage id="question.text.answer" />: {questionText.answer}
                </>
            );
        } else if (question.type === 'NUMERIC') {
            const questionNumeric = question as QuestionNumeric;
            return (
                <>
                    <FormattedMessage id="question.numeric.answer" />: {questionNumeric.answer}
                </>
            );
        } else if (question.type === 'OPTION') {
            const questionOption = question as QuestionOption;
            return (
                <>
                    <FormattedMessage id="question.option.answer" />:{' '}
                    <Space split={<Divider type="vertical" />}>
                        {questionOption.answers
                            .filter((a) => a.result)
                            .map((a) => a.answer)
                            .join(', ')}
                    </Space>
                </>
            );
        }
    };

    renderModal = (): React.ReactElement | undefined => {
        const { skillId } = this.props;
        const { question, modalVisible } = this.state;

        if (modalVisible && skillId) {
            return (
                <QuestionModal
                    skillId={skillId}
                    questionId={question && question.id}
                    onHide={this.hideModal}
                    onSave={this.list}
                />
            );
        }
    };

    render() {
        return (
            <>
                {this.renderContent()}
                {this.renderModal()}
            </>
        );
    }
}
export default injectIntl(QuestionsList);

interface Props extends WrappedComponentProps {
    skillId?: number;
}

interface State {
    questionsPage?: Page<Question>;
    loading?: boolean;
    deleting?: number;
    searchVisible?: boolean;
    searchText?: string;
    question?: Question;
    modalVisible?: boolean;
}
