import { ExternalUser, ResetPassword, ResetPasswordEmail, UpdatePassword } from '../model/entities';
import apiAxios from './CustomAxios';

class ExternalUserApi {
    getByIdentifier = async (identifier: string): Promise<ExternalUser> => {
        const response = await apiAxios.get<ExternalUser>(`/external-users/${identifier}`);
        return response.data;
    };

    create = async (externalUser: ExternalUser): Promise<ExternalUser> => {
        const response = await apiAxios.post<ExternalUser>('/external-users', externalUser);
        return response.data;
    };

    update = async (externalUser: ExternalUser): Promise<ExternalUser> => {
        const response = await apiAxios.put<ExternalUser>(`/external-users/${externalUser.id}`, externalUser);
        return response.data;
    };

    delete = async (externalUser: ExternalUser): Promise<void> => {
        await apiAxios.delete(`/external-users/${externalUser.id}`);
    };

    updatePassword = async (updatePassword: UpdatePassword): Promise<void> => {
        await apiAxios.post<void>('/external-users/passwords', updatePassword);
    };

    sendResetPasswordEmail = async (resetPasswordEmail: ResetPasswordEmail): Promise<void> => {
        await apiAxios.post<void>('/external-users/reset-password-emails', resetPasswordEmail);
    };

    resetPassword = async (resetPassword: ResetPassword): Promise<void> => {
        await apiAxios.post<void>('/external-users/reset-passwords', resetPassword);
    };
}

const externalUserApi: ExternalUserApi = new ExternalUserApi();
export default externalUserApi;
