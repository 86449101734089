import React, { Component } from 'react';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';

class ReportsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    /*** COMPONENTS ***/

    render() {
        return <LayoutComponent pageId="reports"></LayoutComponent>;
    }
}
export default ReportsPage;

interface Props {}

interface State {}
