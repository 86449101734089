import authApi from '../api/AuthApi';
import { Auth } from '../model/entities';
import { ApplicationType } from '../model/types';
import branchService from './BranchService';

class AuthService {
    auth: Auth | undefined;

    get = async (): Promise<Auth | undefined> => {
        try {
            this.auth = await authApi.get();
            this.auth.branchId = branchService.get(this.auth);
        } catch {
            this.auth = undefined;
        }

        return this.auth;
    };

    logIn = async (username: string, password: string): Promise<Auth | undefined> => {
        await authApi.logIn(username, password);
        return await this.get();
    };

    logOut = async () => {
        await authApi.logOut();
        this.auth = undefined;
    };

    getCurrentApplication = (): ApplicationType => {
        const { pathname } = window.location;
        if (pathname.startsWith('/super-admin')) {
            return 'SUPER_ADMIN';
        } else if (pathname.startsWith('/admin')) {
            return 'ADMIN';
        } else {
            return 'PORTAL';
        }
    };

    getHomePath = (): string => {
        if (this.getCurrentApplication() === 'SUPER_ADMIN') {
            return '/super-admin';
        } else if (this.getCurrentApplication() === 'ADMIN') {
            return '/admin';
        } else {
            return '/';
        }
    };
}

const authService: AuthService = new AuthService();
export default authService;
