import { AssignmentUser, AssignmentUserWithAssessment } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';
import dayjs from 'dayjs';

class AssignmentUserApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        assignmentId?: number,
        userId?: string,
        searchText?: string,
    ): Promise<Page<AssignmentUserWithAssessment>> => {
        const response = await apiAxios.get<Page<AssignmentUserWithAssessment>>('/assignment-users', {
            params: { page, size, sortField, sortOrder, assignmentId, userId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.deadline = d.deadline && dayjs.utc(d.deadline).local();
            d.assignment.published = d.assignment.published && dayjs.utc(d.assignment.published).local();
            if (d.assessment) {
                d.assessment.notified = d.assessment.notified && dayjs.utc(d.assessment.notified).local();
                d.assessment.started = d.assessment.started && dayjs.utc(d.assessment.started).local();
                d.assessment.finished = d.assessment.finished && dayjs.utc(d.assessment.finished).local();
            }
        });

        return response.data;
    };

    get = async (id: number): Promise<AssignmentUser> => {
        const response = await apiAxios.get<AssignmentUser>(`/assignment-users/${id}`);
        response.data.deadline = response.data.deadline && dayjs.utc(response.data.deadline).local();

        return response.data;
    };

    create = async (assignmentUser: AssignmentUser): Promise<AssignmentUser> => {
        const response = await apiAxios.post<AssignmentUser>('/assignment-users', assignmentUser);
        response.data.deadline = response.data.deadline && dayjs.utc(response.data.deadline).local();

        return response.data;
    };

    update = async (assignmentUser: AssignmentUser): Promise<AssignmentUser> => {
        const response = await apiAxios.put<AssignmentUser>(`/assignment-users/${assignmentUser.id}`, assignmentUser);
        response.data.deadline = response.data.deadline && dayjs.utc(response.data.deadline).local();

        return response.data;
    };

    delete = async (assignmentUser: AssignmentUser): Promise<void> => {
        await apiAxios.delete(`/assignment-users/${assignmentUser.id}`);
    };
}

const assignmentUserApi: AssignmentUserApi = new AssignmentUserApi();
export default assignmentUserApi;
