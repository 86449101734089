import Icon from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Popconfirm, Row, Space, Tabs, message } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import externalUserApi from '../../../../api/ExternalUserApi';
import userApi from '../../../../api/UserApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../../../context/CustomContext';
import { WithRouterProps, withRouter } from '../../../../hooks/WithRouter';
import { ExternalUser, User } from '../../../../model/entities';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../resources/images/trash-can.svg';
import notificationService from '../../../../services/NotificationService';
import UserAssignmentsList from './UserAssignmentsList/UserAssignmentsList';
import UserCoursesList from './UserCoursesList/UserCoursesList';

class UserPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            if (this.props.params.id === 'new') {
                await this.new();
            } else {
                await this.get(this.props.params.id!);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { intl } = this.props;
        const { auth } = this.context;

        const user: User = { external: true };
        const branch = auth!.branches.filter((b) => b.id === auth?.branchId)[0];

        this.setState({ user });
        this.formRef.current!.setFieldsValue(user);
        this.formRef.current!.setFieldValue('branch', branch.name);
        this.formRef.current!.setFieldValue('language', intl.formatMessage({ id: `language.${branch.language}` }));
    };

    get = async (id: string) => {
        const { intl } = this.props;
        const { auth } = this.context;

        const user = await userApi.get(id);
        const branch = auth!.branches.filter((b) => b.id === auth?.branchId)[0];

        this.setState({ user });
        this.formRef.current!.setFieldsValue(user);
        this.formRef.current!.setFieldValue('branch', branch.name);
        this.formRef.current!.setFieldValue('language', intl.formatMessage({ id: `language.${branch.language}` }));
    };

    save = async (values: any) => {
        const { user } = this.state;
        const { auth } = this.context;

        try {
            this.setState({ loading: 'saving' });

            // save external user
            let externalUser: ExternalUser;
            if (user && user.id) {
                externalUser = await externalUserApi.getByIdentifier(user.id);
                externalUser = Object.assign({}, externalUser, values);
                externalUser = await externalUserApi.update(externalUser);
            } else {
                externalUser = Object.assign({}, values, { branchId: auth?.branchId });
                externalUser = await externalUserApi.create(externalUser);
            }
            const updatedUser: User = await userApi.get(externalUser.identifier!);

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ user: updatedUser });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'user.status.duplicate' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    delete = async () => {
        const { user } = this.state;

        try {
            if (user && user.id) {
                this.setState({ loading: 'deleting' });
                const externalUser: ExternalUser = await externalUserApi.getByIdentifier(user.id);
                await externalUserApi.delete(externalUser);

                this.props.navigate(`/admin/users`);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderForm()}
                {this.renderTabs()}
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { user, loading } = this.state;
        const disabled = user && !user.external;

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="user.firstName" />}
                            name="firstName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" disabled={disabled} maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="user.lastName" />}
                            name="lastName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" disabled={disabled} maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="user.email" />}
                            name="email"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                { type: 'email', message: <FormattedMessage id="status.email.invalid" /> },
                            ]}
                        >
                            <Input size="large" disabled={disabled} maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="user.username" />}
                            name="username"
                            hidden={user?.external}
                        >
                            <Input size="large" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="user.branch" />} name="branch">
                            <Input size="large" disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="user.language" />} name="language">
                            <Input size="large" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="user.userType" />}
                            name="userType"
                            hidden={user?.external}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input size="large" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className="buttons">
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            hidden={!user || !user.external}
                            icon={<Icon component={SaveSvg} />}
                            loading={loading === 'saving'}
                        >
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>

                        <Popconfirm
                            title={<FormattedMessage id="user.delete" />}
                            onConfirm={this.delete}
                            okText={<FormattedMessage id="button.yes" />}
                            cancelText={<FormattedMessage id="button.no" />}
                        >
                            <Button
                                size="large"
                                hidden={!user || !user.id || !user.external}
                                icon={<Icon component={TrashCanSvg} />}
                                loading={loading === 'deleting'}
                                type="ghost"
                            >
                                <FormattedMessage id="button.delete" tagName="span" />
                            </Button>
                        </Popconfirm>
                        <Link to="/admin/users">
                            <Button type="text" size="large">
                                <FormattedMessage id="button.back" tagName="span" />
                            </Button>
                        </Link>
                    </Space>
                </Form.Item>
            </Form>
        );
    };

    renderTabs = (): React.ReactElement | undefined => {
        const { user } = this.state;

        if (user && user.id) {
            return (
                <Tabs defaultActiveKey="assignments" size="large">
                    <Tabs.TabPane key="assignments" tab={<FormattedMessage id="user.assignments" />}>
                        {user && <UserAssignmentsList user={user} />}
                    </Tabs.TabPane>
                    <Tabs.TabPane key="courses" tab={<FormattedMessage id="user.courses" />}>
                        {user && <UserCoursesList user={user} />}
                    </Tabs.TabPane>
                </Tabs>
            );
        }
    };

    render() {
        return (
            <LayoutComponent pageId="users">
                <WrapperComponent title={<FormattedMessage id="user.name" />}>{this.renderContent()}</WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<Props>(UserPage));

interface Props extends WrappedComponentProps {}

interface State {
    user?: User;
    loading?: 'saving' | 'deleting';
}
