import { App as AntdApp, ConfigProvider, message } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import enUS from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { Component } from 'react';
import { IntlProvider } from 'react-intl';
import styles from './App.module.scss';
import { RoutesComponent } from './components/RoutesComponent/RoutesComponent';
import theme from './components/ThemeComponent/ThemeComponent';
import CustomContext from './context/CustomContext';
import messagesEn from './i18n/en.json';
import messagesEs from './i18n/es.json';
import { Context } from './model/elements';
import { Auth } from './model/entities';
import { LanguageType } from './model/types';
import authService from './services/AuthService';
import languageService from './services/LanguageService';

// set up DayJs
dayjs.extend(utc);

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            context: {
                updateAuth: this.updateContextAuth,
            },
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            // set-up antd configuration
            message.config({
                maxCount: 1,
            });

            // set-up current user
            const auth = await authService.get();
            const language = languageService.get(auth);
            this.updateContext(auth, language);

            // set-up locale
            dayjs.locale(language);

            const initialized = true;
            this.setState({ initialized });
        } catch (error) {
            message.error('Error interno');
        }
    };

    private updateContext = (auth?: Auth, language?: LanguageType): void => {
        auth && !this.state.feedback && this.showFeedback();

        const context: Context = Object.assign({}, this.state.context, { auth, language });
        this.setState({ context });
    };

    private updateContextAuth = (auth?: Auth): void => {
        auth && !this.state.feedback && this.showFeedback();

        const context: Context = Object.assign({}, this.state.context, { auth });
        this.setState({ context });
    };

    private showFeedback = () => {
        try {
            const script = document.createElement('script');
            script.src =
                'https://modelical.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=e9eb3575';
            script.async = true;
            document.body.appendChild(script);
            this.setState({ feedback: true });
        } catch (error) {
            console.log(error);
        }
    };

    /*** COMPONENTS ***/

    render() {
        if (this.state.initialized && this.state.context.language) {
            const language = this.state.context.language;
            const locale = language === 'en' ? enUS : esES;
            const messages = language === 'en' ? messagesEn : messagesEs;

            return (
                <ConfigProvider locale={locale} theme={theme}>
                    <AntdApp className={styles.container}>
                        <CustomContext.Provider value={this.state.context}>
                            <IntlProvider locale={language} defaultLocale={language} messages={messages}>
                                <RoutesComponent />
                            </IntlProvider>
                        </CustomContext.Provider>
                    </AntdApp>
                </ConfigProvider>
            );
        } else {
            return <></>;
        }
    }
}

export default App;

interface Props {}

interface State {
    initialized?: boolean;
    context: Context;
    feedback?: boolean;
}
