import { UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row, Space } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../context/CustomContext';
import { WithRouterProps, withRouter } from '../../hooks/WithRouter';
import notificationService from '../../services/NotificationService';
import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';

class UserProfilePage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            await this.get();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    get = async () => {
        const { intl } = this.props;
        const { auth } = this.context;

        const branch = auth!.branches.filter((b) => b.id === auth?.branchId)[0];

        this.formRef.current!.setFieldsValue(auth);
        this.formRef.current!.setFieldValue('branch', branch.name);
        this.formRef.current!.setFieldValue('language', intl.formatMessage({ id: `language.${branch.language}` }));
    };

    showChangePasswordModal = (changePasswordModalVisible: boolean) => {
        this.setState({ changePasswordModalVisible });
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { changePasswordModalVisible } = this.state;

        return (
            <>
                {this.renderForm()}
                {changePasswordModalVisible && (
                    <ChangePasswordModal
                        onHide={() => this.showChangePasswordModal(false)}
                        onSave={() => this.showChangePasswordModal(false)}
                    />
                )}
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { navigate } = this.props;
        const { auth } = this.context;

        return (
            <Form ref={this.formRef} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="user.firstName" />} name="firstName">
                            <Input size="large" disabled={true} maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="user.lastName" />} name="lastName">
                            <Input size="large" disabled={true} maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="user.email" />} name="email">
                            <Input size="large" disabled={true} maxLength={200} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="user.branch" />} name="branch">
                            <Input size="large" disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="user.language" />} name="language">
                            <Input size="large" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="buttons">
                    <Space>
                        <Button
                            type="primary"
                            size="large"
                            hidden={!auth || !auth.external}
                            icon={<UnlockOutlined />}
                            onClick={() => this.showChangePasswordModal(true)}
                        >
                            <FormattedMessage id="userProfile.changePassword" tagName="span" />
                        </Button>

                        <Button type="text" size="large" onClick={() => navigate(-1)}>
                            <FormattedMessage id="button.back" tagName="span" />
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        );
    };

    render() {
        return (
            <LayoutComponent>
                <WrapperComponent title={<FormattedMessage id="userProfile.title" />}>
                    {this.renderContent()}
                </WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<Props>(UserProfilePage));

interface Props extends WrappedComponentProps {}

interface State {
    changePasswordModalVisible?: boolean;
}
