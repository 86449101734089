import Icon, { EyeOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Table, TablePaginationConfig, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import assessmentApi from '../../../../../api/AssessmentApi';
import CustomContext from '../../../../../context/CustomContext';
import { Page } from '../../../../../model/elements';
import { AssessmentWithUser, Assignment, Course } from '../../../../../model/entities';
import { ReactComponent as MailReplySvg } from '../../../../../resources/images/mail-reply.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import AssessmentModal from './AssessmentModal/AssessmentModal';

class AssignmentAssessmentsList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.assignment.id && this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.assignment.id !== prevProps.assignment.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        const { assignment } = this.props;
        try {
            this.setState({ loading: true });
            const assessmentsPage = await assessmentApi.listByAssignment(
                0,
                tableService.pageSize,
                'user.lastName',
                true,
                assignment.id!,
                true,
            );
            this.setState({ assessmentsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { assignment } = this.props;
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const assessmentsPage = await assessmentApi.listByAssignment(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                assignment.id!,
                true,
                searchText,
            );
            this.setState({ assessmentsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    refresh = async () => {
        const { assignment } = this.props;
        const { assessmentsPage, searchText } = this.state;
        try {
            this.setState({ loading: true });
            const sortField = assessmentsPage!.sort.field!;
            const sortOrder = assessmentsPage!.sort.order!;
            const assignmentUsersPageNew = await assessmentApi.listByAssignment(
                0,
                tableService.pageSize,
                sortField,
                sortOrder,
                assignment.id!,
                true,
                searchText,
            );
            this.setState({ assessmentsPage: assignmentUsersPageNew, searchText, loading: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    showModal = (assessment: AssessmentWithUser) => {
        if (assessment.finished) {
            const modalVisible = true;
            this.setState({ modalVisible, assessment });
        }
    };

    hideModal = async () => {
        const modalVisible = false;
        const assessment = undefined;
        this.setState({ modalVisible, assessment });
    };

    sendReminder = async (assessment: AssessmentWithUser) => {
        try {
            this.setState({ sendingReminder: assessment.id });
            await assessmentApi.sendReminder(assessment);
            message.success(this.props.intl.formatMessage({ id: 'status.sent' }));
            this.refresh();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ sendingReminder: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { assignment } = this.props;
        const { assessmentsPage, loading, sendingReminder } = this.state;
        const items = assessmentsPage ? assessmentsPage.content : [];
        const columns: ColumnsType<AssessmentWithUser> = [
            {
                title: <FormattedMessage id="assignmentAssessment.user.fullName" />,
                dataIndex: 'user.lastName',
                key: 'user.lastName',
                sorter: !!assignment.id,
                defaultSortOrder: 'ascend',
                render: (value: string, item: AssessmentWithUser) => (
                    <>
                        {item.user.lastName}, {item.user.firstName}
                    </>
                ),
            },
            {
                title: <FormattedMessage id="assignmentAssessment.started" />,
                dataIndex: 'started',
                key: 'started',
                sorter: !!assignment.id,
                width: '200px',
                align: 'center',
                render: (value: string, item: AssessmentWithUser) =>
                    value && (
                        <FormattedDate
                            value={value}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                            hour12={false}
                        />
                    ),
            },
            {
                title: <FormattedMessage id="assignmentAssessment.finished" />,
                dataIndex: 'finished',
                key: 'finished',
                sorter: !!assignment.id,
                width: '200px',
                align: 'center',
                render: (value: string, item: AssessmentWithUser) =>
                    value && (
                        <FormattedDate
                            value={value}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                            hour12={false}
                        />
                    ),
            },
            {
                title: <FormattedMessage id="assignmentAssessment.result.passed" />,
                dataIndex: 'result.passed',
                key: 'resultPassed',
                sorter: !!assignment.id,
                width: '150px',
                align: 'center',
                render: (value: boolean, item: AssessmentWithUser) => {
                    if (item.result?.passed === true) {
                        return (
                            <Tag color="green">
                                <FormattedMessage id="assessment.result.passed.true" />
                            </Tag>
                        );
                    } else if (item.result?.passed === false) {
                        return (
                            <Tag color="red">
                                <FormattedMessage id="assessment.result.passed.false" />
                            </Tag>
                        );
                    }
                },
            },
            {
                title: <FormattedMessage id="assignmentAssessment.result.mark" />,
                dataIndex: 'result.mark',
                key: 'result.mark',
                sorter: !!assignment.id,
                width: '100px',
                align: 'right',
                render: (value: number, item: AssessmentWithUser) =>
                    item.result &&
                    item.result.mark && (
                        <>
                            <FormattedNumber value={item.result.mark} maximumFractionDigits={1} />%
                        </>
                    ),
            },
            {
                title: <FormattedMessage id="assignmentAssessment.result.courses" />,
                dataIndex: ['result', 'courses'],
                key: 'result.courses',
                width: '100px',
                align: 'right',
                render: (value: Course[], item: AssessmentWithUser) =>
                    value &&
                    value.length > 0 && (
                        <Tooltip title={value.map((c) => c.name).join(', ')} placement="left">
                            {value.length}
                        </Tooltip>
                    ),
            },
            {
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: '120px',
                render: (value: string, item: AssessmentWithUser) => (
                    <Space>
                        <Tooltip title={<FormattedMessage id="assignmentAssessment.mark.tooltip" />}>
                            <Button type="ghost" icon={<EyeOutlined />} onClick={() => this.showModal(item)} />
                        </Tooltip>
                        <Popconfirm
                            title={<FormattedMessage id="assignmentAssessment.reminder" />}
                            onConfirm={() => this.sendReminder(item)}
                            okText={<FormattedMessage id="button.yes" />}
                            cancelText={<FormattedMessage id="button.no" />}
                            placement="left"
                            disabled={!!item.finished}
                        >
                            <Tooltip
                                title={
                                    !item.finished &&
                                    item.reminded && (
                                        <>
                                            <FormattedMessage id="assignmentAssessment.reminder.last" />{' '}
                                            <FormattedDate
                                                value={item.reminded?.format()}
                                                day="2-digit"
                                                month="2-digit"
                                                year="numeric"
                                                hour="2-digit"
                                                minute="2-digit"
                                                hour12={false}
                                            />
                                        </>
                                    )
                                }
                            >
                                <Button
                                    type="ghost"
                                    icon={<Icon component={MailReplySvg} />}
                                    disabled={!!item.finished}
                                    loading={sendingReminder === item.id}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(assessmentsPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    renderModal = (): React.ReactElement | undefined => {
        const { assignment } = this.props;
        const { assessment, modalVisible } = this.state;

        if (modalVisible && assignment.id && assessment && assessment.id) {
            return <AssessmentModal assessment={assessment} onHide={this.hideModal} />;
        }
    };

    render() {
        return (
            <>
                {this.renderTable()}
                {this.renderModal()}
            </>
        );
    }
}
export default injectIntl(AssignmentAssessmentsList);

interface Props extends WrappedComponentProps {
    assignment: Assignment;
}

interface State {
    assessmentsPage?: Page<AssessmentWithUser>;
    loading?: boolean;
    sendingReminder?: number;
    searchText?: string;
    assessment?: AssessmentWithUser;
    modalVisible?: boolean;
}
