import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import CustomContext from '../../context/CustomContext';

/**
 * This component represents a protected route of the admin section. It redirect to the login page if the user is not authenticated.
 * @param props - the route props
 */
export const ProtectedAdminRoute: React.FC<Props> = (props) => {
    const { children } = props;
    const context = useContext(CustomContext);
    const { auth } = context;

    if (auth && auth.authorities.includes('ROLE_ADMIN') && auth.branchId) {
        return children ? children : <Outlet />;
    } else if (auth && auth.authorities.includes('ROLE_SUPER_ADMIN')) {
        return <Navigate to={'/super-admin'} />;
    } else if (auth && auth.authorities.includes('ROLE_USER')) {
        return <Navigate to={'/dashboard'} />;
    } else {
        return <Navigate to={'/login'} />;
    }
};
export default ProtectedAdminRoute;

interface Props {
    children?: React.ReactElement;
}
