import Icon from '@ant-design/icons';
import { Button, Divider, Form, FormInstance, Input, message, Popconfirm, Space, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import profileBranchApi from '../../../../api/ProfileBranchApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../../../context/CustomContext';
import { withRouter, WithRouterProps } from '../../../../hooks/WithRouter';
import { Profile } from '../../../../model/entities';
import ModelicalLogo from '../../../../resources/images/modelical-logo.png';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../resources/images/trash-can.svg';
import notificationService from '../../../../services/NotificationService';
import styles from './ProfilePage.module.scss';
import ProfileSkillsList from './ProfileSkillsList/ProfileSkillsList';

class ProfilePage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            if (this.props.params.id === 'new') {
                await this.new();
            } else {
                await this.get(+this.props.params.id!);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const { auth, language } = this.context;

        const branchId = auth && auth.branchId;
        const profile: Profile = { branchId, passGrade: 50, language };

        this.setState({ profile });
        this.formRef.current!.setFieldsValue(profile);
    };

    get = async (id: number) => {
        const profile = await profileBranchApi.get(id);

        this.setState({ profile });
        this.formRef.current!.setFieldsValue(profile);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let profile: Profile = Object.assign({}, this.state.profile, values);
            profile = profile.id ? await profileBranchApi.update(profile) : await profileBranchApi.create(profile);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ profile });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'profile.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    delete = async () => {
        try {
            this.setState({ deleting: true });
            const profile = Object.assign({}, this.state.profile);
            await profileBranchApi.delete(profile);

            this.props.navigate(`/admin/profiles`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ deleting: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { profile } = this.state;

        return (
            <>
                {this.renderForm()}
                <Divider />
                {profile && <ProfileSkillsList profile={profile} />}
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { profile, saving, deleting } = this.state;
        const disabled = profile && !profile.branchId;

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="profile.name" />}
                    name="name"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Input
                        maxLength={100}
                        size="large"
                        placeholder={intl.formatMessage({ id: 'profile.name.placeholder' })}
                        disabled={disabled}
                        prefix={
                            disabled && (
                                <Tooltip title={<FormattedMessage id="profile.branchId.global" />} placement="topLeft">
                                    <img src={ModelicalLogo} alt="Modelical" className={styles.logo} />
                                </Tooltip>
                            )
                        }
                    />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="profile.description" />} name="description">
                    <TextArea maxLength={500} rows={3} size="large" disabled={disabled} showCount />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="profile.passGrade" />}
                    name="passGrade"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    wrapperCol={{ span: 3 }}
                >
                    <Input type="number" min={0} max={100} step={1} size="large" addonAfter="%" disabled={disabled} />
                </Form.Item>
                <Form.Item className="buttons">
                    <Space>
                        <Button
                            type="primary"
                            ghost={!!profile?.id}
                            htmlType="submit"
                            size="large"
                            icon={<Icon component={SaveSvg} />}
                            loading={saving}
                            hidden={disabled}
                        >
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>
                        <Popconfirm
                            title={<FormattedMessage id="profile.delete" />}
                            onConfirm={this.delete}
                            okText={<FormattedMessage id="button.yes" />}
                            cancelText={<FormattedMessage id="button.no" />}
                        >
                            <Button
                                type="ghost"
                                size="large"
                                hidden={!profile || !profile.id || disabled}
                                icon={<Icon component={TrashCanSvg} />}
                                loading={deleting}
                            >
                                <FormattedMessage id="button.delete" tagName="span" />
                            </Button>
                        </Popconfirm>
                    </Space>
                    <Link to="/admin/profiles">
                        <Button type="text" size="large">
                            <FormattedMessage id="button.back" tagName="span" />
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        );
    };

    render() {
        return (
            <LayoutComponent pageId="profiles">
                <WrapperComponent title={<FormattedMessage id="profiles.name" />}>
                    {this.renderContent()}
                </WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<Props>(ProfilePage));

interface Props extends WrappedComponentProps {}

interface State {
    profile?: Profile;
    saving?: boolean;
    deleting?: boolean;
}
