import Icon from '@ant-design/icons';
import { Avatar, Button, List } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import assessmentApi from '../../../../../api/AssessmentApi';
import PageHeaderComponent from '../../../../../components/PageHeaderComponent/PageHeaderComponent';
import CustomContext from '../../../../../context/CustomContext';
import { Assessment, AssessmentAnswer, AssignmentQuestionFile } from '../../../../../model/entities';
import { ReactComponent as ArrowRightSvg } from '../../../../../resources/images/arrow--right.svg';
import { ReactComponent as DownloadSvg } from '../../../../../resources/images/download.svg';
import notificationService from '../../../../../services/NotificationService';
import AssessmentAnswerComponent from './AssessmentAnswerComponent/AssessmentAnswerComponent';
import styles from './AssessmentAnswersComponent.module.scss';

class AssessmentAnswersComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { files: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            const { assessmentAnswers } = this.props;
            const files: AssignmentQuestionFile[] = assessmentAnswers
                .flatMap((a) => a.question.files)
                .filter((file, index, self) => self.findIndex((t) => t.id === file.id && t.id === file.id) === index);
            const page: number = files.length === 0 ? 0 : -1;
            this.setState({ page, files });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    changePage = async (page: number): Promise<void> => {
        await this.props.onAssessmentAnswerSave();
        this.setState({ page });
    };

    downloadFile = async (assignmentQuestionFile: AssignmentQuestionFile) => {
        const { assessment } = this.props;

        try {
            this.setState({ downloading: assignmentQuestionFile.id });
            await assessmentApi.download(assessment.id!, assignmentQuestionFile);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ downloading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderFiles = (): React.ReactElement | undefined => {
        const { page, files, downloading } = this.state;

        return (
            <>
                <PageHeaderComponent
                    title={<FormattedMessage id="assessment.steps.inProgress.files" />}
                    className={styles.files}
                />
                <List
                    className={styles.files}
                    itemLayout="horizontal"
                    dataSource={files}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    type="ghost"
                                    icon={<Icon component={DownloadSvg} />}
                                    loading={downloading === item.id}
                                    onClick={() => this.downloadFile(item)}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={
                                    <Avatar shape="square" size="large">
                                        {item.file!.name!.charAt(0)}
                                    </Avatar>
                                }
                                title={item.file!.name}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
                <div className={styles.buttons}>
                    <Button key="next" type="primary" ghost size="large" onClick={() => this.changePage(page! + 1)}>
                        <FormattedMessage id="button.next" tagName="span" /> <Icon component={ArrowRightSvg} />
                    </Button>
                </div>
            </>
        );
    };

    renderAnswer = (): React.ReactElement | undefined => {
        const { assessmentAnswers } = this.props;
        const { page, files } = this.state;

        return (
            <AssessmentAnswerComponent
                page={page!}
                count={assessmentAnswers.length}
                assessmentAnswer={assessmentAnswers[page!]}
                includeFiles={files.length > 0}
                onPageChange={this.changePage}
                onSubmit={this.props.onSubmit}
            />
        );
    };

    render() {
        const { page } = this.state;

        if (page === -1) {
            return this.renderFiles();
        } else if (page === undefined) {
            return <></>;
        } else {
            return this.renderAnswer();
        }
    }
}
export default injectIntl(AssessmentAnswersComponent);

interface Props extends WrappedComponentProps {
    assessment: Assessment;
    assessmentAnswers: AssessmentAnswer[];
    onAssessmentAnswerSave: () => Promise<void>;
    onSubmit: () => Promise<void>;
}

interface State {
    files: AssignmentQuestionFile[];
    page?: number;
    downloading?: number;
}
