import Icon from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    FormInstance,
    Input,
    InputNumber,
    message,
    Popconfirm,
    Row,
    Select,
    Space,
    Tabs,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import assignmentApi from '../../../../api/AssignmentApi';
import profileBranchApi from '../../../../api/ProfileBranchApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../../../context/CustomContext';
import { withRouter, WithRouterProps } from '../../../../hooks/WithRouter';
import { Assignment, Profile } from '../../../../model/entities';
import { ReactComponent as CheckmarkSvg } from '../../../../resources/images/checkmark.svg';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../resources/images/trash-can.svg';
import notificationService from '../../../../services/NotificationService';
import AssignmentAssessmentsList from './AssignmentAssessmentsList/AssignmentAssessmentsList';
import AssignmentGroupsList from './AssignmentGroupsList/AssignmentGroupsList';
import AssignmentUsersList from './AssignmentUsersList/AssignmentUsersList';

class AssignmentPage extends Component<WithRouterProps<Props>, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: WithRouterProps<Props>) {
        super(props);
        this.state = { profiles: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            if (this.props.params.id === 'new') {
                await this.new();
            } else {
                await this.get(+this.props.params.id!);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async () => {
        const branchId = this.context.auth!.branchId!;
        const assignment: Assignment = { branchId, passGrade: 50, publicResult: true };
        const profilesPage = await profileBranchApi.list(0, 10, 'name', true, branchId, true);
        const profiles = profilesPage.content;

        this.setState({ assignment, profiles });
        this.formRef.current!.setFieldsValue(assignment);
    };

    get = async (id: number) => {
        const branchId = this.context.auth!.branchId!;
        const responses = await Promise.all([
            assignmentApi.get(id),
            profileBranchApi.list(0, 10, 'name', true, branchId, true),
        ]);
        const assignment = responses[0];
        const profiles = responses[1].content;

        this.setState({ assignment, profiles });
        this.formRef.current!.setFieldsValue(assignment);
    };

    save = async (values: any) => {
        try {
            this.setState({ saving: true });
            let assignment: Assignment = Object.assign({}, this.state.assignment, values);
            assignment = assignment.id
                ? await assignmentApi.update(assignment)
                : await assignmentApi.create(assignment);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ assignment });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'assignment.status.duplicate' },
            ]);
        } finally {
            this.setState({ saving: false });
        }
    };

    publish = async () => {
        const form = this.formRef.current!;
        await form.validateFields();
        try {
            this.setState({ publishing: true });
            let assignment: Assignment = Object.assign({}, this.state.assignment, form.getFieldsValue(), {
                published: dayjs(),
            });
            assignment = assignment.id
                ? await assignmentApi.update(assignment)
                : await assignmentApi.create(assignment);
            message.success(this.props.intl.formatMessage({ id: 'status.published' }));

            this.setState({ assignment });
            this.formRef.current!.setFieldsValue(assignment);
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'assignment.status.duplicate' },
                { status: 400, message: 'assignment.status.questions.empty' },
            ]);
        } finally {
            this.setState({ publishing: false });
        }
    };

    delete = async () => {
        try {
            this.setState({ deleting: true });
            const assignment = Object.assign({}, this.state.assignment);
            await assignmentApi.delete(assignment);

            this.props.navigate(`/admin/assignments`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            this.setState({ deleting: false });
        }
    };

    searchProfiles = async (searchText: string) => {
        const branchId = this.context.auth!.branchId!;
        try {
            const profilesPageNew = await profileBranchApi.list(0, 10, 'name', true, branchId, true, searchText);
            const profiles = profilesPageNew.content;

            this.setState({ profiles });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderForm()}
                {this.renderTabs()}
            </>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { assignment, profiles, saving, publishing, deleting } = this.state;
        const disabled = !!assignment && !!assignment.published;
        const profileOptions = profiles.map((profile) => (
            <Select.Option key={profile.id!} value={profile.id!}>
                {profile.name}
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="assignment.name" />}
                    name="name"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Input
                        maxLength={100}
                        size="large"
                        placeholder={intl.formatMessage({ id: 'assignment.name.placeholder' })}
                        disabled={disabled}
                    />
                </Form.Item>
                {!disabled && (
                    <Form.Item
                        label={<FormattedMessage id="assignment.profile" />}
                        name="profileId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select
                            size="large"
                            placeholder={intl.formatMessage({ id: 'assignment.profile.placeholder' })}
                            showSearch
                            filterOption={false}
                            onSearch={this.searchProfiles}
                            disabled={disabled}
                        >
                            {profileOptions}
                        </Select>
                    </Form.Item>
                )}
                {disabled && (
                    <Form.Item
                        label={<FormattedMessage id="assignment.profile" />}
                        name="publishedProfile"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" disabled={disabled} />
                    </Form.Item>
                )}
                <Row gutter={[28, 0]}>
                    <Col span={6}>
                        <Form.Item
                            label={<FormattedMessage id="assignment.passGrade" />}
                            name="passGrade"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <InputNumber
                                min={0}
                                max={100}
                                step={1}
                                maxLength={3}
                                size="large"
                                addonAfter="%"
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={<FormattedMessage id="assignment.publicResult" />}
                            name="publicResult"
                            valuePropName="checked"
                        >
                            <Checkbox disabled={disabled}>
                                <FormattedMessage id="assignment.publicResult.yes" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={<FormattedMessage id="assignment.period" />}
                            name="period"
                            wrapperCol={{ span: 12 }}
                        >
                            <InputNumber
                                min={0}
                                max={1000}
                                step={1}
                                size="large"
                                maxLength={3}
                                addonAfter={<FormattedMessage id="assignment.period.days" />}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={<FormattedMessage id="assignment.published" />} name="published">
                            <DatePicker
                                size="large"
                                format="DD/MM/YYYY HH:mm"
                                disabled
                                bordered={false}
                                placeholder=""
                                suffixIcon=""
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label={<FormattedMessage id="assignment.description" />} name="description">
                    <TextArea maxLength={500} rows={3} size="large" disabled={disabled} showCount />
                </Form.Item>
                <Form.Item>
                    <Space>
                        {assignment && !assignment.published && (
                            <Button
                                type="primary"
                                ghost={!!assignment?.id}
                                htmlType="submit"
                                size="large"
                                hidden={!!assignment && !!assignment.published}
                                icon={<Icon component={SaveSvg} />}
                                loading={saving}
                            >
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                        )}
                        {assignment && assignment.id && !assignment.published && (
                            <Popconfirm
                                title={<FormattedMessage id="assignment.publish" />}
                                onConfirm={this.publish}
                                okText={<FormattedMessage id="button.yes" />}
                                cancelText={<FormattedMessage id="button.no" />}
                                placement="right"
                            >
                                <Button
                                    size="large"
                                    type="primary"
                                    danger
                                    icon={<Icon component={CheckmarkSvg} />}
                                    loading={publishing}
                                >
                                    <FormattedMessage id="button.publish" tagName="span" />
                                </Button>
                            </Popconfirm>
                        )}
                        {assignment && assignment.id && (
                            <Popconfirm
                                title={<FormattedMessage id="assignment.delete" />}
                                onConfirm={this.delete}
                                okText={<FormattedMessage id="button.yes" />}
                                cancelText={<FormattedMessage id="button.no" />}
                            >
                                <Button
                                    type="ghost"
                                    size="large"
                                    icon={<Icon component={TrashCanSvg} />}
                                    loading={deleting}
                                >
                                    <FormattedMessage id="button.delete" tagName="span" />
                                </Button>
                            </Popconfirm>
                        )}
                    </Space>
                    <Link to="/admin/assignments">
                        <Button type="text" size="large">
                            <FormattedMessage id="button.back" tagName="span" />
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        );
    };

    renderTabs = (): React.ReactElement | undefined => {
        const { assignment } = this.state;

        return (
            <Tabs defaultActiveKey="users" size="large">
                <Tabs.TabPane key="users" tab={<FormattedMessage id="assignment.users" />}>
                    {assignment && <AssignmentUsersList assignment={assignment} />}
                </Tabs.TabPane>
                <Tabs.TabPane key="groups" tab={<FormattedMessage id="assignment.groups" />}>
                    {assignment && <AssignmentGroupsList assignment={assignment} />}
                </Tabs.TabPane>
                <Tabs.TabPane key="assessments" tab={<FormattedMessage id="assignment.assessments" />}>
                    {assignment && <AssignmentAssessmentsList assignment={assignment} />}
                </Tabs.TabPane>
            </Tabs>
        );
    };

    render() {
        return (
            <LayoutComponent pageId="assignments">
                <WrapperComponent title={<FormattedMessage id="assignments.name" />}>
                    {this.renderContent()}
                </WrapperComponent>
            </LayoutComponent>
        );
    }
}
export default injectIntl(withRouter<Props>(AssignmentPage));

interface Props extends WrappedComponentProps {}

interface State {
    assignment?: Assignment;
    profiles: Profile[];
    saving?: boolean;
    publishing?: boolean;
    deleting?: boolean;
}
