import { Course, CourseUser } from '../model/entities';
import { Page } from '../model/elements';
import apiAxios from './CustomAxios';
import dayjs from 'dayjs';

class CourseUserApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        userId?: string,
        searchText?: string,
    ): Promise<Page<Course>> => {
        const response = await apiAxios.get<Page<CourseUser>>('/course-users', {
            params: { page, size, sortField, sortOrder, userId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.enrolledOn = d.enrolledOn && dayjs.utc(d.enrolledOn).local();
            d.completedOn = d.completedOn && dayjs.utc(d.completedOn).local();
            d.expiredOn = d.completedOn && dayjs.utc(d.expiredOn).local();
        });

        return response.data;
    };
}

const courseUserApi: CourseUserApi = new CourseUserApi();
export default courseUserApi;
