import { TablePaginationConfig } from 'antd/lib/table';
import { Page } from '../model/elements';

class TableService {
    readonly pageSize = 10;

    createPagination = (pageItems?: Page<any>): TablePaginationConfig => {
        return {
            current: pageItems ? pageItems.number + 1 : 1,
            total: pageItems ? pageItems.totalElements : 0,
            pageSize: this.pageSize,
            showSizeChanger: false,
            size: 'small',
            hideOnSinglePage: true,
        };
    };
}

const tableService: TableService = new TableService();
export default tableService;
