import React, { Component } from 'react';
import { Col, Row, Table, TablePaginationConfig } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as SearchSvg } from '../../../resources/images/search.svg';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import courseUserApi from '../../../api/CourseUserApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Course } from '../../../model/entities';
import tableService from '../../../services/TableService';
import notificationService from '../../../services/NotificationService';

class CoursesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const coursesPage = await courseUserApi.list(0, tableService.pageSize, 'name', true);
            this.setState({ coursesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const coursesPage = await courseUserApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                undefined,
                searchText,
            );
            this.setState({ coursesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    search = async (searchText: string) => {
        const { coursesPage } = this.state;
        try {
            this.setState({ loading: true });
            const pageSize = coursesPage!.size;
            const sortField = coursesPage!.sort.field!;
            const sortOrder = coursesPage!.sort.order!;
            const coursesPageNew = await courseUserApi.list(0, pageSize, sortField, sortOrder, undefined, searchText);
            this.setState({ coursesPage: coursesPageNew, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                {this.renderTable()}
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const placeholder: string = intl.formatMessage({ id: 'courses.search' });

        return (
            <Row className="toolbar">
                <Col span="24" lg={12}>
                    <Search
                        placeholder={placeholder}
                        onSearch={this.search}
                        size="large"
                        bordered={false}
                        addonBefore={<Icon component={SearchSvg} />}
                        className="search"
                    />
                </Col>
            </Row>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { coursesPage, loading } = this.state;
        const items = coursesPage ? coursesPage.content : [];
        const columns: ColumnsType<Course> = [
            {
                title: <FormattedMessage id="courses.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                defaultSortOrder: 'ascend',
            },
            {
                title: <FormattedMessage id="course.enrolledOn" />,
                dataIndex: 'enrolledOn',
                key: 'enrolledOn',
                width: '200px',
                align: 'center',
                sorter: true,
                render: (value: string) =>
                    value && (
                        <FormattedDate
                            value={value}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                            hour12={false}
                        />
                    ),
            },
            {
                title: <FormattedMessage id="course.completedOn" />,
                dataIndex: 'completedOn',
                key: 'completedOn',
                width: '200px',
                align: 'center',
                sorter: true,
                render: (value: string) =>
                    value && (
                        <FormattedDate
                            value={value}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                            hour12={false}
                        />
                    ),
            },
            {
                title: <FormattedMessage id="course.completionPercentage" />,
                dataIndex: 'completionPercentage',
                key: 'completionPercentage',
                width: '100px',
                align: 'right',
                sorter: true,
                render: (value: number) => (
                    <>
                        <FormattedNumber value={value || 0} maximumFractionDigits={1} />%
                    </>
                ),
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(coursesPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return <LayoutComponent pageId="courses">{this.renderContent()}</LayoutComponent>;
    }
}
export default injectIntl(CoursesPage);

interface Props extends WrappedComponentProps {}

interface State {
    coursesPage?: Page<Course>;
    loading?: boolean;
    searchText?: string;
}
