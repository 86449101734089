import React from 'react';

const PageHeaderComponent: React.FC<Props> = (props: Props) => {
    const { title, className } = props;
    return (
        <div className={className}>
            <h1>{title}</h1>
        </div>
    );
};
export default PageHeaderComponent;

interface Props {
    title: React.ReactNode | string;
    className?: string | undefined;
}
