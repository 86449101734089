import React, { Component } from 'react';
import { Modal, Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import userApi from '../../../../../api/UserApi';
import CustomContext from '../../../../../context/CustomContext';
import { Page } from '../../../../../model/elements';
import { User } from '../../../../../model/entities';
import tableService from '../../../../../services/TableService';
import notificationService from '../../../../../services/NotificationService';

class GroupUsersList extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { auth } = this.context;
        const branchId = auth!.branchId!;
        const { groupId } = this.props;
        try {
            this.setState({ loading: true });
            const usersPage = await userApi.list(0, tableService.pageSize, 'name', true, branchId, groupId);
            this.setState({ usersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const { auth } = this.context;
        const branchId = auth!.branchId!;
        const { groupId } = this.props;
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const usersPage = await userApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                branchId,
                groupId,
                searchText,
            );
            this.setState({ usersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    new = () => {
        const { intl } = this.props;
        const title = intl.formatMessage({ id: 'users.new.modal.title' });
        const content = intl.formatMessage({ id: 'users.new.modal.description' });
        Modal.destroyAll();
        Modal.info({ title, content, maskClosable: true });
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { usersPage, loading } = this.state;
        const items = usersPage ? usersPage.content : [];
        const columns: ColumnsType<User> = [
            {
                title: <FormattedMessage id="user.name" />,
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, item: User) => (
                    <Link to={`/admin/users/${item.id}`}>
                        {item.lastName}, {item.firstName}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="user.email" />,
                dataIndex: 'email',
                key: 'email',
                sorter: true,
                render: (value: string, item: User) => <Link to={`/admin/users/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="user.assignments" />,
                dataIndex: 'assignmentsNumber',
                key: 'assignmentsNumber',
                align: 'right',
                width: '150px',
                render: (value: string, item: User) => <Link to={`/admin/users/${item.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="user.courses" />,
                dataIndex: 'coursesNumber',
                key: 'coursesNumber',
                align: 'right',
                width: '150px',
                render: (value: string, item: User) => <Link to={`/admin/users/${item.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(usersPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return this.renderTable();
    }
}
export default injectIntl(GroupUsersList);

interface Props extends WrappedComponentProps {
    groupId: number;
}

interface State {
    usersPage?: Page<User>;
    loading?: boolean;
    searchText?: string;
}
